.themes-theme-seven-contact-screen #contact {
  background: url("../../../assets/fernandez/contact/BusesFernandez-Website-Contact-Banner.jpg");
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  margin-top: -20px;
}
.themes-theme-seven-contact-screen #contact .heading {
  position: relative;
  top: 140px;
}
.themes-theme-seven-contact-screen #contact .heading h1 {
  text-align: center;
  color: #203b68;
  font-family: "DMSans-Bold";
  font-size: 25px;
}
.themes-theme-seven-contact-screen .contact h3 {
  text-align: center;
  margin: 30px auto;
  display: flex;
  width: 90%;
}
.themes-theme-seven-contact-screen .contact h3 span {
  color: #203b68;
  border: 1px solid #b2b6be;
  border-radius: 50px;
  padding: 6px 30px;
  font-family: "DMSans-Bold";
}
.themes-theme-seven-contact-screen .contact h3::before {
  border-left: 1px solid #b2b6be;
  border-radius: 10px 0 0 0;
}
.themes-theme-seven-contact-screen .contact h3::after {
  border-right: 1px solid #b2b6be;
  border-radius: 0 10px 0 0;
}
.themes-theme-seven-contact-screen .contact h3::before,
.themes-theme-seven-contact-screen .contact h3::after {
  content: "";
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 40px;
  position: relative;
  top: 20px;
}
.themes-theme-seven-contact-screen .contact h5 {
  font-size: 16px;
  text-align: center;
  color: #203b68;
  line-height: 21px;
}
.themes-theme-seven-contact-screen .contact h5 span {
  font-family: "DMSans-Bold";
}
.themes-theme-seven-contact-screen .contact .info {
  font-size: 15px;
  padding: 40px;
  background: #203b68;
  color: #ffffff !important;
  border-radius: 10px 10px 0 0;
  /* display: flex;
  justify-content: space-between;
  align-items: center; */
}
.themes-theme-seven-contact-screen .contact .info .w50 {
  /* width: 50%; */
}
.themes-theme-seven-contact-screen .contact .info .bdr-rt {
  /* border-right: 1px solid #ffffff; */
}
.themes-theme-seven-contact-screen .contact .info .address {
  margin-bottom: 30px;
  color: #fff;
}
.themes-theme-seven-contact-screen .contact .info .address span:first-child {
  border: 1px solid #ffa100;
  background-color: #ffa100;
  border-radius: 50px;
  padding: 6px 15px;
  font-family: "DMSans-Bold";
  margin-bottom: 4px;
  display: inline-block;
}
.themes-theme-seven-contact-screen .contact .info .address span:last-child {
  /* border: 1px solid #ffffff; */
  border-left: 0;
  border-radius: 0 50px 50px 0;
  padding: 6px 15px 6px 20px;
  margin-left: -15px;
}
.themes-theme-seven-contact-screen .contact .info .c-flex-row {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  color: #fff;
}

.themes-theme-seven-contact-screen .contact .info .c-flex-row span {
  width: 100%;
}
.themes-theme-seven-contact-screen .contact .info img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}
.themes-theme-seven-contact-screen .contact .form {
  background: #3966b1;
  color: #ffffff;
  padding: 25px;
  border-radius: 0;
  font-size: 15px;
  font-family: "DMSans-Bold";
}
.themes-theme-seven-contact-screen .contact .form .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  color: #ffffff;
}
.themes-theme-seven-contact-screen .contact .form .form-group label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
.themes-theme-seven-contact-screen .contact .form .form-group .form-control {
  height: 40px;
  box-shadow: none;
  border-color: transparent;
}
.themes-theme-seven-contact-screen
  .contact
  .form
  .form-group
  textarea.form-control {
  height: auto;
}
.themes-theme-seven-contact-screen .contact .form #submit_btn {
  background: #ffa100;
  border: 1px solid #ffa100;
  font-family: "DMSans-Bold";
  padding: 5px 30px;
  color: #fff;
  border-radius: 3px;
  font-size: 14px;
}
.themes-theme-seven-contact-screen .contact .support-section .support {
  background: #4881d3;
  color: #ffffff;
  padding: 25px;
  border-radius: 0 0 10px 10px;
  font-size: 15px;
  font-family: "DMSans-Bold";
}

.themes-theme-seven-contact-screen .contact .support-section .support .item {
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
}
.themes-theme-seven-contact-screen .contact .support-section .support h5 {
  text-align: center;
  margin: 30px auto;
}
.themes-theme-seven-contact-screen .contact .support-section .support h5 span {
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 50px;
  padding: 6px 30px;
  font-family: "DMSans-Bold";
}
.themes-theme-seven-contact-screen .contact .support-section .support img {
  width: 32px;
  height: 32px;
  margin-right: 4px;
}
