.buses-items {
	display: flex;
	flex-direction: column;
}

.buses-items ul {
	list-style: none;
	padding: none;
}

.buses-items-left {
	/* width: 40%; */
}

.buses-items-right {
	/* width: 60%; */
	/* margin-left: 30px; */
}

.buses-items-right-details li {
	margin-bottom: 15px;
	color: #666666;
}

.buses-items-right-details-list {
	display: flex;
}

.buses-items-right-details-list .first-span {
	display: inline-block;
	width: 200px;
}

.buses-items-left-sub-image {
	margin-top: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.buses-items-left-sub-image img {
	width: 50px;
	height: auto;
	margin: 0 10px;
}

.equipment-list {
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding: 0 30px;
	margin-top: 30px;
}

.equipment-list-item {
	width: 94px;
	height: 110px;
	margin: 10px;
	text-align: center;
	/* margin-bottom: 15px; */
}

.pullman-san-andreas-fleet {
	margin-top: -20px;
}

.pullman-san-andreas-fleet-bottom-banner {
	margin: 50px 0 30px 0;
}
