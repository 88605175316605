.offices-main-div {
  margin-top: 50px;
}

.offices-inner-div {
  margin-bottom: 40px;
}

.offices-inner-para {
  color: #e86b2a;
  margin-left: 27px;
  margin-bottom: 7px;
}

.offices-dest-div {
  display: flex;

  img {
    width: 20px;
    height: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.offices-phone-div {
  img {
    width: 20px;
    height: 20px;
    
    
  }

  span {
    margin-left: 10px;
    margin-bottom: 10px;
  }
}
/* .bold-text{
  font-size: 17px;
} */
