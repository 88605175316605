.pullman-san-andreas-services {
	/* transform: translateY(-34px); */
}

.pullman-san-andreas-services .main-heading {
	font-size: 22px;
	margin-top: 40px;
	color: #333;
}

.pullman-san-andreas-services .sub-heading {
	margin-bottom: 8px;
	font-size: 14px;
}

.pullman-san-andreas-services p {
	color: #666666;
	font-size: 16px;
	line-height: 1.3;
	font-weight: 700;
}

.pullman-san-andreas-services .buy-online-image {
	height: 85px;
	margin: 30px 0;
}

.pullman-san-andreas-services .division-industry {
	/* display: flex; */
	margin-bottom: 40px;
}

.pullman-san-andreas-services .division-industry-heading {
	margin-top: 0;
}

.pullman-san-andreas-services .division-industry-left {
	/* width: 40%; */
	/* display: flex; */
}

.pullman-san-andreas-services .division-industry-right {
	/* width: 60%; */
	margin-top: 10px;
	line-height: 1;
	font-weight: 600;
	/* padding-left: 30px; */
}

.pullman-san-andreas-services .division-industry-right {
	color: #666666;
	font-size: 14px;
	line-height: 1.3;
	font-weight: 700;
}

.pullman-san-andreas-services .division-industry-right .head {
	display: inline-block;
	margin-bottom: 10px;
}

.pullman-san-andreas-services .division-industry-right .bottom {
	display: inline-block;
	margin-bottom: 20px;
}
