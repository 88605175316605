.themes-theme-seven-price-and-services-screen #prices_services {
  background: url("../../../assets/fernandez/services/BusesFernandez-Website-ServicesAndPrices-Banner.jpg");
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
}
.themes-theme-seven-price-and-services-screen #prices_services .heading {
  position: relative;
  top: 140px;
}
.themes-theme-seven-price-and-services-screen #prices_services .heading h1 {
  /* display: flex; */
  /* width: 80%; */
  /* margin: 0 auto; */
  text-align: center;
  color: #ffffff;
  font-family: "DMSans-Bold";
  font-size: 20px;
}
.themes-theme-seven-price-and-services-screen .prices_services h3 {
  text-align: center;
  margin: 20px 0;
}
.themes-theme-seven-price-and-services-screen .prices_services h3 span {
  color: #203b68;
  /* border: 1px solid #b2b6be; */
  /* border-radius: 50px; */
  padding: 6px 30px;
  font-family: "DMSans-Bold";
  /* position: absolute; */
  /* top: -23px; */
  /* transform: translate(-145px); */
  background-color: #ffffff;
}
.themes-theme-seven-price-and-services-screen .prices_services .price-details {
  border: 1px solid #b2b6be;
  padding: 4% 30px;
  border-radius: 10px;
}
