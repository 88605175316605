.themes-theme-seven-company-screen #company {
  background: url("../../../assets/fernandez/our-companies/BusesFernandez-Website-OurCompanies-Banner.jpg");
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  margin-top: -20px;
}
.themes-theme-seven-company-screen #company .heading {
  position: relative;
  top: 140px;
}
.themes-theme-seven-company-screen #company .heading h1 {
  text-align: center;
  color: #ffffff;
  font-family: "DMSans-Bold";
  font-size: 25px;
}
.themes-theme-seven-company-screen .company h3 {
  text-align: center;
  margin: 20px auto;
  /* display: flex; */
  /* width: 70%; */
}
.themes-theme-seven-company-screen .company h3 span {
  color: #203b68;
  /* border: 1px solid #b2b6be; */
  border-radius: 50px;
  padding: 6px 30px;
  font-family: "DMSans-Bold";
}
/* .themes-theme-seven-company-screen .company h3::before {
  border-left: 1px solid #b2b6be;
  border-radius: 10px 0 0 0;
}
.themes-theme-seven-company-screen .company h3::after {
  border-right: 1px solid #b2b6be;
  border-radius: 10px 0 0 0;
}
.themes-theme-seven-company-screen .company h3::before,
.themes-theme-seven-company-screen .company h3::after {
  content: '';
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 25px;
  position: relative;
  top: 10px;
} */
.themes-theme-seven-company-screen .company .nav-tabs {
  /* border-bottom: 0; */
  /* display: flex; */
  /* justify-content: center; */
  /* align-items: center; */
  /* position: relative; */
  /* top: 20px; */
  padding: 0;
}
.themes-theme-seven-company-screen .company .tab {
  font-size: 17px;
  background: #3966b1;
  color: #ffffff;
  border: 1px solid transparent;
  border-right-color: #ffffff;
  padding: 10px 40px;
  text-align: center;
}
/* .themes-theme-seven-company-screen .company .tab:first-child {
  border-top-left-radius: 75px;
  border-bottom-left-radius: 75px;
}
.themes-theme-seven-company-screen .company .tab:last-child {
  border-top-right-radius: 75px;
  border-bottom-right-radius: 75px;
} */
.themes-theme-seven-company-screen .company .active {
  background: #ffa100;
  font-family: "DMSans-Bold";
}
.themes-theme-seven-company-screen .company .tab-content {
  min-height: 350px;
  background-color: #203b68;
  color: #ffffff;
  font-size: 15px;
  border-radius: 10px;
  padding: 50px 20px;
  line-height: 1.4;
  /* display: flex; */
}
.themes-theme-seven-company-screen .company .tab-content img {
  width: 100%;
  height: auto;
}
.themes-theme-seven-company-screen .company .tab-content h5 {
  margin-bottom: 25px;
}
.themes-theme-seven-company-screen .company .tab-content h5 span {
  font-size: 15px;
  background-color: #ffa100;
  padding: 6px 18px;
  color: #ffffff;
  border-radius: 50px;
}
.themes-theme-seven-company-screen .company .tab-content p {
  color: #ffffff;

  margin: 0 0 10px;
}
.themes-theme-seven-company-screen .company .tab-content a {
  color: #ffffff;
}
