.themes-theme-seven-special-services-screen #special-service {
  background: url("../../../assets/fernandez/services/BusesFernandez-Website-Services-SpecialServices-Banner-WithoutText.jpg");
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  margin-bottom: 30px;
  margin-top: -20px;
}
.themes-theme-seven-special-services-screen #special-service .heading {
  position: relative;
  top: 140px;
}
.themes-theme-seven-special-services-screen #special-service .heading h1 {
  /* display: flex; */
  /* width: 80%; */
  /* margin: 0 auto; */
  text-align: center;
  color: #ffffff;
  font-family: "DMSans-Bold";
  font-size: 20px;
}
/* .themes-theme-seven-special-services-screen
  #special-service
  .heading
  h1:before {
  content: '';
  flex: 1 1;
  border-top: 1px solid #ffffff;
  margin: auto;
  height: 100px;
  position: relative;
  top: 25px;
  border-left: 1px solid #ffffff;
  border-radius: 10px 0 0 0;
  margin-right: 15px;
}
.themes-theme-seven-special-services-screen #special-service .heading h1:after {
  content: '';
  flex: 1 1;
  border-top: 1px solid #ffffff;
  margin: auto;
  height: 100px;
  position: relative;
  top: 25px;
  border-right: 1px solid #ffffff;
  border-radius: 0 10px 0 0;
  margin-left: 15px;
} */
.themes-theme-seven-special-services-screen
  #special-service
  .heading
  .service-list {
  position: relative;
  top: 40px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.themes-theme-seven-special-services-screen
  #special-service
  .heading
  .service-list
  li {
  background-color: #ffffff;
  color: #203b68;
  padding: 10px 25px;
  font-family: "DMSans-Bold";
  border-radius: 50px;
  font-size: 20px;
}
.themes-theme-seven-special-services-screen
  #special-service
  .heading
  .service-list
  li
  a:link {
  text-decoration: none;
}
.themes-theme-seven-special-services-screen
  #special-service
  .heading
  .service-list
  .active-color {
  background-color: #ffa100;
}
.themes-theme-seven-special-services-screen
  #special-service
  .heading
  .service-list
  .active-color
  a {
  color: white;
}
.themes-theme-seven-special-services-screen .special-service h3 {
  text-align: center;
  margin: 20px auto;
  /* display: flex; */
}
.themes-theme-seven-special-services-screen .special-service h3 span {
  color: #203b68;
  /* border: 1px solid #b2b6be; */
  border-radius: 50px;
  padding: 6px 30px;
  font-family: "DMSans-Bold";
}
/* .themes-theme-seven-special-services-screen .special-service h3:before {
  content: '';
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 40px;
  position: relative;
  top: 20px;
  border-left: 1px solid #b2b6be;
  border-radius: 10px 0 0 0;
}
.themes-theme-seven-special-services-screen .special-service h3:after {
  content: '';
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 40px;
  position: relative;
  top: 20px;
  border-right: 1px solid #b2b6be;
  border-radius: 0 10px 0 0;
} */
.themes-theme-seven-special-services-screen .special-service h5 {
  font-size: 16px;
  text-align: center;
  color: #203b68;
  line-height: 21px;
}
.themes-theme-seven-special-services-screen .special-service h5 span {
  font-family: "DMSans-Bold";
}
.themes-theme-seven-special-services-screen .special-service p {
  font-family: "DMSans-Bold";
  text-decoration: underline;
  font-size: 16px;
  text-align: center;
  color: #203b68;
  line-height: 21px;
}
.themes-theme-seven-special-services-screen .special-service .form-section {
  border-radius: 10px;
}
.themes-theme-seven-special-services-screen
  .special-service
  .form-section
  .form {
  background: #203b68;
  color: #ffffff;
  padding: 25px;
  border-radius: 0 0 10px 10px;
  font-size: 15px;
  font-family: "DMSans-Bold";
}
.themes-theme-seven-special-services-screen
  .special-service
  .form-section
  .form
  .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  color: white;
}
.themes-theme-seven-special-services-screen
  .special-service
  .form-section
  .form
  .form-group
  label {
  display: inline-block;
  max-width: 100%;
  margin-bottom: 5px;
  font-weight: bold;
}
.themes-theme-seven-special-services-screen
  .special-service
  .form-section
  .form
  .form-group
  .form-control {
  height: 40px;
  box-shadow: none;
  border-color: transparent;
}
.themes-theme-seven-special-services-screen
  .special-service
  .form-section
  .form
  #submit_btn {
  background: #ffa100;
  border: 1px solid #ffa100;
  font-family: "DMSans-Bold";
  color: white;
  padding: 9px 30px;
  border-radius: 5px;
}
