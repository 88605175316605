.themes-theme-seven-ticket-sale-screen #ticket-sales {
  background: url("../../../assets/fernandez/services/BusesFernandez-Website-ServicesOnSiteSalesServices-Banner-WithoutText.jpg");
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  /* margin-bottom: 30px; */
}
.themes-theme-seven-ticket-sale-screen #ticket-sales .heading {
  position: relative;
  top: 140px;
}
.themes-theme-seven-ticket-sale-screen #ticket-sales .heading h1 {
  /* display: flex; */
  /* width: 80%; */
  margin: 0 auto;
  text-align: center;
  color: #ffffff;
  font-family: "DMSans-Bold";
  font-size: 25px;
}
/* .themes-theme-seven-ticket-sale-screen #ticket-sales .heading h1:before {
  content: '';
  flex: 1 1;
  border-top: 1px solid #ffffff;
  margin: auto;
  height: 100px;
  position: relative;
  top: 25px;
  border-left: 1px solid #ffffff;
  border-radius: 10px 0 0 0;
  margin-right: 15px;
}
.themes-theme-seven-ticket-sale-screen #ticket-sales .heading h1:after {
  content: '';
  flex: 1 1;
  border-top: 1px solid #ffffff;
  margin: auto;
  height: 100px;
  position: relative;
  top: 25px;
  border-right: 1px solid #ffffff;
  border-radius: 0 10px 0 0;
  margin-left: 15px;
} */
.themes-theme-seven-ticket-sale-screen #ticket-sales .heading .service-list {
  position: relative;
  top: 40px;
  padding: 0;
  margin: 0;
  list-style-type: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.themes-theme-seven-ticket-sale-screen #ticket-sales .heading .service-list li {
  background-color: #ffffff;
  color: #203b68;
  padding: 10px 25px;
  font-family: "DMSans-Bold";
  border-radius: 50px;
  font-size: 20px;
}
.themes-theme-seven-ticket-sale-screen
  #ticket-sales
  .heading
  .service-list
  li
  a:link {
  text-decoration: none;
}
.themes-theme-seven-ticket-sale-screen
  #ticket-sales
  .heading
  .service-list
  .active-color {
  background-color: #ffa100;
}
.themes-theme-seven-ticket-sale-screen
  #ticket-sales
  .heading
  .service-list
  .active-color
  a {
  color: white;
}
.themes-theme-seven-ticket-sale-screen .ticket-sales h3 {
  text-align: center;
  margin: 20px auto;
}
.themes-theme-seven-ticket-sale-screen .ticket-sales h3 span {
  color: #203b68;
  /* border: 1px solid #b2b6be; */
  /* border-radius: 50px; */
  /* padding: 6px 30px; */
  font-family: "DMSans-Bold";
  /* position: absolute; */
  /* top: -23px; */
  /* transform: translate(-145px); */
  background-color: #ffffff;
  font-size: 24px;
}
.themes-theme-seven-ticket-sale-screen .ticket-sales .cargo-details {
  border: 1px solid #b2b6be;
  padding: 4% 10px;
  border-radius: 10px;
}
.themes-theme-seven-ticket-sale-screen .ticket-sales .cargo-details h5 {
  text-align: center;
  font-size: 16px;
  color: #203b68;
  margin-bottom: 20px;
  line-height: 21px;
}
.themes-theme-seven-ticket-sale-screen .ticket-sales .cargo-details h5 span {
  font-family: "DMSans-Bold";
}
.themes-theme-seven-ticket-sale-screen .ticket-sales .cargo-details img {
  width: 100%;
  height: 350px;
}
.themes-theme-seven-ticket-sale-screen .ticket-sales .cargo-details .support {
  background-color: #3966b1;
  color: #ffffff;
  height: auto;
  line-height: normal;
  font-size: 14px;
  padding: 8px 10px;
  border-radius: 10px 10px 0 0;
}

.themes-theme-seven-ticket-sale-screen
  .ticket-sales
  .cargo-details
  .support
  span {
  color: #ffffff;
}
.themes-theme-seven-ticket-sale-screen
  .ticket-sales
  .cargo-details
  .support
  .se {
  justify-content: space-evenly;
}
.themes-theme-seven-ticket-sale-screen
  .ticket-sales
  .cargo-details
  .support
  .c-flex-row {
  display: flex;
  /* align-items: center; */
  justify-content: center;
  flex-direction: column;
}

.themes-theme-seven-ticket-sale-screen
  .ticket-sales
  .cargo-details
  .support
  .c-flex-row
  .flex {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.themes-theme-seven-ticket-sale-screen
  .ticket-sales
  .cargo-details
  .support
  img {
  width: 30px;
  height: auto;
}
.themes-theme-seven-ticket-sale-screen .ticket-sales .cargo-details .col-md-8,
.themes-theme-seven-ticket-sale-screen .ticket-sales .cargo-details .col-md-4 {
  padding: 0;
}
.themes-theme-seven-ticket-sale-screen
  .ticket-sales
  .cargo-details
  .iframe1
  .support {
  border-top-right-radius: 10px;
}
.themes-theme-seven-ticket-sale-screen
  .ticket-sales
  .cargo-details
  .iframe2
  .support {
  border-top-left-radius: 10px;
}
.themes-theme-seven-ticket-sale-screen
  .ticket-sales
  .cargo-details
  .iframe1
  .support {
  border-top-right-radius: 10px;
}
.themes-theme-seven-ticket-sale-screen .ticket-sales .cargo-details .mb-70 {
  margin-bottom: 70px;
}
