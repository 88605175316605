.ThemeTwoContactForm .contact {
  font-size: 15px;
}
.ThemeTwoContactForm .contact {
  padding: 0 20px;
  margin-top: 30px;
}

.ThemeTwoContactForm .contact-header {
  /* background: green; */
  padding: 0.8rem 0;
  /* padding-left: 20px; */
}

.ThemeTwoContactForm .info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-top: 30px;
  padding: 0 20px;
  
}

.ThemeTwoContactForm .info .info-item {
  padding: 0.8rem 0;
  display: flex;
  align-items: center;
  font-size: 15px;
}

.ThemeTwoContactForm .info .info-item .info-text {
}

.ThemeTwoContactForm .info .info-item img {
  width: 30px;
  height: auto;
  margin-right: 10px;
}

.ThemeTwoContactForm .contact-header .contact-header-text {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  /* border-bottom: 2px solid rgb(255, 239, 0); */
  display: inline-block;
  padding-bottom: 4px;
}

.ThemeTwoContactForm .contact .contact-bottom {
  /* background: #e4e5e9; */
  padding: 20px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.ThemeTwoContactForm .contact .contact-bottom .align-contact-inputs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  /* // padding-top:15px; */
}
.ThemeTwoContactForm
  .contact
  .contact-bottom
  .align-contact-inputs
  .contact-input-item {
  width: 100%;
  margin-right: 10px;
  margin-bottom: 10px;
}

.ThemeTwoContactForm .contact .contact-bottom .contact-input-label {
  margin-bottom: 10px;
  font-size: 16px;
  /* font-family: Poppins-Medium;
   */
}

.ThemeTwoContactForm .contact .contact-bottom .text-area-input {
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.ThemeTwoContactForm .contact .contact-bottom .text-area {
  border-radius: 10px;
  border: none;
  width: 100%;
  padding: 10px;
}

.py-60 {
  padding: 60px 0;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}

.contact-input-container {
  display: flex;
  flex-direction: column;
}

.contact-input-container.side-by-side {
  flex-direction: row;
  justify-content: space-between;
}

.contact-input-container.side-by-side .contact-input-item {
  flex: 1;
  margin-right: 10px; /* Adjust as needed for spacing */
}

.contact-input-container.side-by-side .contact-input-item:last-child {
  margin-right: 0; /* Remove margin for the last item */
}
