.pullman-san-andreas-safety {
  /* transform: translateY(-32px); */
}

.safety-items {
  display: flex;
  flex-direction: column;
}

.safety-items-left {
  /* width: 100%; */
}

.safety-items-right {
  /* width: 60%; */
  /* margin-left: 30px; */
}

.safety-items-right-details li {
  margin-bottom: 15px;
  color: #666666;
}

.safety-items-right-details-list .first-span {
  display: inline-block;
  width: 300px;
}

.safety-items-left-sub-image {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.safety-items-left-sub-image img {
  width: 80px;
  height: auto;
  margin: 0 10px;
}
/* .design-bg {
     height: 80px;
     background-image: url('/assets/pullmanSanAndres/design.svg');
     background-repeat: no-repeat;
     background-position-x: 100%;
   } */

/* .equipment-list {
	display: flex;
	justify-content: space-between;
}

.equipment-list-item {
	width: 120px;
} */

/* .safety-items-sensor-image {
	width: 360px;
} */

.pullman-san-andreas-safety .main-heading {
  font-size: 22px;
  margin-top: 40px;
  margin-bottom: 40px;

  color: #333;
}

.pullman-san-andreas-safety .sub-heading {
  font-size: 20px;
  color: #333;
}

.pullman-san-andreas-safety p {
  color: #666666;
  font-size: 13px;
  line-height: 1.3;
  font-weight: 700;
}

.pullman-san-andreas-safety .bottom-para {
  font-size: 20px;
  text-align: center;
}
