.themes-theme-four-office-section {
  /* display: flex; */
}
.themes-theme-four-office-section .contact-office-info {
  /* display: flex; */
}
.themes-theme-four-office-section .contact-office-info .item {
  flex: 1;
}
.themes-theme-four-office-section .contact-office-info .details .info {
  padding: 40px;
}
.themes-theme-four-office-section .contact-office-info img {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.themes-theme-four-office-section .contact-office-info .location {
  font-size: 16px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.themes-theme-four-office-section .contact-office-info .location .icon {
  width: auto;
  height: 25px;
  margin-right: 5px;
}
.themes-theme-four-office-section .contact-office-info .contact {
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.themes-theme-four-office-section .contact-office-info .contact .icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
.themes-theme-four-office-section .office {
  flex: 1;
}
.themes-theme-four-office-section .office .heading {
  font-size: 18px;
  text-align: center;
  padding: 10px;
  background: green;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}
.themes-theme-four-office-section .office .details .info {
  padding: 50px;
}
.themes-theme-four-office-section .office .location {
  font-size: 14px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.themes-theme-four-office-section .office .location .icon {
  width: auto;
  height: 25px;
  margin-right: 5px;
}
.themes-theme-four-office-section .office .contact {
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.themes-theme-four-office-section .office .contact .icon {
  width: 30px;
  height: 30px;
  margin-right: 5px;
}
