:root {
  color: #777;
  --primary-color: #5bab06;
  --primary-light-color: #7fae4e;
  --secondary-color: #012592;
  --dark-font: #464647;
  --light-font: #919191;
  --light-color: #c0c0c0;
  --border-color: #ddd;
}

/* @font-face {
  font-family: "AvenirNextLTPro-Light";
  src: url("/assets/fonts/AvenirLTStd-Light.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Regular";
  src: url("/assets/fonts/AvenirNextLTPro-Regular.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Demi";
  src: url("/assets/fonts/AvenirNextLTPro-Demi.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Bold";
  src: url("/assets/fonts/AvenirNextLTPro-Demi.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "AvenirNextLTPro-Black";
  src: url("/assets/fonts/AvenirLTStd-Black.otf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
} */

@font-face {
  font-family: "DMSans-Bold";
  src: url("../assets/fonts/DMSans-Bold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "DMSans-Black";
  src: url("../assets/fonts/DMSans-Black.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DM Sans", sans-serif;
  src: url("../assets/fonts/DMSans-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DMSans-Light", sans-serif;
  src: url("../assets/fonts/DMSans-Light.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "DMSans-Medium", sans-serif;
  src: url("../assets/fonts/DMSans-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* body {
  line-height: 1;
  background: var(--white-color);
  font-size: 14pt;
  font-weight: normal;
  color: var(--primary-text-color);
  font-family: 'AvenirNextLTPro-Regular' , sans-serif;
} */

/* Andimar Font Family And Classes Start */

/* @font-face {
  font-family: 'Montserrat-Black ,serif ';
  src: url('/assets/andimar/fonts/Montserrat-Black.ttf');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Montserrat-Bold ';
  src: url('/assets/andimar/fonts/Montserrat-Bold.ttf');
  font-weight: 700;
  font-style: bold;
} */

/* .react-multiple-carousel__arrow { right: 0 !important; } */
/* .react-multiple-carousel__arrow { left: -50 !important; } */
/* 
@font-face {
  font-family: 'Montserrat-SemiBold ';
  src: url('/assets/andimar/fonts/Montserrat-SemiBold.ttf');
  font-weight: 600;
  font-style: Semi-bold;
} */

.avenir-regular {
  font-family: "DM Sans", sans-serif;
}

.avenir-semi {
  font-family: "DMSans-Medium", sans-serif;
}

.avenir-bold {
  font-family: "DMSans-Bold", sans-serif;
}
.sans-regular {
  font-family: "DM Sans", sans-serif;
}
body {
	font-family: 'DMSans-Regular', sans-serif !important;
	font-size: 14px;
}

body > * {
	font-family: 'DMSans-Regular', sans-serif !important;
}

html.md {
  --ion-default-font: "DM Sans", sans-serif !important;
  font-size: 14px;
}

html.ios {
  --ion-default-font: "DM Sans", sans-serif !important;
  font-size: 14px;
}

.no-offset-content {
  --offset-bottom: 0 !important;
}

.keyboard-up {
  height: auto !important;
}

.margin-bottom-10 {
  margin-bottom: 10px !important;
}

div {
  color: #464647;
}

.discount-block {
  margin-top: 10px;
}

.text-uppercase {
  text-transform: uppercase;
}

.new-container {
  width: 90%;
  margin: auto;
}
.mt-30 {
  margin-top: 30px;
}
.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px;
}

.black-text {
  font-weight: normal;
  font-family: "DMSans-Black", sans-serif;
}

.light-text {
  font-weight: normal;
  font-family: "DMSans-Light", sans-serif;
}

.normal-text {
  font-weight: normal;
  font-family: "DM Sans", sans-serif;
}

.bold-text {
  font-weight: bold;
  font-family: "DMSans-Bold", sans-serif;
}

.less-bold-text {
  font-weight: normal;
  font-family: "DMSans-Medium", sans-serif;
}

.center-text {
  text-align: center;
}

.justify-text {
  text-align: justify;
}

.uppercase {
  text-transform: uppercase;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex1 {
  flex: 1;
}

.flex-center {
  justify-content: center;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.inline-flex {
  display: inline-flex !important;
}

.space-between {
  justify-content: space-between;
}

.align-start {
  align-items: flex-start;
}

.align-baseline {
  align-items: baseline;
}
.align-center {
  align-items: center;
}
.relative {
  position: relative;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input {
  border-top-style: hidden;
  border-right-style: hidden;
  border-left-style: hidden;
  border-bottom-style: hidden;
  background-color: #ffffff;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #bbb;
  opacity: 1;
  /* Firefox */
}

::-webkit-input-placeholder {
  color: #bbb;
}

/* Chrome/Opera/Safari */
::-moz-placeholder {
  color: #bbb;
}

/* Firefox 19+ */
:-ms-input-placeholder {
  color: #bbb;
}

/* IE 10+ */
:-moz-placeholder {
  color: #bbb;
}

input:focus {
  outline: none;
}

button:focus {
  outline: none;
}

.text-center {
  text-align: center;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.flex-center {
  display: flex;
  align-items: center;
}

.justify-flex-center {
  display: flex;
  justify-content: center;
}

.align-flex-center {
  display: flex;
  align-items: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.root-container {
  height: 100%;
  overflow: hidden;
}

.body-container {
  overflow: scroll;
}

.flex-center-column {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-flex {
  flex: 1;
}

.margin-top-0 {
  margin-top: 0px !important;
}

.margin-20 {
  margin: 0px 20px !important;
}
.margin-top-13 {
  margin-top: 13px !important;
}

.margin-40 {
  margin: 0px 40px !important;
}

.margin-top-10 {
  margin-top: 10px !important;
}

.margin-top-5 {
  margin-top: 5px;
}

.float-right {
  float: right;
}

.height-100-percent {
  height: 100%;
  overflow: hidden;
}

.dark-font {
  color: #464647 !important;
}

.green-font {
  color: #9acd32 !important;
}

.light-font {
  color: var(--light-font) !important;
}

.white-text {
  color: #fff !important;
}

.black-font {
  color: #000000;
}

.primary-light-color-bg {
  background-color: var(--primary-light-color) !important;
  color: var(--primary-color) !important;
}

.light-grey-font {
  color: #a4a4a6;
}

.aaa-font {
  color: #aaa;
}

.bbb-font {
  color: #bbb;
}

.nine-font {
  color: #999;
}

.bold-font {
  /* font-family:"Carnas Bold"; */
  font-weight: bold;
  font-family: "DMSans-Bold", sans-serif;
}
.new-bold-font {
  font-weight: bold;
  font-family: "sans_Pro_Medium ";
}

.side-menu-new-bold-font {
  /* font-weight: bold; */
  font-family: "sans_Pro_Medium " !important;
}

.primary-font {
  color: #ff7f45 !important;
}

.red-font {
  color: red !important;
}

.secondary-font {
  color: var(--secondary-color) !important;
}

.light-color {
  color: #c0c0c0 !important;
}

.underlined-text {
  text-decoration: underline;
}

.white-font {
  color: #ffffff !important;
}

.primary-color-font {
  color: var(--primary-color) !important;
}

.align-center {
  display: flex;
  align-items: center;
}

.text-align-center {
  text-align: center;
}

.white-space {
  width: 5px;
}

.underline-text {
  text-decoration: underline;
}

.kupos-border {
  border: 1px solid var(--border-color);
}

a {
  text-decoration: none;
}

.triangle-up {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-bottom: 100px solid #fff;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 50px solid transparent;
  border-right: 50px solid transparent;
  border-top: 100px solid #fff;
}

.kupos-button-secondary {
  background-color: var(--secondary-color);
  color: #fff !important;
  border-radius: 15px;
  padding: 5px 0px;
  display: block;
  cursor: pointer;
}

.font6 {
  font-size: 6px !important;
}

.font7 {
  font-size: 7px !important;
}

.font8 {
  font-size: 8px !important;
}

.font9 {
  font-size: 9px !important;
}

.font10 {
  font-size: 10px !important;
}

.font11 {
  font-size: 11px !important;
}

.font12 {
  font-size: 12px !important;
}

.font13 {
  font-size: 13px !important;
}

.font14 {
  font-size: 14px !important;
}

.font15 {
  font-size: 15px !important;
}

.font16 {
  font-size: 16px !important;
}

.font17 {
  font-size: 17px !important;
}

.font18 {
  font-size: 18px !important;
}

.font19 {
  font-size: 19px !important;
}

.font20 {
  font-size: 20px !important;
}

.font21 {
  font-size: 21px !important;
}

.font22 {
  font-size: 22px !important;
}

.font23 {
  font-size: 23px !important;
}

.font24 {
  font-size: 24px !important;
}

.font25 {
  font-size: 25px !important;
}

.font26 {
  font-size: 26px !important;
}

.font30 {
  font-size: 30px !important;
}

.font32 {
  font-size: 32px !important;
}

.font33 {
  font-size: 33px !important;
}

.font39 {
  font-size: 39px !important;
}

.font40 {
  font-size: 40px !important;
}

.font42 {
  font-size: 42px !important;
}

.font47 {
  font-size: 47px !important;
}

.font48 {
  font-size: 48px !important;
}

.lh-1-3 {
  line-height: 1.3;
}
/* ----------- add to home start --------- */

.andimar-btn-link {
  background: #ec1c24;
  text-transform: uppercase;
  font-size: 14px;
  color: #ffffff;
  border-radius: 50px;
  display: block;
  padding: 10px 0px;
  margin: 0 70px;
  width: 90%;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}

.add-to-home-button {
  display: flex;
  justify-content: space-between;
  position: fixed;
  width: 100%;
  bottom: 0px;
  max-width: 900px;
  align-items: center;
  justify-content: center;
  padding: 12px 16px;
  background-color: #fff;
  border: 1px solid #ddd;
}

.add-to-home-button .add-to-home-logo {
  display: flex;
  align-items: center;
  justify-self: center;
}

.add-to-home-button .add-to-home-logo img {
  width: 40px;
  margin-right: 10px;
  border-radius: 5px;
}

.add-to-home-button .add-to-home-link {
  color: var(--primaryColor);
  text-transform: uppercase;
  text-decoration: underline;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  justify-self: center;
}

.add-to-home-button .add-to-home-link img {
  width: 25px;
}

.add-to-home-button .close-btn {
  position: absolute;
  top: -24px;
  height: 22px;
  width: 22px;
  background-color: #fff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 2px;
  color: var(--primaryColor);
  font-size: 1.2rem;
  display: none;
}

.add-to-home-button .store-details {
  flex: 1;
}

.add-to-home-button .instant-access {
  font-size: 0.85rem;
  margin-top: 0px;
}

/* -----
------ add to home end --------- */

.main-seat-layout-container ion-select {
  /* min-width: 75vw; */
  min-width: 100%;
  flex: 1;
  padding-left: 0px;
}

.main-seat-layout-container ion-item {
  padding-left: 0px;
  border-bottom: none;
  height: 40px;
  width: 100%;
}

.main-seat-layout-container .button-inner-container {
  padding-top: 0px;
  padding-bottom: 0px;
}

/* ---------------- svgs start ------------------ */
.common-svg-amenities .ac-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .ac-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .restrooms-white-cls-1,
.common-svg-amenities .restrooms-white-cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.common-svg-amenities .restrooms-grey-cls-1,
.common-svg-amenities .restrooms-grey-cls-2 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.common-svg-amenities .restrooms-white-cls-1,
.common-svg-amenities .restrooms-grey-cls-1 {
  stroke-width: 30px;
}

.common-svg-amenities .restrooms-white-cls-2,
.common-svg-amenities .restrooms-grey-cls-2 {
  stroke-width: 26px;
}

.common-svg-amenities .charger-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .charger-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .food-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .food-white-cls-2 {
  fill: #fff;
}

.common-svg-amenities .food-grey-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .food-grey-cls-2 {
  fill: #fff;
}

.common-svg-amenities .mobile-ticket-white-cls-1,
.common-svg-amenities .mobile-ticket-white-cls-3 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.common-svg-amenities .mobile-ticket-white-cls-2 {
  fill: #fff;
}

.common-svg-amenities .mobile-ticket-grey-cls-1,
.common-svg-amenities .mobile-ticket-grey-cls-3 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.common-svg-amenities .mobile-ticket-grey-cls-2 {
  fill: #464648;
}

.common-svg-amenities .mobile-ticket-white-cls-1,
.common-svg-amenities .mobile-ticket-grey-cls-1 {
  stroke-width: 30px;
}

.common-svg-amenities .mobile-ticket-white-cls-3,
.common-svg-amenities .mobile-ticket-grey-cls-3 {
  stroke-width: 20px;
}

.common-svg-amenities .music-white-cls-1,
.common-svg-amenities .music-white-cls-2 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.common-svg-amenities .music-grey-cls-1,
.common-svg-amenities .music-grey-cls-2 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.common-svg-amenities .music-white-cls-1,
.common-svg-amenities .music-grey-cls-1 {
  stroke-width: 30px;
}

.common-svg-amenities .music-white-cls-2,
.common-svg-amenities .music-grey-cls-2 {
  stroke-width: 46px;
}

.common-svg-amenities .snack-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .snack-white-cls-2 {
  fill: #fff;
}

.common-svg-amenities .snack-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .snack-grey-cls-2 {
  fill: #464648;
}

.common-svg-amenities .baggage-white-cls-1,
.common-svg-amenities .baggage-white-cls-2 {
  fill: none;
  stroke: #fff;
}

.common-svg-amenities .baggage-grey-cls-1,
.common-svg-amenities .baggage-grey-cls-2 {
  fill: none;
  stroke: #464648;
}

.common-svg-amenities .baggage-white-cls-1,
.common-svg-amenities .baggage-grey-cls-1 {
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .baggage-white-cls-2,
.common-svg-amenities .baggage-grey-cls-2 {
  stroke-linecap: square;
  stroke-miterlimit: 10;
  stroke-width: 46px;
}

.common-svg-amenities .tv-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .tv-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .handicap-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .handicap-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .wifi-white-cls-1 {
  fill: none;
  stroke: #fff;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .wifi-white-cls-2 {
  fill: #fff;
}

.common-svg-amenities .wifi-grey-cls-1 {
  fill: none;
  stroke: #464648;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 30px;
}

.common-svg-amenities .wifi-grey-cls-2 {
  fill: #464648;
}

/* --------------------- svgs end ------------------ */

@keyframes blinking {
  0% {
    background-color: var(--secondary-color);
  }

  /* YOU CAN ADD MORE COLORS IN THE KEYFRAMES IF YOU WANT
  50%{
    background-color: #55d66b;
    border: 5px solid #126620;
  }
  */
  100% {
    background-color: var(--primary-color);
  }
}

.kupos-page-scroll {
  height: calc(100vh - 55px);
  overflow: scroll;
  position: relative;
}

.top-strip-margin {
  margin-top: 85px;
}

.invert-color {
  filter: invert(1);
}

.flex-100 {
  width: 100%;
  display: flex;
}

.margin-left-6vw {
  margin-left: 6vw;
}

.margin-left-2vw {
  margin-left: 2vw;
}

.margin-left-10 {
  margin-left: 10px !important;
}

.margin-right-10 {
  margin-right: 10px !important;
}

.item-container-outer.kupos-card {
  background-color: #fff;
  padding: 12px 0;
  margin: 26px 0px;
  margin-bottom: 35px;
  position: relative;
  z-index: 10;
  border-radius: 18px;
}
.item-container-outer.kupos-card .direct-service-ui {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  position: absolute;
  right: 0;
  top: -13px;
  padding: 0 20px;
  /* margin-left: 20px; */
}

.item-container-outer.kupos-card .direct-service-ui .bg {
  width: 85px;
  height: 28px;
}

.item-container-outer.kupos-card .direct-service-ui .wrapper {
  width: 85px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 6px;
  padding-left: 15px;
  padding-right: 10px;
}

.item-container-outer.kupos-card .direct-service-ui .direct-arrow {
  width: 9px;
}

.item-container-outer.kupos-card .direct-service-ui .directo {
  font-size: 10px;
  color: white;
  font-weight: bolder !important;
  margin-top: 3px;
}

.topMainIconLabelContainer {
  margin-top: 4px;
}

.header-back-arrow {
  width: auto;
  height: 23px;
  /* margin-top: 5px; */
  /* filter: invert(1); */
}

ion-menu {
  --background: transparent;
  --width: 260px;
}

ion-menu ion-content {
  --background: transparent;
  overflow: hidden;
}

ion-content {
  --keyboard-offset: 0 !important;
}

.content-transparent {
  --background: transparent;
}

.pic-cropper-div {
  position: fixed !important;
  z-index: 999;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.pic-cropper-buttons {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  background-color: rgba(0, 0, 0, 0.6);
}

.pic-cropper-buttons div {
  flex: 1;
  text-align: center;
  padding: 10px;
  color: #fff;
  cursor: pointer;
}

.pic-cropper-buttons div:nth-child(1) {
  border-right: 1px solid #aaa;
}

.ReactCrop {
  position: absolute !important;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
  top: 0;
  bottom: 50px;
  right: 0;
  left: 0;
}

.menu-inner {
  box-shadow: 0;
}

.menu-container-div {
  background-color: #fff;
  overflow: hidden;
  border-radius: 0 20px 20px 0;
  height: 100%;
}

.split-menu-custom {
  border-radius: 0 20px 20px 0;
}

.no-padding {
  padding: 0px;
}
.pb-30 {
  padding-bottom: 30px;
}

.kupos-card {
  border-radius: 8px;
  background-color: rgb(256, 256, 256);
  box-shadow: 0px 10px 10px 0px #ddd;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.09);
  z-index: 2;
  padding: 20px;
  margin: 20px;
  padding-bottom: 20px;
}

.display-fex-start {
  display: flex;
  align-self: flex-start;
}

.common-kupos-counter {
  margin-top: 3px;
  width: 100%;
  max-width: 200px;
}

.common-kupos-counter .coumter-inner {
  height: 45px;
  align-items: center;
  border-radius: 10px;
}

.common-kupos-counter .coumter-inner img {
  padding: 0;
  margin: 0 10px;
  width: 20px;
}

.common-kupos-counter span.svg-span {
  width: 20px;
  margin: 10px;
}

.home-screen-background {
  background-image: url("../assets/images/default-home-bg.png");
  width: 100%;
  height: 100%;
}

.mainBgImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  /* z-index: -1; */
}

.main-bg-short {
  width: 100%;
  height: 50%;
  position: absolute;
  top: 0;
  /* z-index: -1; */
}

.main-bottom-image {
  width: 100%;
  height: 200px;
  position: absolute;
  bottom: 0px;
  z-index: 0;
}

.carpol-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 56px;
  align-items: center;
  position: relative;
  padding-top: 16px;
  padding: 0px 10px;
  padding-bottom: 6px;
  padding-top: 6px;
  /* position: fixed; */
  width: 100%;
  z-index: 999;
  top: 0;
}
/* pet-seat-info */
.pet-seat-info-container .pet-seat-info {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.carpol-header .label-center {
}

.carpol-header + * {
  /* position: relative; */
  /* top: 56px; */
}

.carpol-header.white-bg {
  background-color: #fff;
}

.main-body-container {
  position: relative;
}

.carpol-homeMenu {
  margin-top: -4px;
}

.carpol-homeMenuInner {
  padding: 15px 9px 10px;
}

.carpol-homeMenuInner img {
  height: 25px;
  width: auto;
}

.carpol-menu-home-icon img {
  height: 25px;
  margin-right: 10px;
}

.carpol-label {
  width: 100%;
  height: 40px;
  position: absolute;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  margin-top: -10px;
}

.topMainContainer {
  margin-top: 10px 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.display-flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.display-flex-column {
  display: flex;
  flex-direction: column;
}

.pb-modal-backdrop {
  display: flex;
  flex: 1;
  width: 100vh;
  height: 100vh;
  position: absolute;
  align-items: center;
  justify-content: center;
}

ion-select {
  opacity: 1 !important;
  color: #464647;
}

select-placeholder {
  color: var(--dark-font) !important;
  opacity: 1 !important;
}

.pb-modal {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.pb-modal-icon-container {
  width: 100%;
  padding: 10px;
  padding-top: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80px;
  /* margin-top: 15px; */
}

.pb-modal-container {
  border-radius: 20px;
  width: 82vw;
  padding-bottom: 25px;
}

.modal-outer {
  width: 90%;
}

.pb-modal-body-content {
  margin-bottom: 15px;
  margin-top: 15px;
}

.pb-modal-bottom {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
}

.modal-back-drop {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
}

.pb-modal-content {
  position: relative;
  /* bottom: 5vh; */
  z-index: 1;
  /* width: 90vw; */
}

.contact-modal {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 70px;
  background-color: rgba(0, 0, 0, 0.4);
}

.contact-modal .contact-row {
  margin: 10px 0;
}

.contact-modal .contact-icons {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin-top: 30px;
  margin-bottom: 18px;
}

.contact-modal .contact-icons img {
  width: 58%;
  margin-bottom: 7px;
}

.contact-modal-backdrop {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.pb-loader {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.3);
}

.pb-loader ion-spinner {
  --color: #fff;
}

.dropdown-wrapper {
  position: relative;
  padding: 0 !important;
}

.dropdown-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdown-mask img {
  margin: 0;
  width: 17px;
}

.dropdown-transparent {
  height: 100%;
  width: 100%;
  position: relative;
  opacity: 0;
}

/* contact form 4 */
.contact-form {
  border-radius: 10px;
  overflow: hidden;
  width: 90%;
}

.contact-form .contact-form__head {
  /* background-color: #161d4f; */
  color: #fff;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 15px 20px;
}

.contact-form__container {
  background-color: #f3f7ff;
}

.contact-form__container label {
  color: #161d4f;
  font-size: 14px;
  font-weight: bold;
}

.contact-form__container input,
.contact-form__container textarea {
  font-size: 16px;
  padding: 5px 10px;
  /* border: 1px solid #999; */
  border-radius: 5px;
}

.contact-form__container button {
  border: none;
  background-color: #ec1c24;
  font-size: 16px;
  text-transform: uppercase;
  padding: 10px;
  font-weight: 500;
  border-radius: 100px;
  color: #fff;
}

/* calendar styling */
.common-kupos-calendar {
  width: 300px;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  padding: 20px;
  box-shadow: 1px 1px 16px 0px rgba(0, 0, 0, 0.08);
  margin: auto;
}

.common-kupos-calendar b {
  font-weight: normal !important;
}

.common-kupos-calendar .month-label {
  display: flex;
  flex-direction: row;
  /* align-items: center; */
  justify-content: space-between;
  background-color: var(--primary-color);
  color: #fff;
  margin-bottom: 8px;
  border-radius: 10px;
  padding: 10px;
}

.common-kupos-calendar .month-label div {
  color: #fff;
}

.common-kupos-calendar .month-label img {
  /* filter: grayscale(1); */
  width: auto;
  height: 18px;
  /* position: static; */
  display: flex;
  margin-top: 2px;
  align-items: center;
  align-self: center;
}

.common-kupos-calendar .month-label .svg-span {
  width: 15px;
  position: static;
  margin: 0;
}

.common-kupos-calendar .WeekDays {
  display: flex;
  text-align: center;
}

.common-kupos-calendar .WeekDays .unit {
  background-color: transparent;
  flex: 1;
  color: var(--primary-color);
  padding: 5px 0;
}

.common-kupos-calendar .monyear {
  margin-top: 3px;
}

.common-kupos-calendar .days {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  min-height: 165px;
}

.common-kupos-calendar .day-number {
  width: 36px;
  height: 31px;
  padding: 3px;
}

.common-kupos-calendar .day-number .day-number-inner {
  width: 28px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  padding-top: 5px;
}

.common-kupos-calendar .day-number.selected .day-number-inner {
  color: var(--primary-color);
  background-color: rgba(252, 118, 59, 0.2);
  display: block;
}

.common-kupos-calendar .day-number.past .day-number-inner,
.common-kupos-calendar .day-number.deactivate .day-number-inner {
  opacity: 0.3;
}

.common-kupos-calendar .day-number.next-month {
  height: 0px;
}

.common-kupos-calendar .day-number.next-month {
  padding: 0px;
}

.calendar-heading {
  margin: 10px 0px;
  margin-bottom: 18px;
}

/*  */

.top-menu-icon {
  display: flex;
}

.top-menu-icon img {
  height: 50px;
}

.topMainIconContainer {
  margin: 10px 0 0;
  width: 100%;
  /* display: flex;
  flex-direction: row;
  justify-content: space-betweenlogin-main-icon-container; */
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.bodyIconContainer {
  width: 100%;
}

.w-100 {
  width: 100%;
}

.px-5 {
  padding-bottom: 5px;
  padding-top: 5px;
}

.form-4 {
  padding: 30px 20px;
}

.form-group input,
.form-group textarea {
  margin-top: 5px;
  padding: 10px;
}

.bodyIconContainerRow {
  margin: 25px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.topMainIconItemContainer {
  align-items: center;
  justify-content: flex-start;
}

.topMainIconImg {
  border-radius: 50%;
  height: 52px;
  width: auto;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}

.bodyIconContainer .topMainIconImg {
  width: 60px;
  height: 60px;
}

.bodyIconContainer .topMainIconImg .top-menu-icon img {
  height: 60px;
  width: auto;
}

.kupos-com-main-image-container {
  display: flex;
  justify-content: center;
}

.kupos-com-main-image {
  /* position: absolute;
  top: 15px; */
  width: 150px !important;
  height: 30px !important;
}

.availableBalance {
  padding-inline: 5px 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.homeContainer {
  flex: 1;
  padding: 0px 20px;
  margin-top: 35px;
}

.homeContainer .kupos-card.home-main-card {
  margin: 0;
  position: relative;
  bottom: 24px;
}

.line {
  width: 100%;
  margin: 5px 25px;
  border-bottom: 1px solid #ccc;
}

.amount-seperator {
  width: 100%;
  margin: 5px 0px;
}

.border-half-three {
  border-bottom: 1px solid #ccc;
}

/* Home Page */

.home-container {
  flex: 1;
  /* padding:0px 20px; */
}

.home-header {
  width: 100%;
  flex-direction: row;
  padding-top: 25px;
  padding-bottom: 5px;
  justify-content: space-between;
}

.home-menu-inner {
  width: 8vw + 18px;
  padding: 5px;
  padding: 0px 9px;
}

.home-menu-inner img {
  width: 35px;
  height: auto;
}

hr {
  height: 0;
  border-top: 1px solid #ccc;
}

.home-menu-title-text {
  margin-top: 0px;
  margin-bottom: 5px;
  align-items: center;
}

.home-menu {
  margin-left: -10px;
}

.logo-container {
  margin-top: 40px;
  width: 100%;
  margin-bottom: 40px;
}

.city-box-container {
  display: grid;
  grid-template-columns: 9fr 1fr;
  border-radius: 10px;
  height: 105px;
  padding: 5px 10px;
  width: 100%;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.city-selection-container {
  /* display: grid;
  grid-template-columns: 9fr 1fr; */
  display: flex;
  align-items: center;
  border-radius: 10px;
  height: 44px;
  padding: 5px 10px;
  width: 100%;
  border: 1px solid #ccc;
  margin-top: 5px;
}

.city-container {
  display: grid;
  grid-template-rows: 1fr 0.01fr 1fr;
  padding: 12px 0px;
  grid-gap: 8px;
}

.city-container {
  width: 100%;
}

.city-container > div {
  width: 90%;
}

.city-box-item {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin-top: 6px;
}

.city-box-item img {
  width: auto;
  height: 17px;
  /* height: 23px; */
  margin-left: 0px;
  margin-right: 13px;
}

.city-box-item-inner {
  width: 20px;
  align-items: center;
}

.city-box-city {
  margin: 0px 10px;
}

.city-box-label-container {
  width: 80px;
  padding-left: 10px;
}

.city-item-divider {
  border-bottom: 1px solid #ccc;
  margin-left: 15px;
}

.swap-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-left: 10px;
}

.swap-container img {
  height: auto;
  width: 5vw;
}

.date-box-container {
  /* display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px; */
  margin-top: 18px;
  width: 100%;
  position: relative;
}

.date-box-button {
  overflow: hidden;
  width: 95%;
  margin-top: 5px;
}

.date-item {
  background-color: rgba(255, 255, 255, 0.94);
  border-radius: 5px;
  /* padding: 10px; */
  flex: 1;
}

.date-label-contaner {
  width: 100%;
}

.dateLabel {
  color: #aaa;
  font-size: 10px;
}

.date-value-contaner {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  margin-top: 5px;
}

.primary-grad {
  background-image: linear-gradient(#fc763b, #febb3d);
}

.primary-color {
  background-color: #fc763b;
}

.search-button-container {
  height: 40px;
  margin-top: 35px;
  border-radius: 5px;
}

.pb-button {
  height: 100%;
  width: 100%;
  background-color: var(--primary-color);
  padding: 12px 0px;
  justify-content: center;
  align-items: center;
  align-self: center;
  height: 11vw;
  border-radius: 8px;
  color: #fff;
  font-size: 17px;
  max-height: 55px;
}

.pb-button div,
.pb-button span {
  color: #fff;
}

button.pb-button.secondary-button,
button.pb-button.secondary-button span {
  background-color: var(--primary-light-color);
  color: var(--primary-color) !important;
}

button.pb-button.disbaled-button {
  background-color: var(--light-color) !important;
}
.buttons-container button.pb-button.secondary-button {
  margin-top: 10px;
}

.white-button span {
  color: rgb(252, 131, 50);
  background-color: rgb(255, 255, 255);
}

.pb-button-else {
  height: 11vw;
  width: 100%;
  background-color: var(--primary-color);
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 8px;
}

.pb-button-else-else {
  height: 11vw;
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: center;
  border-radius: 8px;
}

.button-seat-selected {
  margin-bottom: 30px;
  width: 90%;
  height: 11vw;
}

.pb-button-text-container {
  display: flex;
  /* flex:1; */
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pb-button-text {
  text-align: center;
  color: #ffffff !important;
  background-color: transparent;
  font-size: 15px;
}

.bottom-strip-container {
  width: 100%;
  display: flex;
  flex: 1;
  position: absolute;
  bottom: 0;
  flex-direction: row;
  align-items: center;
  height: 50px;
  justify-content: space-evenly;
}

.bottom-strip-item-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.bottom-menu-icon {
  margin-bottom: 3px;
  height: 18px;
}

.bottom-menu-label {
  font-size: 10px;
  color: #fff;
}

.side-menu-main-container {
  display: flex;
  flex: 1;
  background-color: #fff;
  justify-content: space-between;
}
.side-menu-item .newdisplay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.side-menu-item .newimg {
  height: auto;
  width: 7px;
}
.newdisplay img {
  height: auto;
  width: 7px;
}
ion-item.side-menu-item {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.side-menu-item .item-inner {
  --border-width: 0;
}

.side-menu-item img {
  height: auto;
  width: 18px;
}

.side-menu-item .side-menu-label {
  padding-left: 10px;
}
.side-menu-item .newdisplay {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}
.newdisplay img {
  height: auto;
  width: 7px;
}

.home-icon-sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url("../assets/sprite/home-sprite.png");
}

.home-icon-sprite.bl {
  width: 32px;
  height: 32px;
  background-position: 0.892857% 0.892857%;
  background-size: 800%;
}

.home-icon-sprite.buy-in-cash {
  width: 32px;
  height: 32px;
  background-position: 16.0714% 0.892857%;
  background-size: 800%;
}

.home-icon-sprite.chat {
  width: 32px;
  height: 32px;
  background-position: 31.25% 0.892857%;
  background-size: 800%;
}

.home-icon-sprite.contact {
  width: 32px;
  height: 32px;
  background-position: 61.6071% 0.892857%;
  background-size: 800%;
}

.home-icon-sprite.contact-us {
  width: 32px;
  height: 32px;
  background-position: 46.4286% 0.892857%;
  background-size: 800%;
}

.home-icon-sprite.fb {
  width: 32px;
  height: 32px;
  background-position: 76.7857% 0.892857%;
  background-size: 800%;
}

.home-icon-sprite.glossary {
  width: 32px;
  height: 32px;
  background-position: 91.9643% 0.892857%;
  background-size: 800%;
}

.home-icon-sprite.group {
  width: 32px;
  height: 32px;
  background-position: 0.892857% 16.0714%;
  background-size: 800%;
}

.home-icon-sprite.icon_avatar_circular {
  width: 32px;
  height: 32px;
  background-position: 16.0714% 16.0714%;
  background-size: 800%;
}

.home-icon-sprite.icon_contact {
  width: 32px;
  height: 32px;
  background-position: 31.25% 16.0714%;
  background-size: 800%;
}

.home-icon-sprite.icon_discuss_issue {
  width: 32px;
  height: 32px;
  background-position: 46.4286% 16.0714%;
  background-size: 800%;
}

.home-icon-sprite.icon_group {
  width: 32px;
  height: 32px;
  background-position: 61.6071% 16.0714%;
  background-size: 800%;
}

.home-icon-sprite.icon_info {
  width: 32px;
  height: 32px;
  background-position: 76.7857% 16.0714%;
  background-size: 800%;
}

.home-icon-sprite.icon_my_trips {
  width: 32px;
  height: 32px;
  background-position: 91.9643% 16.0714%;
  background-size: 800%;
}

.home-icon-sprite.icon_promotions {
  width: 32px;
  height: 32px;
  background-position: 0.892857% 31.25%;
  background-size: 800%;
}

.home-icon-sprite.icon_QR_code {
  width: 32px;
  height: 32px;
  background-position: 16.0714% 31.25%;
  background-size: 800%;
}

.home-icon-sprite.in {
  width: 32px;
  height: 32px;
  background-position: 31.25% 31.25%;
  background-size: 800%;
}

.home-icon-sprite.info {
  width: 32px;
  height: 32px;
  background-position: 46.4286% 31.25%;
  background-size: 800%;
}

.home-icon-sprite.log-out {
  width: 32px;
  height: 32px;
  background-position: 61.6071% 31.25%;
  background-size: 800%;
}

.home-icon-sprite.movements {
  width: 32px;
  height: 32px;
  background-position: 76.7857% 31.25%;
  background-size: 800%;
}

.home-icon-sprite.my-account {
  width: 32px;
  height: 32px;
  background-position: 91.9643% 31.25%;
  background-size: 800%;
}

.home-icon-sprite.my-movements {
  width: 32px;
  height: 32px;
  background-position: 0.892857% 46.4286%;
  background-size: 800%;
}

.home-icon-sprite.my-tickets {
  width: 32px;
  height: 32px;
  background-position: 16.0714% 46.4286%;
  background-size: 800%;
}

.home-icon-sprite.pencil {
  width: 32px;
  height: 32px;
  background-position: 31.25% 46.4286%;
  background-size: 800%;
}

.home-icon-sprite.profile {
  width: 32px;
  height: 32px;
  background-position: 46.4286% 46.4286%;
  background-size: 800%;
}

.home-icon-sprite.promo {
  width: 32px;
  height: 32px;
  background-position: 61.6071% 46.4286%;
  background-size: 800%;
}

.home-icon-sprite.promotion {
  width: 32px;
  height: 32px;
  background-position: 76.7857% 46.4286%;
  background-size: 800%;
}

.home-icon-sprite.qr-code {
  width: 32px;
  height: 32px;
  background-position: 91.9643% 46.4286%;
  background-size: 800%;
}

.home-icon-sprite.rate-us {
  width: 32px;
  height: 32px;
  background-position: 0.892857% 61.6071%;
  background-size: 800%;
}

.home-icon-sprite.scan {
  width: 32px;
  height: 32px;
  background-position: 16.0714% 61.6071%;
  background-size: 800%;
}

.home-icon-sprite.terms {
  width: 32px;
  height: 32px;
  background-position: 31.25% 61.6071%;
  background-size: 800%;
}

.home-icon-sprite.tickets {
  width: 32px;
  height: 32px;
  background-position: 46.4286% 61.6071%;
  background-size: 800%;
}

.home-icon-sprite.transfer-money {
  width: 32px;
  height: 32px;
  background-position: 61.6071% 61.6071%;
  background-size: 800%;
}

.home-icon-sprite.tw {
  width: 32px;
  height: 32px;
  background-position: 76.7857% 61.6071%;
  background-size: 800%;
}

.home-icon-sprite.user {
  width: 32px;
  height: 32px;
  background-position: 91.9643% 61.6071%;
  background-size: 800%;
}

.home-icon-sprite.wallet {
  width: 32px;
  height: 32px;
  background-position: 0.892857% 76.7857%;
  background-size: 800%;
}

.home-icon-circular-sprite {
  display: inline-block;
  overflow: hidden;
  background-repeat: no-repeat;
  background-image: url("../assets/sprite/home-circular-sprite.png");
}

.home-icon-circular-sprite.airport-transfer-circle {
  width: 96px;
  height: 96px;
  background-position: 71.1538% 24.0385%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.bip-circle {
  width: 96px;
  height: 96px;
  background-position: 0.480769% 0.480769%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.bus-circle {
  width: 96px;
  height: 96px;
  background-position: 47.5962% 0.480769%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.camera-add-circle {
  width: 96px;
  height: 96px;
  background-position: 24.0385% 0.480769%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.carpool-circle {
  width: 96px;
  height: 96px;
  background-position: 71.1538% 0.480769%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.icon_kupos-go {
  width: 96px;
  height: 96px;
  background-position: 94.7115% 0.480769%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.icon_kupos_premium {
  width: 96px;
  height: 96px;
  background-position: 94.7115% 24.0385%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.recharge-circle {
  width: 96px;
  height: 96px;
  background-position: 0.480769% 47.5962%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.scan-circle {
  width: 96px;
  height: 96px;
  background-position: 0.480769% 24.0385%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.train-circle {
  width: 96px;
  height: 96px;
  background-position: 47.5962% 24.0385%;
  background-size: 533.333%;
}

.home-icon-circular-sprite.transfer-circle {
  width: 96px;
  height: 96px;
  background-position: 24.0385% 24.0385%;
  background-size: 533.333%;
}

.side-menu-item div.home-icon-sprite {
  width: 18px;
  height: 18px;
}

div.home-icon-circular-sprite.size60 {
  width: 60px !important;
  height: 60px !important;
}

div.home-icon-circular-sprite.size50 {
  width: 50px !important;
  height: 50px !important;
}

div.home-icon-circular-sprite.size55 {
  width: 55px !important;
  height: 55px !important;
}

.welcome-image-container {
  display: flex;
  flex: 1;
  align-items: center;
  /* justify-content: center; */
  padding: 25px;
  padding-top: 40px;
}

.welcome-image {
  width: auto;
  height: 60px;
}

.welcome-image-circle {
  height: 55px;
  width: 55px;
  overflow: hidden;
  border-radius: 50%;
  display: flex;
  /* align-items: center;
  justify-content: center;
  background-color: #ccc; */
  /* background-image: url('/assets/carpool/camera-add-circle.png'); */
  background-size: cover;
  background-position: center;
}

.welcome-image-logged-in {
  background-position: center center;
  height: 55px;
  width: 55px;
  overflow: hidden;
  border-radius: 50%;
}

.upload-image-div {
  /* image-orientation: from-image; */
}

.show-strip {
  height: 80px;
  width: 100%;
  margin: 10px 20px;
  margin-right: 50px;
  background-color: #fc763b;
  border-radius: 20px;
}

.pink-box {
  background-color: #fde9e0;
  height: 8vh;
  border-radius: 10px;
  margin: 0px 20px;
  /* margin-bottom:20px; */
}

.welcome-container {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
}

.welcome-image-container {
  padding: 20px;
  padding-top: 25px;
}

.user-name-container {
  justify-content: center;
  margin-left: 10px;
}

.input-container {
  margin-bottom: 10px;
}

.input-50 {
  height: 50px !important;
}

.input-box-shadow {
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.09);
}

.search-input-container input {
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.09);
  margin-bottom: 10px;
}

.text-input {
  width: 100%;
  border-color: #ccc;
  border-width: 1px;
  border-radius: 10px;
  padding: 10px;
}

.loader-container {
  width: 100vw;
  height: 75vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.model-top-strip {
  padding: 15px 15px;
  display: grid;
  grid-template-columns: 1fr 6fr 1fr;
  grid-gap: 10px;
  background-color: #fc763b;
  z-index: 99;
  width: 100%;
}

.model-top-strip a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.white-model-top-strip {
  padding: 15px 20px;
  background-color: #fff;
  z-index: 99;
  width: 100%;
}

.model-top-strip img {
  width: auto;
  height: 20px;
  filter: invert(1);
}

.model-top-strip-label {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #fff;
}

textarea {
  width: 100%;
  border: 1px solid #ccc;
  border-radius: 10px;
  min-height: 80px;
  padding: 10px;
}

.select-city-body {
  height: calc(100vh - 100px);
}

.select-city-body .select-city-body-inner {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  padding-top: 0px;
}

.city-list {
  flex: 1;
  height: calc(100% - 40px);
  overflow: auto;
}

.city-list .city-box-item {
  flex-direction: row;
  width: 100%;
  align-items: center;
  height: 35px;
}

.city-list .city-icon-container {
  width: 30px;
}

.city-list .city-icon-container img {
  width: auto;
  height: 21px;
}

.city-list .city-icon-container.at {
  width: 24px;
}

.city-list .city-icon-container.at img {
  width: auto;
  height: 18px;
}

.city-box-search-icon {
  height: 20px;
  width: auto;
}

/* Service List Screen */

.service-list-container {
  flex: 1;
}

.service-list-header {
  /* position: fixed;  */
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}

.service-list-header + * {
  margin-top: 165px;
}

.travel-details-header-container {
  align-items: center;
  justify-content: center;
  padding: 8px;
  background-color: #fff;
  z-index: 99;
  padding-top: 0;
}

.travel-details-header-container .from-to {
  text-align: center;
}

.travel-details-header-container .date-details-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 6px;
  text-transform: capitalize;
}

.date-arrow {
  display: flex;
  align-items: center;
  justify-content: center;
}

.date-arrow img {
  height: 16px;
  width: auto;
  /* filter: invert(1); */
  /* box-shadow: inset 0px 0px 64px 64px cornflowerblue, 0px 0px 4px 4px cornflowerblue; */
}

.travel-date {
  margin: 0px 20px;
}

.sort-strip-container {
  text-align: center;
  padding: 5px;
  background-color: var(--secondary-color);
  border-radius: 20px;
  overflow-x: hidden;
  color: #fff !important;
  position: relative;
  margin: 0 5px;
}

.sort-strip-container.padding-right {
  padding-right: 20px;
  /* overflow-x: scroll; */
}

.sort-strip-container.padding-left {
  padding-left: 20px;
}

.sort-strip-container .scrollitem {
  min-width: 100%;
  transition: 0.2s all ease-in-out;
}

.sort-strip-container .sort-scroll-arrow {
  position: absolute;
  width: 25px;
  top: 10px;
  top: 0;
  z-index: 99;
  height: 100%;
  display: flex;
  padding: 0 3px;
  background-color: var(--secondary-color);
}

.sort-strip-container .sort-scroll-arrow.right {
  right: -0px;
}

.sort-strip-container .sort-scroll-arrow.left {
  left: -0px;
}

.sort-strip-container img.sort-strip-img {
  filter: inherit;
  height: 20px;
  width: auto;
  margin-left: 3px;
}

.sort-strip-container img.sort-strip-img.desc {
  transform: rotate(180deg);
}

.view-flex {
  width: 100%;
  align-items: center;
  z-index: 1;
}

.sort-item {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
  width: 33%;
  max-width: 33%;
}

.sort-item-last {
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  display: flex;
}

.sort-item-inner {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.sort-item-inner .sort-item-name {
  color: #fff !important;
}

.sort-item-inner img {
  width: 20px;
  max-width: initial;
}

.sort-item-inner > div {
  margin-right: 5px;
}

.sort-item-inner .whitebg {
  background-color: #fff;
  border-radius: 50%;
  padding: 4px;
  width: 28px;
  height: 28px;
}

.sort-strip-img {
  height: auto;
  width: 25px;
  filter: invert(1);
}

.sort-strip-filter-item {
  height: 100%;
  display: flex;
}

.list-item-container {
  margin-left: 15px;
  margin-right: 15px;
}

.list-item-container .offer-text {
  /* text-align: center; */
  padding: 3px;
  padding-left: 10px;

  /* background-color: var(--secondary-color); */
  background-color: #ededed;
  color: #464647;
  /* color: #fff; */

  padding-top: 14px;
  margin-top: -40px;
  position: relative;
  /* z-index: -1; */
  border-radius: 0 0 8px 8px;
}

.list-item-container .offer-text span.offer-percent {
  margin-right: 5px;
}

.item-container {
  width: 100%;
  /* display: grid;
  grid-gap: 5px;
  grid-template-columns: .85fr .05fr 1.5fr 1fr; */
}

.border-bottom-item-list {
  border-bottom: 1px solid #343434;
}

.item-container .v-line {
  height: 100%;
  border-right: 1px solid #ccc;
}

.item-container .rating {
  display: flex;
  align-items: center;
  width: 60%;
}

.service-details {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.service-details-row {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 0 12px;
}
.service-details-row-2 {
  border-top: 1px solid #ddd;
  padding: 0 20px;
  padding-top: 12px;
  display: flex;
  justify-content: space-between;
}
.service-details-row-2 .service-time {
  display: flex;
  align-items: center;
}
.service-details-row-2 .service-pet .pet-icon {
  margin-right: 5px;
  margin-top: -2px;
}
.service-details-row-2 .service-pet .span {
}

.service-details-row-2 .service-pet {
  display: flex;
  align-items: center;
}

.service-details-row-2 .service-time .duration-icon {
  width: 20px;
  margin-top: -2px;
  margin-right: 5px;
}

.amenities-date {
  width: 50%;
  /* margin-right: 17px; */
}

.amenities-date .ampm {
  margin-left: 2px;
}
.amenities-date .dot {
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #000;
  border-radius: 100%;
  margin-top: -4px;
  /* margin-right: 10px; */
}

.amenities-date-row-1,
.amenities-date-row-2 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 0;
  line-height: 1;
  text-transform: capitalize;
}
.amenities-date-row-1-time-box,
.amenities-date-row-2-time-box {
  position: relative;
  width: 70px;
}
.amenities-date-row-1-time-box .amenities-date-row-1-time,
.amenities-date-row-2-time-box .amenities-date-row-2-time {
  position: absolute;
  top: -6px;
  left: 15px;
}

.amenities-date .origin {
  width: 20px;
  height: 20px;
  margin-right: 5px;
  margin-top: -2px;
}
.amenities-date .neworigin {
  width: 15px;
  height: auto;
  margin-right: 5px;
  margin-top: -2px;
}
.amenities-date .destination {
  width: 20px;
  height: 18px;
  margin-right: 5px;
  margin-top: -2px;
}

.amenities-date .jeldestination {
	width: 15px;
	height:auto;
	margin-right: 5px;
	margin-top: -2px;
}
.time-row.flex-row {
  align-items: center;
}

.amenities-date .date-row {
  width: 100%;
  text-transform: capitalize;
  margin-top: 3px;
}

.time-seperator {
  margin-left: 5px;
  margin-right: 5px;
  flex: 1 1;
  text-align: center;
  position: relative;
  top: 3px;
}

.time-seperator img {
  position: relative;
  top: -6px;
}

.thirtypx-space {
  margin: 0px 5px;
}

.service-details .time-row {
  width: 100%;
  justify-content: flex-start;
  margin: 5px 0;
  margin-bottom: 2px;
}

.service-details .time-row span.ampm {
  margin-left: 2px;
}

.service-details .duration-hours {
  text-align: center;
  margin: 0 5px;
  position: relative;
  flex: 1;
}

.service-details .duration-hours span {
  display: block;
}

.service-details .duration-hours img {
  position: absolute;
  width: 100%;
  left: 0;
  /* top: 45%; */
}

.service-details .duration-hours span.hours {
  display: inline-block;
  margin-top: 3px;
}

/* .service-details{
  align-items:flex-start; 
  margin-top:321px;
} */

.amenities-container {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: flex-start;
  /* width:100%; */
  margin-top: 5px;
}

.sold-out-text {
  width: 100%;
  align-items: flex-start;
  text-align: right;
}

.sold-service {
  opacity: 0.4;
}

.arrow {
  padding: 0px 10px 0px 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* .op-name{
  font-size:12px;
  position:absolute;
  top:9px;
  left:20px;
} */
.op-name-row {
  display: flex;

  padding: 0 20px;
}

.operator-image {
  width: 40%;
  height: auto;
  margin-left: -9px;
  /* padding: 0px 10px; */
}

.on-name {
  /* width: 180px; */
  width: 120px;
  /* text-align: center; */
  margin-left: 10px;
}

.rating img {
  width: 15px;
  margin-top: -3px;
  margin-right: 3px;
}

.star-rating-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.rating-bg {
  position: absolute;
  background-color: #fa8039;
  height: 15px;
  /* width: 40px; */
  display: flex;
  margin-top: 3px;
  margin-left: 10px;
}

.rating-stars {
  position: relative;
  width: auto;
  height: 18px;
  padding: 0px 10px;
  z-index: 1;
}

.rating-badge-text-container {
  position: absolute;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
  /* width:22px */
}

.rating-badge-text {
  font-size: 8px;
  color: #fff;
  text-align: center;
}

.rating-badge {
  display: flex;
  flex-direction: row;
}

.seat-type-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 20px;
  margin-top: 2px;
  padding-left: 30px;
}

.seat-type-container.single-service {
  margin-top: 23px;
}

.seat-type-container .secondary-font {
  color: #f47373;
}
.seat-type-container .price-colour {
	color: #f47373 !important;
}

.seat-type-container:nth-child(2),
.seat-type-container:nth-child(3) {
  margin-top: 2px;
}

.seat-type-container-inner {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.seat-type-rows-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

/* .op-name{
  position:absolute;
  top:9;
  left:100
} */

.amenity {
  margin-right: 3px;
}

.three-row-grid {
  display: grid;
  grid-template-rows: repeat(3, 1fr);
}

/*Loader Modal*/
.loader-container {
  flex: 1;
}

.loader-from-to-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 80px;
  text-align: center;
}

.loader-travel-date {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.bus-icon {
  position: absolute;
  bottom: 0px;
}

.animation-container {
  margin-top: 10px;
}

.animation-container .loader-scene-img {
  width: 100%;
}

.loader-white-line {
  width: 100%;
}

/* .model-top-strip{
  width: 100%;
  position: fixed;
} */

.seat-layout-container {
  display: flex;
  width: 100%;
  padding-top: 13px;
  overflow: hidden;
  padding-bottom: 5px;
  margin-bottom: 10px;
  padding: 10px 20px;
  flex: 1;
}

.seat-layout-h-line {
  border-top: 1px solid #ccc;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1px 20px;
  margin: 5px 20px;
}

.legend-row-container {
  margin-bottom: 8px;
}

.floor-tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  position: absolute;
  width: 100%;
  top: 0px;
  z-index: 999;
}

.floor-tabs {
  display: flex;
  width: auto;
  background-color: #ccc;
  border-radius: 16px;
  margin-bottom: 5px;
}

.selected-floor,
.floor-tabs .active {
  background-color: var(--secondary-color);
  border-radius: 20px;
  padding: 5px;
}

.floor-tabs .tab-item-outer {
  border-radius: 20px;
  padding: 5px;
}

.deselected-floor {
  border-radius: 20px;
  padding: 5px;
}

.seat-details {
  height: 100%;
  padding: 0px 10px 0 0;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.seat-types {
  flex: 1;
  margin-bottom: 10px;
}

.legend-row {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  align-items: center;
}

.legend-circle {
  width: 15px;
  height: 15px;
  background-color: #dbdbdb;
  border-radius: 15px;
  border: 1px solid #969494;
  margin-right: 10px;
}

.seat-row {
  display: flex;
  justify-content: start;
  padding: 0px 15px;
}

.seat-column {
  position: relative;
  padding: 5px 5px;
  align-items: center;
}

.train-seat-layout-inner .seat-column {
  padding: 5px 0;
}

.seat-column.normal-dir {
  transform: rotate(180deg);
}

.seat-column img {
  transform: rotate(90deg);
  width: 34px;
  max-width: initial;
}

.train-seat-gangway {
  width: 34px;
}

.train-seat-gangway.not-gangway {
  width: 2px;
}

.seat-column .steering-img {
  width: 23px;
  margin: 0 2px;
}

.seat-number {
  position: absolute;
  top: -3%;
  left: 0;
  /* bottom: 10px; */
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 33px;
}

.seat-column.normal-dir .seat-number {
  transform: rotateY(180deg) rotate(180deg);
}

.seat-column.reverse-dir .seat-number {
  transform: rotateY(180deg);
}

.train-seat-layout-inner .seats-dir-gap {
  height: 25px;
}

.button-inner-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 6px 20px;
  align-items: center;
  /* border-bottom: 0.1px solid var(--primary-color); */
}

.drop-down-button-icon {
  margin-right: 7px;
  width: 15px;
}

.seat-type-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 10px;
}

.seat-layout-inner {
  /* border-left: 1px solid #ccc; */
  padding: 10px;
  flex: 1 1;
  height: 98%;
  overflow: scroll;
  margin: 5px 0px;
  /* margin-top: 17px; */
}

.main-seat-layout-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
}

.seats-section-container {
  height: calc(60vh - 17px);
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 17px;
  padding-top: 10px;
  flex: 1;
  max-height: calc(96% + 10px);
}

.seats-section-container-train {
  /* height: calc(60vh - 17px); */
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 17px;
  padding-top: 10px;
  flex: 1;
  max-height: calc(96% + 10px);
}

.seat-type-item .seat-type-show-image {
  width: 30px;
  height: 30px;
}

/* .driver-seat-icon{
  height: 30px;
  width: ;
} */

.selected-seats-container {
  height: 60px;
  padding: 0px 10px;
  background-color: #fff;
  margin-top: 5px;
  padding-bottom: 5px;
}

.selected-seats-container-inner {
  border: 1px solid var(--primary-color);
  border-radius: 8px;
  width: auto;
  padding: 0px;
  flex: 1;
  height: 100%;
  display: flex;
  margin: 0px 10px;
  justify-content: center;
}

.floor-and-seats-section {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 180px;
  top: 0px;
  padding-bottom: 15px;
}

.emptydiv {
  height: 65px;
  padding: 6px 10px;
  opacity: 0.5;
}

.seat-types-fare {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1 1;
  flex-direction: column;
}

.seat-type-numbers {
  display: flex;
  flex-direction: row;
}

.seat-type-number-item {
  border-left: 1px solid #ccc;
  padding: 0px 5px;
  margin: 0px 5px;
}

.seat-type-number-text {
  font-size: 13px;
  /* font-family: 'Carnas Regular'; */
}

.fare-details-text {
  /* font-size:14px; */
  /* font-family:'Carnas Regular'; */
}

.seat-layout-inner-container {
  height: 100%;
  /* overflow: scroll; */
}

.tab-item {
  padding: 5px 10px;
}

.seat-type-item {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.seat-type-item img {
  margin-right: 10px;
  transform: rotate(90deg);
}

.border-top {
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  padding: 5px 10px;
}

.border-bottom {
  border-bottom: 1px solid #ccc;
  padding: 5px 10px;
}

.seat-details-label-first {
  background-color: var(--secondary-color);
  padding: 3px 15px;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-container {
  margin-bottom: 10px;
}

.booking-detail-container {
  width: 100%;
}

.origin-dest-text-container {
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.cityname {
  display: flex;
  /* flex:1; */
  align-items: flex-end;
  padding-right: 3px;
  /* color: var(--primary-color); */
}

.origin-dest-text {
  font-size: 16px;
  text-align: center;
}

.origin-dest-card-container {
  height: 100vh;
  overflow: scroll;
  position: relative;
  border-radius: 10px;
}

.origin-dest-card-container hr {
  height: 0;
  border-top: 1px solid #ccc;
}

.origin-dest-card-container .kupos-card {
  margin-top: 5px;
}

.origin-dest-card-container .ion-dropdown {
  --padding-start: 0;
  --padding-end: 0;
}

.pb-select .ion-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  --padding-start: 10px;
  height: 11vw;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.ion-dropdown {
  display: flex;
  align-items: center;
  width: 100%;
  --padding-start: 0px;
  height: 11vw;
  margin-top: 5px;
  /* border: 1px solid #ccc; */
  border-radius: 8px;
}

.op-name {
  display: flex;
  width: 100%;
}

.op-label-text {
  font-size: 18px;
}

.op-name-text {
  font-size: 18px;
}

.service-bus-details {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.date-details {
  padding: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.date-text {
  font-size: 25px;
}

.booking-input-item {
  margin-top: 10px;
  padding-bottom: 1px;
}

.checkbox-passenger-return {
  display: flex;
  margin: 10px 0px;
  align-items: center;
}

.passenger-return-checkbox-new {
  --background-checked: var(--secondary-color);
  --border-color: var(--secondary-color);
  --border-color-checked: var(--secondary-color);
  margin-right: 10px;
}

.common-checkbox {
  --background-checked: var(--secondary-color);
  --border-color: var(--secondary-color);
  --border-color-checked: var(--secondary-color);
  margin-right: 10px;
}

.passenger-input-container-inner {
  margin: 12px 0px;
}

.passenger-details-button {
  width: 100%;
  /* height: 50px; */
  position: relative;
  top: 5px;
  margin: 10px 0;
}

.passenger-promo-code-container-parent {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 6px;
}

.touchable-opacity {
  margin-top: 10px;
}

.v-line {
  height: 30px;
  border-right: 1px solid #343434;
}

.service-detail-rows {
  flex: 1;
  justify-content: center;
}

.service-detail-row {
  display: flex;
  font-size: 11px;
  width: 100%;
  padding: 2px 10px;
}

.service-detail-row-label {
  width: 65px;
}

.service-detail-row-value {
  flex: 1;
}

.seat-details-label {
  display: flex;
  width: 100%;
  font-size: 14px;
  align-items: center;
}

.v-lign-seat-details {
  height: auto;
  border: 0.1px solid #ccc;
  width: 1px;
}

.seat-details-label-seat {
  padding: 5px;
  display: flex;
  margin-top: 4px;
  flex-direction: row;
}

.selected-item-text-container {
  flex: 1;
}

.drop-down-button-text {
  color: var(--primary-color);
  font-size: 14px;
  /* font-family:'Carnas Regular'; */
}

.icon-container {
  height: 100%;
  align-items: center;
  width: 50px;
}

.list-item {
  width: 84vw;
  display: flex;
  padding: 10px;
}

.list-item-text {
  margin-left: 10px;
  font-size: 14;
  /* font-family: 'Carnas Regular'; */
}

.mot-input-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 12px 0;
}

.arrow {
  margin-right: 10px;
}

.error-text-container {
  padding: 0px 2px;
}

.error-msg-padding {
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
}

.error-text {
  color: #f00;
  font-size: 11px;
  /* font-family:'Carnas Regular'; */
}

.label-text-container {
  padding: 0px 2px;
}

.label-text {
  color: var(--primary-color);
  font-size: 14px;
  /* font-family:'Carnas Regular'; */
}

.modal-dropdown-button {
  justify-content: center;
}

.modal-dropdown-button-text {
  font-size: 12;
  /* font-family: "Carnas Regular"; */
}

.modal-dropdown-modal {
  flex-grow: 1;
}

.modal-dropdown {
  position: absolute;
  height: 100;
  border: 100px solid lightgrey;
  border-radius: 2px;
  background-color: white;
  justify-content: center;
  /* elevation: 3,
  shadowColor: "#000",
  shadowOpacity: 0.88,
  shadowOffset: { width: 3, height: 3 },
  shadowRadius: 3 */
}

.row-text {
  padding: 10px 6px;
  font-size: 11px;
  color: gray;
  background-color: white;
  text-align: "center";
  /* font-family: "Carnas Regular"; */
}

.modal-seperator {
  height: 100;
  background-color: lightgray;
}

.PB-input-container {
  width: 100%;
  /* min-height: 60px; */
}

.input-wrapper {
  border: 1px solid #ccc;
  border-radius: 8px;
  display: flex;
  height: 100%;
  align-items: center;
  margin-top: 3px;
  width: inherit;
  padding: 5px 10px;
}

.input-wrapper ion-item {
  padding-left: 0px;
  border-bottom: none;
  height: 36px !important;
  width: 100%;
}

.PB-input-icon-ontainer {
  height: 100%;
  width: 40px;
  align-items: center;
  justify-content: center;
}

.icon-container-right {
  height: 100%;
  width: 40px;
  align-items: center;
  justify-content: center;
}

/* .text-input{
  height: 100%;
  padding:0px 10px;
  color:var(--primary-color);
  font-family:'Carnas Regular';
  font-size:14px;
  flex:1;
  margin:0px;
} */

.payment-card {
  padding: 10px;
  margin-bottom: 10px;
}

.show-password-container {
  height: 100%;
  width: 36px;
  align-items: center;
  justify-content: center;
}

.error-text-container {
  padding: 0px 2px;
}

.error-text {
  color: #f00;
  font-size: 11px;
  /* font-family: 'Carnas Regular'; */
}

.PB-text-input {
  height: 100%;
  flex: 1 1;
  margin: 0px;
  padding-right: 0px;
  padding-left: 20px !important;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  border-radius: 10px;
}

.PB-text-input::placeholder {
  /* padding-left: 10px; */
}

.seat-number-passenger-details {
  text-align: center;
  width: 100%;
  height: 100%;
  flex: 1 1;
  margin: 0px;
  padding-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.radio-group-container {
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

.radion-button {
  width: 45%;
  margin: 0px 10px;
}

.radio-button-input {
  display: flex;
  padding: 2dp;
}

.outer-border-radio-button {
  border: 1px solid var(--secondary-color);
  height: 15px;
  width: 15px;
  align-self: center;
  border-radius: 12px;
  margin-right: 5px;
}

.inner-radio-button {
  background-color: var(--secondary-color);
  width: 8px;
  height: 8px;
  border-radius: 4.5px;
  align-items: center;
  margin-top: 2.5px;
  justify-content: center;
  display: flex;
  margin-left: 2.4px;
}

.passenger-details-continue-button {
  display: flex;
  justify-content: center;
  margin: 14px auto;
  border-radius: 5px;
}

.passenger-label-input {
  display: flex;
  flex-direction: column;
}

.passenger-label-input .input-wrapper,
.login-input-container .input-wrapper {
  min-height: 40px;
  height: 11vw;
  max-height: 50px;
}

.passenger-details-booking-input-inner-top .input-wrapper {
  min-height: 40px;
  height: 11vw;
  max-height: 50px;
}

.no-label-input {
  display: flex;
  flex-direction: column;
  width: 40px;
}

.nombre-label-input {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-right: 2px;
}

.apellido-label-input {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-left: 5px;
}

.gender-label-input {
  display: flex;
  flex-direction: column;
  width: auto;
  margin-left: 5px;
}

.passenger-details-booking-input-inner-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 12px 0px;
}

.passenger-details-booking-inpute-inner {
  display: grid;
  grid-template-columns: 0.2fr 0.8fr 1fr;
  grid-gap: 5px;
  width: 100%;
  margin: 12px 0px;
}

.drop-down-down-arrow {
  position: absolute;
  right: 0px;
  top: 13px;
}

.block-container {
  margin: 12px 0px;
}

.title-text {
  font-size: 16px;
}

.mobile-row {
  display: flex;
  width: 100%;
}

.note-text-container {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* margin-top: -10px; */
}

.note-icon-text {
  font-size: 40px;
  margin-left: 20px;
  margin-right: 10px;
}

.payment {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  align-content: space-between;
  margin-top: 20px;
  margin-bottom: 20px;
}

.payment-item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-radion-button {
  /* border: 2px solid var(--secondary-color); */
  /* border: 2px solid var(--dark-font); */
  border: 2px solid #ec1c24;

  width: 16px;
  height: 16px;
  border-radius: 8px;
  align-self: center;
  justify-content: center;
  margin-right: 5px;
  align-items: center;
  display: flex;
}

.payment-button-inner {
  /* background-color: var(--light-color); */
  background-color: #ec1c24;

  width: 10px;
  height: 10px;
  border-radius: 5px;
  align-self: center;
}

span.radio-label {
  position: relative;
  top: 2px;
  margin-left: 5px;
  font-size: 13px;
}

.payment-details {
  padding: 5px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.payment-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 15px;
  margin: 2px 15px;
}

.promo-apply-btn span {
  margin-top: 2px;
}

.confirm-modal {
  margin-top: 10px;
}

.confirm-modal .line {
  margin: 10px 0px;
}

.confirm-row {
  display: flex;
}

.confirm-col {
  flex: 1;
  align-items: flex-start;
}

.confirm-row-column {
  display: flex;
  flex: 1;
  align-items: flex-start;
}

.confirm-text {
  color: var(--dark-font);
  font-size: 15px;
  /* font-family: 'Carnas Regular'; */
}

.hr {
  margin: 10px 0px;
}

.tabs-container {
  height: 30px;
  width: 100%;
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  display: block;
  align-items: center;
  justify-content: center;
}

.tabs-inner {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
  position: relative;
  z-index: 1;
}

.tabs-inner > .tab-item {
  flex: 1;
  height: 100%;
  padding: 4px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.tabs-animation-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #ccc;
}

.tab-indicator {
  height: 100%;
  position: absolute;
  background-color: var(--secondary-color);
  transition: all 0.2s ease-in-out 0s;
}

.promo-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 0px;
}

.promo-container-section {
  display: flex;
  flex-direction: row;
  padding-right: 10%;
  width: 100%;
  position: absolute;
  z-index: 9;
  height: 30px;
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 7fr 2fr;
}

.payment-circle-checkbox {
  border: 1px solid #fff;
  width: 16px;
  height: 16px;
  border-radius: 8px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 10px;
}

.payment-circle-checkbox img {
  max-width: inherit;
}

.applied-promo-container {
  border-radius: 8px;
  border: 1px solid #ccc;
  width: 100%;
  display: flex;
  padding: 7px;
  padding-left: 10px;
  align-items: center;
  margin-top: 5px;
}

.applied-promo-container .trash-image {
  height: 20px;
  width: auto;
}

.star-animation-image {
  margin: auto;
  display: flex;
  height: 40px;
  /* margin-top: 20px; */
}

.thank-you-card {
  margin-top: 65px;
}

.note-icon {
  margin: 0px 10px;
}

.check-box-row {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.select-box-title {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 0px;
  width: 100%;
}

.kupos-payment-card {
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0px 10px 10px 0px #ddd;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.09);
  z-index: 2;
  padding: 7px;
  margin: 15px;
  padding-bottom: 25px;
  padding-top: 10px;
}

.tnc-main-container {
  margin-top: 30px;
  margin-bottom: 10px;
  /* display: flex; */
  /* flex-direction: column; */
  text-align: center;
}

.payment-checkbox-new {
  --background-checked: var(--secondary-color);
  --border-color: var(--secondary-color);
  --border-color-checked: var(--secondary-color);
}

.promo-code-button {
  width: 100%;
  height: 11vw;
  border-radius: 8px !important;
  margin-top: 4px;
  font-size: 15px;
}

.end-payment-button {
  width: 185px;
  height: 40px;
  margin: 10px auto;
  display: flex;
  border-radius: 8px;
}

.end-payment-button span {
  margin-top: 1px;
}

.payment-screen-seat-top {
  display: grid;
  grid-template-columns: 1fr 9fr;
}

.promocode-checkbox-new {
  --background-checked: #fc763b;
  --border-color: #fc763b;
  --border-color-checked: #fc763b;
}

.static-page {
  overflow: hidden;
}

.content-slider {
  overflow: scroll;
  margin-top: 50px;
}

.ion-dropdown-parent {
  width: 100px;
  margin-right: 10px;
}

.ion-dropdown {
  height: 33px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  width: 100%;
}

.ion-dropdown .select-dropdown {
  display: flex;
  width: 100%;
  /* width: 90vw !important; */
  min-width: -webkit-fill-available;
  /* min-width: 90vw !important; */
  padding: 0px;
  align-items: center;
}

.popover-content.sc-ion-popover-md {
  width: 65vw !important;
  left: 41px !important;
}

.select-text {
  min-width: 30px;
  font-size: inherit;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1 1 0%;
  overflow: hidden;
}

.input-wrapper .item-native {
  padding-left: unset;
  padding-right: unset;
  padding-inline-start: calc(
    var(--padding-start) + var(--ion-safe-area-left, 0px)
  );
  padding-inline-end: var(--padding-end);
}

.input-outer-div {
  height: 40px;
}

.PB-input-container .input-wrapper {
  min-height: 40px;
  height: 11vw;
  max-height: 50px;
  overflow: hidden;
}

.input-wrapper .country-code-container {
  /* height: 40px; */
  margin-top: -5px;
  margin-bottom: -5px;
  width: 38px;
  border-right: 1px solid #ccc;
  margin-right: 10px;
}

.input-wrapper .country-code-container input {
  height: 100%;
  width: 100%;
}

.code-and-phone-style {
  margin: 5px 0px;
  height: 95%;
  padding-top: 3px;
}

.modal-buttons-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 25px;
}

.modal-button-container {
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 100%;
  margin-bottom: 8px;
  align-self: center;
  margin-top: 25px;
}

.modal-buttons-container2 .modal-button-container:nth-child(2) {
  margin-top: 15px;
}

.model-dark-text {
  color: var(--dark-font);
  font-size: 15px;
  /* font-family: 'Carnas Regular'; */
}

.thank-you-icon-container {
  display: flex;
  width: 100%;
  /* padding: 10px; */
  padding-bottom: 0px;
  padding-top: 0px;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
}

.failure-container {
  position: relative;
}

.thank-you-button-style {
  width: 100%;
  /* margin: 0px auto; */
  display: flex;
  justify-content: center;
  margin-top: 15px;
  border-radius: 8px;
}

/* Login Page styling */

.password-show {
  -webkit-text-security: disc;
  height: 24px;
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
}

.password-hide {
  -webkit-text-security: text;
  height: 24px;
  width: auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-left: 10px;
}

.show-password-container {
  height: 100%;
  width: 36px;
  align-items: center;
  justify-content: center;
}

.show-password-container .ion-icon {
  font: icon;
  --color: var(--primary-color);
}

.login-main-icon-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 20px;
}

.login-main-icon-container img {
  height: 100px;
  width: auto;
}

.login-title {
  display: flex;
  justify-content: center;
  text-align: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.login-left-icon-container {
  height: 100%;
  width: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
}

.login-left-icon-container img {
  margin-right: 6px;
}

.login-input-container {
  padding: 5px 0px;
}

.login-button-container {
  margin-bottom: 8px;
}

.bottom-links {
  display: grid;
  grid-template-columns: 1fr 0.1fr 1fr;
  align-items: center;
  margin-top: 15px;
}

.margin-hr-25 {
  margin: 0px 25px;
}

.margin-hr-20 {
  margin: 0px 20px;
}

.cancellation-body {
  padding: 35px 10px;
}

.desc-container {
  margin-top: 80px;
}

.cancel-ticket {
  margin-top: 15px;
}

.cancel-ticket .input-wrapper {
  height: 40px;
}

.cancel-inp-row {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 15px;
}

.cancellation-grid-items-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1.2fr;
  grid-gap: 3px;
}

.cancellation-grid-row-container {
  display: grid;
  grid-template-columns: 0.3fr 0.8fr 1.5fr;
  /* grid-template-columns: 0.1fr 0.4fr 1.5fr 0.5fr; */
  grid-gap: 5px;
  margin-top: 10px;
}

.cancellation-grid-row-container-bus {
  display: grid;
  grid-template-columns: 0.1fr 0.4fr 1.5fr 0.5fr;
  grid-gap: 5px;
  margin-top: 10px;
}

.cancellation-input-container {
  padding: 5px 15px;
}

.cancellation-button-container {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.cancellation-details-desc-container {
  padding: 0px 30px;
  width: 80%;
  align-self: center;
  margin: 30px auto;
  margin-top: 85px;
  margin-bottom: 10px;
}

.cancellation-detail-card {
  /* width:90%;
  margin:0px 5px; */
  /* padding:0px */
  margin-top: 5px;
}

.cancellation-detail-card .title-name {
  margin-bottom: 15px;
}

.cancellation-detail-card .city-names {
  margin-top: 5px;
}

.cancellation-detail-card .city-names > div {
  display: flex;
  align-items: center;
  padding: 3px 0;
}

.cancellation-detail-card .city-names img {
  height: 18px;
  margin-right: 6px;
}

.cancellation-detail-white-line {
  border-bottom: 1px solid #fff;
  width: 100%;
  margin: 6px 0px;
}

.cancellation-detail-op-info {
  margin: 10px 0px;
}

.cancellation-detail-touchable {
  padding-left: 10px;
  padding-right: 10px;
  z-index: 9;
  align-items: center;
  margin: 0;
  position: absolute;
  left: 0;
  padding: 2px;
  padding-left: 25px;
  top: 0;
  z-index: 9;
}

.cancellation-detail-button {
  display: flex;
  margin: auto;
  margin-top: 26px;
}

.cancellation-service-info-item {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.cancellation-service-info-label {
  /* color:#fff; */
  font-size: 10px;
}

.cancellation-service-info-value {
  /* color:#fff; */
  font-size: 10px;
}

.cancellation-seat-checkbox {
  /* width: 40px;
  max-width: 40px;
  padding: 4px; */
  text-align: left;
}

.cancellation-seat-row {
  display: flex;
  flex-direction: row;
  /* border-bottom: 1px solid #bbb; */
  /* margin: 0px 12px; */
  align-items: center;
}

.cancellation-header {
  border-radius: 8px 8px 0px 0px;
  /* padding: 10px; */
}

.cancellation-seat-name {
  flex: 1;
  align-items: flex-start;
  padding-right: 4px 0px;
}

.cancellation-seat-no {
  width: 70px;
  align-items: flex-start;
  padding: 4px 0px;
}

.cancellation-seat-fare {
  display: flex;
  justify-content: center;
  /* width: 60px;
  align-items: flex-start;
  padding: 4px 0px; */
}

.cancellation-item-flex-start {
  display: flex;
  justify-content: flex-start;
}

.cancellation-seats-container {
  padding-bottom: 12px;
  margin-top: 12px;
}

.cancellation-seats-container div:nth-child(1) {
  /* margin-top: 10px; */
}

.checkbox-icon {
  height: 90%;
  width: auto;
}

.cancellation-detail-sub-card {
  /* padding: 16px; */
  z-index: 10;
  position: absolute;
  width: 90vw;
}

.cancellation-detail-sub-card .refund-pg-types {
  margin-top: 16px;
  justify-content: space-between;
}

.cancellation-detail-sub-card .kupos-pay {
  width: 110px;
}

.cancellation-detail-sub-card .kupos-pay img {
  /* margin-top: 10px; */
}

.cancellation-footer-container {
  align-items: center;
  justify-content: center;
  padding: 20px 20px 0px;
  display: flex;
  flex-direction: column;
}

.cancellation-pg-row {
  display: flex;
  align-items: center;
  min-width: 110px;
  position: relative;
}

.cancellation-note {
  align-self: center;
  display: flex;
  margin: auto;
  margin: 35px 0 20px;
  text-align: center;
}

.refund-inp-item {
  width: 48%;
  min-width: 48%;
  margin: 2%;
}

.refund-inp-item:nth-child(2n + 1) {
  margin-left: 0;
}

.refund-inp-item:nth-child(2n + 2) {
  margin-right: 0;
}

.refund-inp-item .input-wrapper {
  height: 100%;
}

.refund-inp-item.email {
  width: 100%;
  margin-right: 0;
}

.refund-inp-item .ion-dropdown {
  height: 40px;
  --padding-top: 5px;
}

.refund-title {
  margin-bottom: 10px;
}

.credit-card-input-field {
  margin-bottom: 15px;
}

.refund-tnc {
  margin: 15px 0;
  text-align: center;
  margin-top: 22px;
}

.bank-form .button-container {
  width: 100%;
  margin: 15px 0;
}

.cancel-success-screen {
  padding: 50px 5vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-bottom: 0px;
  overflow: hidden;
}

.cancel-success-screen .wallet-success-container {
  /* flex:1 */
}

.cancel-success-screen .cancel-success-title {
  margin-top: 10px;
}

.cancel-success-screen .cancellation-button-container {
  position: absolute;
  display: flex;
  width: 80vw;
  bottom: 1.5vh;
  flex-direction: column;
  align-items: center;
  margin: auto;
  margin-left: 5vw;
}

.cancel-success-screen .cancellation-button-container .pb-button {
  margin-bottom: 15px;
}

.cannot-cancel-body {
  padding: 20px 0;
}

.ion-checkbox {
  --background: var(--primary-color);
  --background-checked: var(--primary-color);
  --border-color: var(--primary-color);
  --border-color-checked: var(--primary-color);
}

.cancellation-checkbox {
  --background: var(--primary-color);
  --background-checked: var(--primary-color);
  --border-color: var(--primary-color);
  --border-color-checked: var(--primary-color);
}

.transaction-list-main-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.transaction-list-header-container-parent {
  margin: 20px;
  margin-bottom: 0px;
  margin-top: 14px;
}

.transaction-list-details-container {
  flex: 1;
  padding: 8px 0px;
  align-items: center;
  display: grid;
  grid-gap: 6px;
  grid-template-columns: 1.2fr 0.1fr 6fr 0.1fr 3.6fr;
}

.transaction-list-date-container {
  display: flex;
  flex-direction: column;
  padding: 0px 6.5px;
  justify-content: center;
  align-items: center;
}

.transaction-list-v-line {
  width: 1px;
  border-right: 1px solid #ccc;
  height: 100%;
}

.transaction-list-type-container {
  flex: 1;
  padding: 0px;
}

.transaction-list-amount-container {
  margin-right: 5px;
}

.transaction-list-heading {
  /* width: 200px; */
  display: flex;
  /* flex-direction: row; */
  border-bottom-color: rgb(204, 204, 204);
  border-bottom-width: 1px;
  margin-top: 25px;
  margin: 10px 18px;
  align-items: center;
  justify-content: baseline;
  /* justify-content: space-between; */
  align-items: flex-end;
  justify-content: center;
  align-items: center;
}

.transaction-details-h-line {
  border-bottom: 1px solid #ccc;
  width: 100%;
  margin: 10px 0px;
  margin-bottom: 15px;
}

.transaction-details-time-hour-container {
  display: grid;
  grid-template-columns: 1.06fr 1fr;
  grid-gap: 5px;
}

.transaction-details-amount-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 10px 0px;
  margin-top: 20px;
}

.my-account-txn-head {
  display: flex;
  /* flex-direction: row; */
  border-bottom-color: rgb(204, 204, 204);
  border-bottom-width: 1px;
  margin-top: 25px;
  margin: 10px 18px;
  align-items: center;
  justify-content: baseline;
  /* justify-content: space-between; */
  align-items: flex-end;
  justify-content: center;
  align-items: center;
}

.my-account-arrow-container {
  width: 22px;
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: var(--primary-color);
  border-radius: 0px 8px 8px 0px;
}

.transaction-list-card {
  padding: 5px 15px;
  /* padding-right: 0px; */
  margin-top: 6px;
  margin-bottom: 8px;
}

.transaction-list-container-padding {
  margin: 0px 15px;
  margin-top: 5px;
  margin-bottom: 0px;
}

.my-accounts-settings-icon {
  position: absolute;
  right: 22px;
  top: 85px;
  z-index: 9;
  border-radius: 18px;
}

.my-account-bg-container {
  width: 100%;
  border-radius: 8px;
  padding: 20px 25px;
  align-items: center;
  z-index: 1;
}

.my-account-container {
  display: flex;
  width: 100%;
  margin-top: 15px;
}

.my-account-amount {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.my-account-flex-end {
  display: flex;
  justify-content: flex-end;
}

.edit-profile-person-image {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.edit-profile-account-info {
  display: flex;
  margin-top: 10px;
  flex-direction: column;
  align-items: center;
}

.edit-profile-input-container {
  padding-bottom: 10px;
}

.edit-profile-button-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

ion-datetime {
  width: calc(100% + 20px);
  margin: 0px -10px;
  padding-left: 10px;
  padding-top: 15px;
  z-index: inherit;
}

.screen-container {
  margin-top: 80px;
}

.cancel-success-main-image {
  display: flex;
  align-items: center;
  justify-content: center;
}

.wallet-success-container {
  width: 100%;
  margin-top: 15px;
}

.wallet-success-row {
  width: 100%;
  display: flex;
  border-bottom: 1px solid #ccc;
  padding: 15px 0px;
}

.cancel-success-text-container {
  margin: 10px 0px;
  align-items: center;
}

.txn-details-flex {
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 10px 0px;
}

.txn-details-h-line {
  border-bottom: 1px solid rgb(0, 0, 0);
  width: 100%;
  margin: 10px 0px;
  margin-bottom: 15px;
}

.transaction-history-txn-head {
  width: 92%;
  margin: 0px 4%;
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-top: 25px;
  justify-content: space-between;
  align-items: flex-end;
}

.center-items {
  align-items: center;
  justify-content: center;
  padding: 3px;
}

.transaction-history-date-header {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  margin-top: 10px;
  background-image: linear-gradient(to right, #fc763b, #febb3d);
}

.transaction-history-date-header-internal {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.transaction-history-date-header-internal div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.transaction-history-date-header-internal div img {
  margin-left: 5px;
}

.transaction-history-date-header-item {
  width: 50%;
  padding: 10px;
  display: flex;
  justify-content: center;
}

.transaction-history-h-line {
  border-bottom: 1px solid #ccc;
  margin: 10px 0px;
  width: 100%;
}

.transaction-history-txn-container {
  display: flex;
  width: 90%;
  margin-left: 4%;
}

.transaction-history-v-line {
  margin-right: 5px;
  border-right: 1px solid #ccc;
}

.txn-history-top-strip-margin {
  margin-top: 40px;
}

.transaction-history-date-header .txn-month-picker {
  position: absolute;
  z-index: 100;
  opacity: 0;
  width: auto;
}

.my-account-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 50px;
  align-items: center;
  /* position: relative; */
  /* background-color: #fff; */
  position: sticky;
  z-index: 1000;
  height: 65px;
  /*  */
  padding-top: 16px;
  width: 100%;
  padding: 0px 20px;
  margin-bottom: 6px;
  margin-top: 6px;
  top: 0px;
}
.successhome {
  justify-content: flex-end !important;
}

.header-back-button {
  position: relative;
  z-index: 999;
  /* padding: 0 4px;
  margin-left: -4px; */
}

.my-account-header img.kupos-pay-title-logo {
  height: 19px;
  width: auto;
}

.my-account-header-space {
  margin-top: 60px;
}

.my-account-header img {
  width: 23px;
  height: auto;
}

.my-account-header .qr-back-arrow {
  filter: invert(1);
}

.my-account-header-label {
  text-align: center;
  color: #fff !important;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: -15px;
  flex: 1;
}

.my-account-header-label-black {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  margin-left: -15px;
  flex: 1;
}

.my-account-header-label div {
  color: #fff;
}

.my-account-main-card {
  padding: 15px;
  position: relative;
  margin-top: 40px;
}

.new-home-header {
  display: grid;
  grid-template-columns: 1fr 8fr 1fr;
  height: 50px;
  align-items: center;
  position: relative;
  padding-top: 16px;
  padding: 0px 20px;
  margin-bottom: 40px;
  margin-top: 10px;
}

.new-home-header img {
  width: 22px;
  height: 22px;
}

.new-home-header .header_logo img {
  width: 100%;
  height: 50px;
}

img.qr-back-arrow {
  width: 22px;
  height: 22px;
}
img.lafit-icons {
	width: 6px;
	height: auto;
}
.kupos-com-main-image-container img {
  width: 144px;
  height: 22px;
}

.flex-end {
  display: flex;
  align-items: flex-end;
}

.my-account-wallet-strip {
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  display: flex;
}

.my-account-h-line {
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
  width: 100%;
}

.my-account-dp-container {
  align-items: center;
  margin-bottom: 10px;
}

.my-account-img-circle {
  width: 70px;
  height: 70px;
  border-radius: 35px;
  overflow: hidden;
  border: 2px solid #fff;
  position: absolute;
  top: -35px;
  left: 39.5%;
  background-color: #fff;
  background-size: cover;
  background-position: center;
}

.my-account-img-circle img {
  height: auto;
  height: 70px;
  width: 70px;
  margin: 0px;
  border-radius: 40px;
  border: 2px solid #fff;
}

.my-account-main-details-container {
  margin-top: 25px;
  align-items: center;
}

.my-account-dp-container-dp {
  width: 80px;
  border-radius: 80px;
  border-color: rgb(255, 255, 255);
  border-width: 2px;
  position: relative;
  top: -85px;
  left: 135px;
}

.my-account-menu-card-row {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-bottom: 20px;
  margin: 10px 30px;
}

.my-account-title-container {
  align-items: center;
  margin: 20px 0px;
}

.my-account-menu-card {
  border-radius: 20px;
  background-color: #fff;
  overflow: hidden;
  /* width: 29%; */
  width: 40%;
  height: 90px;
  position: relative;
  box-shadow: 0px 0px 6px 0px #ccc;
}

.my-account-menu-icon-container {
  align-items: flex-end;
  position: absolute;
  right: 10px;
  top: 10px;
}

.my-account-menu-icon-container img {
  height: 35px;
  width: auto;
}

.my-account-card-image-container {
  /* justify-content: flex-end; */
  /* flex: 1 1; */
  position: absolute;
  left: 5px;
  bottom: 10px;
  width: 93%;
}

.my-account-card-image-container-bottom {
  /* justify-content: flex-end; */
  /* flex: 1 1; */
  position: absolute;
  left: 8px;
  bottom: 10px;
  width: 93%;
}

.full-flex-end {
  justify-content: flex-end;
  flex: 1;
}

.my-trips-icons-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 5px 15px;
  justify-content: space-between;
}

.single-trip-item {
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 15px;
}

.my-trips-icon-container {
  width: 14vw;
  border-radius: 7vw;
  overflow: hidden;
  height: 14vw;
}

.my-trips-icon {
  width: 14vw;
  height: 14vw;
  resize: horizontal;
}

.my-trips-head-text {
  margin: 30px 0px;
}

.my-trips-inner-card {
  padding: 15px;
}

.my-trips-full-flex {
  display: flex;
  width: 100%;
  align-items: flex-start;
}

.my-trips-trip-card {
  padding: 0px;
  overflow: hidden;
  border-radius: 10px;
  background-color: #fff;
  box-shadow: 1px 2px 4px 2px rgba(0, 0, 0, 0.09);
  margin: 0 5px;
  position: relative;
  z-index: 9;
  margin-top: 10px;
}

.carpool-trips-card-cancel-btn {
  overflow: hidden;
  margin: -15px 5px 0;
  z-index: 1;
  border-radius: 10px;
  background-color: var(--secondary-color);
  position: relative;
  margin-bottom: 15px;
}

.carpool-trips-card-details {
  position: relative;
  z-index: 9;
}

.my-trips-ticket-item-container {
  padding: 10px;
  width: 100%;
  margin-top: 8px;
  border-radius: 5px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px #898989;
}

.my-trips-ticket-item-inner-row {
  display: flex;
  width: 100%;
}

.my-trips-row-value {
  width: 50%;
  display: flex;
}

.my-trips-main-container {
  height: 82vh;
  overflow: scroll;
  width: 100%;
  padding: 8px 0px;
  position: relative;
}

.my-trips-carpol-container {
  align-items: center;
  margin: 15px 0px;
  margin-top: 5px;
}

.ticket-item-conatiner {
  display: grid;
  grid-template-columns: 8fr 2.5fr;
  grid-gap: 10px;
  margin: 2px 5px 13px;
}

.my-trips-row-price-container {
  width: 100%;
  display: flex;
  background-color: #a4a4a6;
  color: white;
  justify-content: center;
  padding: 3px;
  border-radius: 15px;
}

.my-trips-detailed-body-bottom {
  padding: 10px 20px;
  margin: 0px 20.6px;
  margin-top: -35px;
  background: var(--primary-color);
  color: #fff;
  padding-top: 26px;
  border-radius: 0px 0px 8px 8px;
  position: relative;
}

.my-trips-root-container {
}

.my-trips-root-container .trip-body {
  margin: 10px 15px;
}

.qr-scan-container {
  position: absolute;
  z-index: 9;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.qr-recipient-icon {
  width: 100vw;
  flex: 1;
}

.qr-bg-transparent {
  background-color: #000;
  opacity: 0.4;
  flex: 1;
}

.qr-full-flex-center {
  flex: 1;
  align-items: center;
}

.qr-scan-frame {
  width: 50vw;
  height: 50vw;
}

.qr-scan-full-main {
  flex: 1;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
}

.qr-bottom-container {
  background-color: #fff;
  padding: 15px 30px;
  width: 100%;
  border-top-left-radius: 300px;
  border-top-right-radius: 30px;
  margin-top: -30px;
}

.qr-main-container {
  flex: 1;
  background-color: #fff;
}

.qr-last-element {
  z-index: 10;
  position: absolute;
  top: 0px;
  width: 100%;
}

.qr-white-BG {
  background-color: #fff;
}

.qr-modal-icon-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-top: 10px;
  justify-content: center;
}

.qr-info-modal-content {
  padding: 20px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}

.qr-qr-code-container-main {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 75px;
}

.qr-shot-container {
  width: 100vw;
  align-items: center;
  margin-top: 40px;
  background-color: #fff;
}

.qr-rut-container {
  display: flex;
  justify-content: space-between;
  width: 70vw;
  padding-top: 20px;
  margin: auto;
  margin-top: 25px;
  border-top: 1px solid #ddd;
}

.qr-buttons-container {
  width: 70vw;
  margin: 0px 15vw;
  position: absolute;
  bottom: 9vw;
}

.qr-button-container {
  margin-top: 10px;
}

.qr-image {
  width: 55vw;
  height: auto;
}

.qr-code-container {
  flex: 1;
  align-items: center;
}

.carpool-homescreen-full {
  height: 100%;
  overflow: hidden;
}

.new-home-page-date-box {
  margin-top: 5px;
}

.new-home-body-container {
  flex: 1 1;
  padding: 15px 20px;
  position: relative;
  bottom: -30px;
}

.new-home-body-container-card {
  margin: 0px 0;
  width: 100%;
  padding: 25px 0;
}

.new-home-body-container-card-kuposG0 {
  margin-bottom: 56px;
}

.new-home-location-container {
  margin-top: 10px;
}

.new-home-location-container .origin-dest-container {
  padding: 0px 10px;
  padding-left: 0px;
  padding-right: 5px;
}

.new-home-location-container .input-wrapper {
  padding: 5px 10px;
  padding-right: 0px;
}

.new-home-date-box-inner {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0px 10px;
  border-radius: 10px;
  height: 45px;
  margin-top: 5px;
  position: relative;
}

.new-home-date-box-inner img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.new-home-count-button {
  width: 25px;
  height: 25px;
  border-radius: 12px;
  border: 1px solid #888;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.new-home-count-button-inner {
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
}

.new-home-preference-icon-container {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.new-location-box-swap-inner {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.new-location-box-swap-inner img {
  width: 5vw;
  height: 5vw;
}

.new-home-location-button {
  width: 100%;
  display: flex;
  align-items: center;
  height: 10vw;
}

.new-home-location-button img {
  width: auto;
  height: 5vw;
  margin-left: 0px;
  margin-right: 15px;
  /* resize: horizontal; */
}

.new-home-display-center {
  align-items: center;
  justify-content: center;
}

.new-home-display-center img {
  width: 15vw;
  height: 15vw;
}

.new-home-footer-buttons {
  width: 100vw;
  height: 20vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0px;
  background-color: white;
  /* left: 0px; */
}
img.none {
  display: none !important;
}
.new-home-footer-buttons-new {
  width: 100vw;
  height: 20vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  bottom: 0px;
  background-color: white;
  /* left: 0px; */
}

.left-tabs {
  display: flex;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  width: 45vw;
  margin-bottom: 20px;
}

.new-left-tabs {
  display: flex;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  width: 100%;
}

.right-tabs {
  display: flex;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  width: 45vw;
  margin-bottom: 20px;
}

.new-right-tabs {
  display: flex;
  /* flex: 1; */
  justify-content: center;
  align-items: center;
  width: 100%;
}

.main-image-footer-button {
  flex: 1;
  justify-content: center;
  align-items: center;
}

.main-image-footer-button-new {
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-bottom: 25px;
}

.main-image-footer-button-new img {
  position: absolute;
  width: 25px;
  /* width: 35px; */
  height: auto;
}

.main-image-footer-button img {
  position: absolute;
  width: 25px;
  /* width: 35px; */
  height: auto;
}

.main-image-footer-button-container {
  flex: 1 1 0%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.main-home-footer-round-button {
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 42.5vw;
  bottom: 8.5vw;
  background-color: var(--primary-color);
  border-radius: 9vw;
  width: 15vw;
  height: 15vw;
  overflow: hidden;
  display: flex;
}
.button-pos .main-home-footer-round-button {
  bottom: 12.5vw;
}

.main-home-footer-round-button-inner {
  width: 10vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-home-location-box {
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.main-home-location-box-buttons {
  flex: 1;
  overflow: hidden;
}

.main-home-location-box-swap {
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-home-count-button-box {
  overflow: hidden;
  width: 100%;
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  padding: 0px 15px;
  border-radius: 10px;
  height: 12vw;
  margin-top: 4px;
}

.main-home-date-count-container {
  margin-top: 20px;
}

.main-home-counter-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-home-preference-icon-item-container {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8vw;
  width: 15vw;
  height: 15vw;
  overflow: hidden;
}

.full-height {
  height: 100vh;
}

.main-home-footer-button-inner {
  flex: 1 1;
  width: 20vw;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0px;
  position: relative;
}

.new-home-footer-bg {
  position: fixed;
  /* bottom: 0px; */
  display: flex;
  width: 100vw;
}

.main-image-footer-botton-text {
  position: absolute;
  top: 30px;
}
.highlights {
  color:#5bab06
}
.main-home-forward-img {
  width: 6.3vw;
  height: auto;
  resize: horizontal;
}

.train-cancel-button-container {
  margin-top: 15px;
}

.train-cancel-button-container .pb-button {
  margin-top: 10px;
}

.forget-page-main-image {
  margin: 20px 32vw;
  width: 36vw;
}

.forget-page-des-container {
  display: flex;
  align-items: center;
  padding: 5px 50px;
}

.forget-page-button {
  height: 40px;
  /* border-radius: 12px; */
}

.forget-password-card-container {
  margin-top: 25px;
  margin-bottom: 20px;
}

.reset-modal-inp .inp {
  margin: 20px 0;
}

.forgot-tabs-container {
  margin: 0 34px;
}

.forgot-flag-container {
  border-right: 1px solid #ccc;
  padding-right: 10px;
}

/* tnc page */

.tnc-body {
  font-size: 14px;
  padding-top: 0px;
  height: 100%;
  overflow: scroll;
  padding-bottom: 100px;
  text-align: justify;
  margin-top: -14px;
}

.tnc-body .privacy-sub-details {
  margin-left: 20px;
}

.tnc-body .primary-circle {
  border-radius: 50%;
  background: var(--primary-color);
  color: #fff;
  height: 29px;
  width: 29px;
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 10px;
  margin-right: 7px;
  font-size: 0.8rem;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pr-3 {
  padding-right: 3px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-20 {
  padding-top: 20px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-20 {
  padding-left: 20px;
}
.mt-y-3 {
  margin-bottom: 15px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-10 {
  margin-top: 10px;
}
.mt10 {
  margin-top: 10px;
}

.tnc-item {
  margin-bottom: 20px;
}

.secondary-circle {
  border-radius: 50%;
  /* background: var(--secondary-color); */
  /* color: #fff; */
  height: 8px;
  width: 10px;
  padding: 8px 0;
  margin-right: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  text-align: left;
}

.tnc-item > .privacy-sub-details .number-and-text {
  margin-left: 10px;
}

.primary-circle {
  margin-right: 10px;
  font-weight: bold;
  text-align: left;
}

.privacy-sub-details .privacy-sub-details .primary-circle {
  width: 50px;
}

.number-and-text.my-3 .secondary-circle,
.number-and-text.my-1 .secondary-circle {
  margin-left: 10px !important;
  margin-right: 15px;
}

.number-and-text {
  display: flex;
  margin-bottom: 10px !important;
}

span.image-inside-text {
  display: inline-block;
  width: 70px;
  position: relative;
  top: 3px;
}

/* Contact modal styling */
.contact-modal-padding {
  padding: 5px 20px;
}

.contact-modal-title {
  margin-top: 15px;
  text-align: center;
}

.contact-modal-subtitle {
  margin-top: 5px;
  margin-bottom: 28px;
  align-items: center;
}

.inline-text-center {
  text-align: center;
  display: inline;
}

/* .contact-modal-whatsapp img{
  margin-left: 6%;
} */

/* .contact-modal-email{
  display: flex;
  flex-direction: column;
  align-items: flex-end;
} */

/* .contact-modal-email img{
  margin-right: 6%;
} */

.recharge-flex {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.recharge-body {
  padding: 20px;
}

.recharge-input-container {
  margin-top: 32px;
}

.recharge-input-container .kupos-card {
  /* margin-top: 15px; */
}

.recharge-desc-container {
  padding: 0px 15px;
  margin-bottom: 0px;
}

.recharge-desc-text {
  text-align: center;
}

.rechrage-input-container {
  padding: 10px 0px;
  padding-top: 0px;
}

.recharge-tabs-container {
  width: 60vw;
  margin-left: 5vw;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2px;
  margin-bottom: 10px;
}

.recharge-link-container {
  margin: 10px 0px;
  align-items: center;
  justify-content: center;
}

.tansfer-container {
  /* margin-top: 25px; */
}

.tansfer-container .kupos-card {
  /* margin-top: 15px; */
}

.recharge-number-card {
  display: flex;
  width: 100%;
}

.recharge-number-card .step-icon {
  /* width: 50px; */
  height: 30px;
  display: flex;
  min-width: 30px;
  align-items: center;
  justify-content: center;
  /* background-color: '#008137'; */
  background-color: var(--secondary-color);
  border-radius: 50%;
  color: #fff;
  margin-right: 10px;
}

.recharge-number-card-image {
  margin-right: 10px;
}

.rechrage-number-card-text {
  color: var(--dark-font);
}

.recharge-bank-details-row {
  display: flex;
}

.bank-detail-rows-container {
  margin-left: 40px;
  margin-top: 15px;
  margin-bottom: 15px;
  line-height: 1.5;
}

.tansfer-container hr {
  height: 0;
  border-top: 1px solid #ccc;
  margin-bottom: 20px;
}

.recharge-bottom-container {
  width: 100%;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 15px;
}

.recharge-contact-numbers-container {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.recharge-contact-item {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.recharge-account-card {
  margin: 20px;
  position: relative;
  margin-top: 14px;
  padding-bottom: 14px;
}

.recharge-account-card .my-account-h-line {
  margin: 10px 0;
}

.recharge-submit-button {
  display: flex;
  justify-content: center;
  margin: 15px auto;
  margin-bottom: 0px;
}

.my-account-header.transparent-bg {
  background-color: rgba(255, 255, 255, 0.3);
  position: fixed;
  z-index: 99;
  top: 0;
  margin-top: 0;
  padding-top: 6px;
  padding-bottom: 6px;
}

.recharge-steps-container {
  margin-top: 20px;
}

.steps-button-container {
  z-index: 1;
  position: absolute;
  /* width: 100%; */
  bottom: 50px;
  left: 20px;
  right: 20px;
}

.recharge-steps-container .wallet-recharge-extra-items {
  margin-top: 7px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px;
  margin: 0 25px;
  line-height: 1.4;
  position: relative;
}

.recharge-steps-container .wallet-recharge-extra-items .kupos-card {
  height: 140px;
  margin: 0;
  padding: 12px;
  margin-bottom: 20px;
}

.recharge-steps-container .wallet-recharge-extra-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
  text-align: justify;
}

.recharge-steps-container .wallet-recharge-extra-item img {
  width: 45px;
  height: auto;
}

.reecharge-transfer-inp button {
  margin-top: 20px;
}

.my-favorities-list-container {
  margin: 25px 0 10px;
}

.my-favorities-title {
  padding: 5px 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 5px;
}

.my-favorities-row {
  padding: 5px 0;
}

.recipient-container {
  margin: 20px 0;
  line-height: 1.4;
}

.recipient-icon-container {
  position: absolute;
  top: 0;
  right: 0;
}

.recharge-transfer-modal-body-container {
  margin: 20px 0;
  line-height: 1.4;
}

.recharge-transfer-modal-body-container hr {
  height: 0;
  border-top: 1px solid #ccc;
}

.common-kupos-map .gmnoprint {
  display: none;
}

.common-kupos-map .gm-fullscreen-control {
  display: none;
}

.common-kupos-map .autocomplete-places.kupos-border {
  position: absolute;
  left: 15px;
  right: 15px;
  top: 50px;
  height: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0px 2px 4px 2px rgba(0, 0, 0, 0.09);
}

.common-kupos-map .autocomplete-places.kupos-border img {
  position: absolute;
  height: 45%;
  width: auto;
  filter: brightness(0.5);
  left: 15px;
}

.common-kupos-map .autocomplete-places.kupos-border input {
  border: 0;
  height: 100%;
  width: 100%;
  padding: 10px;
  padding-left: 50px;
  padding-top: 12px;
  font-family: "DM Sans", sans-serif !important;
}
.common-kupos-map .info-window {
  width: 220px;
  padding: 15px;
  font-family: "DM Sans", sans-serif !important;
}

.common-kupos-map .info-window .kupos-button {
  height: 35px;
  width: 100%;
  margin-top: 10px;
  background-color: var(--primary-color);
  color: #fff;
  text-transform: uppercase;
  border-radius: 8px;
}

.common-kupos-map .info-window .marker-icon-name {
  margin-bottom: 7px;
  display: flex;
  align-items: center;
}

.common-kupos-map .info-window .marker-icon-name span.marker-icon {
  height: 36px;
  width: 36px;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: 7px;
}

.common-kupos-map .info-window .marker-icon-name span.marker-name {
  margin-top: 14px;
}

.common-kupos-map .info-window .marker-icon-name span.marker-icon img {
  width: 75%;
  position: relative;
  top: 16%;
}

.common-kupos-map .info-window .marker-service {
  line-height: 1.5;
}

.common-kupos-map .info-window .marker-service span {
  display: inline;
}

.common-kupos-map .info-window hr {
  margin-top: 8px;
  margin-bottom: 8px;
  border: 1px solid #ccc;
  height: 0;
}

.common-kupos-map .gm-style .gm-style-iw-d {
  max-height: initial !important;
  overflow: hidden !important;
  max-width: 220px !important;
}

.common-kupos-map .gm-style-iw {
  padding: 0px;
  max-height: initial !important;
  margin-left: -55px;
}

.common-kupos-map .gm-style-iw > button {
  display: none !important;
}

.common-kupos-checkbox {
  display: inline;
}

.common-kupos-checkbox label {
  display: flex;
  align-items: center;
  margin-bottom: 0;
}

.common-kupos-checkbox .kupos-checkbox {
  display: inline-block;
  margin-right: 2px;
}

.common-kupos-checkbox .kupos-checkbox.circle {
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.common-kupos-checkbox input {
  display: none;
}

.common-kupos-checkbox img {
  display: none;
}

.common-kupos-checkbox input:checked + img {
  display: block;
}

.common-kupos-checkbox .checkbox-label {
  margin-top: 2px;
}

.icon-selection-circle-not-selected,
.icon-selection-circle-selected,
.icon-check-square-pink,
.icon-check-square-pink-line {
  display: inline-block;
  background: url("../assets/icons/check-sprite.png") no-repeat;
  overflow: hidden;
  text-indent: -9999px;
  text-align: left;
  background-size: 200% 200%;
  position: relative;
  top: 2px;
  width: 18px;
  height: 18px;
  /* z-index: 100; */
}

.icon-selection-circle-not-selected {
  background-position: -0 -0;
}

.icon-selection-circle-selected {
  background-position: 100% -0%;
}

.icon-check-square-pink {
  background-position: -0% 100%;
}

.icon-check-square-pink-line {
  background-position: 100% 100%;
}

.services-gif-loader-container {
  text-align: center;
}

.services-gif-loader-container img {
  width: 100px;
}

.flex-space-between {
  display: flex;
  justify-content: space-between;
}

.payment-details-service {
  padding: 25px 15px;
}

.payment-details-service .line {
  margin: 10px 0;
}
.new-icon-size .payment-details-service img {
  width: 15px;
}
.summary-date {
  text-transform: capitalize;
}

.total-payment-strip-passenger-details {
  background-color: var(--secondary-color);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  margin: 20px;
  padding-top: 20px;
  margin-top: -30px;
  /* margin: 15px; */
  z-index: 0;
  position: relative;
}

.payment-tnc {
  margin: 25px 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-options-card {
  padding: 10px 15px;
  padding-bottom: 0px;
}

.promo-apply-btn .pb-button {
  font-size: 15px;
}

.payment-history-h-line {
  border-bottom: 1px solid #ccc;
  margin: 14px 0px;
  width: 100%;
}

.payment-box-item {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.payment-box-item img {
  width: auto;
  height: 18px;
  margin-left: 0px;
  margin-right: 3px;
}

.position-relative {
  position: relative;
}

/* thank you page */

.thank-you-icon-container img {
  width: 100px;
  height: auto;
  margin: auto;
}

.thankyou-page-text-container {
  margin-top: 10px;
  padding: 0px 8vw;
}

.failure-card-icon-text-container {
  display: flex;
}

.failure-card-icon-text-container img {
  height: 25px;
  width: auto;
  margin-right: 10px;
}

.failure-card-header-label {
  text-align: center;
  display: flex;
  align-items: flex-end;
}

.failure-page-text-container {
  margin-top: 25px;
  padding: 0px 8vw;
}

.thankyou-buttons-container {
  width: 84vw;
  margin: 0px 8vw;
  margin-top: 30px;
  position: absolute;
  bottom: 8vh;
  z-index: 30;
}

.thankyou-buttons-container-failure {
  width: 84vw;
  margin: 0px 8vw;
  margin-top: 30px;
  position: absolute;
  z-index: 10;
}

.thankyou-buttons-container-terminal {
  width: 84vw;
  margin: 0px 8vw;
  margin-top: 50px;
}

.thankyou-buttons-container-failure {
  width: 84vw;
  margin: 0px 8vw;
  margin-top: 30px;
  position: absolute;
  z-index: 10;
}

.failure-screen-container {
  height: 100%;
  overflow: hidden;
  position: relative;
  z-index: 99;
}

.failure-screen-container-body {
  height: 92%;
  overflow: scroll;
  margin-bottom: 50px;
  padding-bottom: 60px;
}

/* Register screen */

.register-social-links-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
}

.fb-background {
  /* width: 40vw; */
  height: 6vw;
  background-color: #3b5998;
  display: flex;
  border-radius: 8px;
  align-items: center;
  position: relative;
}

.fb-background img {
  height: 5vw;
  width: auto;
  margin: 0.5vw 1vw;
  margin-right: 0px;
}

.fb-background .fb-login-btn {
  position: absolute;
  z-index: 99;
  opacity: 0;
}

.login-fb-login-btn {
  position: absolute;
  z-index: 99;
  opacity: 0;
}

.login-fb-login-btn .kep-login-facebook.metro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 90vw;
  justify-content: flex-start;
  margin-left: 20px;
  margin-right: 20px;
  font-size: 17px;
  font-family: inherit;
  font-weight: inherit;
}

.insta-background {
  /* width: 40vw; */
  height: 6vw;
  background-color: #464647;
  border-radius: 8px;
  display: flex;
  align-items: center;
  position: relative;
}

.insta-background img {
  height: 4vw;
  width: auto;
  margin: 0.5vw 2vw;
}

.register-screen-container {
  flex: 1 1 0%;
  height: 100%;
  overflow: hidden;
}

.register-screen-container .input-outer-div .input-wrapper {
  padding: 0 10px;
}

.register-input-body-container {
  /* height: 76vh; */
  overflow: scroll;
  margin-top: 2px;
}

.register-input-body-container .input-wrapper {
  height: 40px;
}

.register-input-body-container .ion-dropdown {
  --padding-start: 0;
  --padding-end: 0;
}

.list-md {
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  margin-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 8px;
  padding-bottom: 0px !important;
  background: var(--ion-item-background, var(--ion-background-color, #fff));
}

.register-input-container {
  /* height: 44px; */
  margin: 10px 0px;
  margin-top: 0px;
}

/* Transactions Screen */

.transactions-header-container {
  display: flex;
  margin: 0px 20px;
  align-items: center;
  background-color: var(--secondary-color);
  border-radius: 10px;
  padding: 0px;
  margin-bottom: 10px;
  margin-top: 10px;
}

.transactions-header-inner {
  display: flex;
  width: 100%;
  padding: 10px 20px;
  justify-content: space-between;
}

.transactions-header-inner-row {
  display: flex;
}

.my-transactions .movements-in-capsule-menu,
.my-account-main-tabs-body-my-promotions .movements-in-capsule-menu {
  margin-top: 30px;
}

.my-account-main-tabs-body-my-promotions .movements-in-capsule-menu {
  position: absolute;
  margin: 0;
  left: 0;
  bottom: 23px;
}

.transactions-deep_date-picker {
  position: absolute;
  width: 90%;
  opacity: 0;
}

/* promotions screen  */

.promotion-full-screen {
  height: 100%;
  overflow: hidden;
}

.promotion-txns {
  margin-top: 25px;
}

.promotion-transaction-container {
  background: #fff;
  border-radius: 15px 15px 0px 0px;
  box-shadow: 0px -2px 6px 0px #ccc;
  padding: 10px 20px;
  height: 76vh;
  overflow: scroll;
  padding-bottom: 60px;
}

.promotion-txn-item {
  display: grid;
  grid-template-columns: 2fr 4fr 2fr;
  grid-gap: 10px;
  padding: 18px 0px;
  border-bottom: 1px solid #ccc;
}

.promotion-amount {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.promotion-amount img {
  width: auto;
  height: 12px;
}

.promotion-info-modal-content {
  padding: 20px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
}

.promotion-modal-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 10px;
}

.promotion-modal-icon-container img {
  width: 60px;
  height: auto;
}

/* Modify Profile */
.modify-profile-image-container {
  width: 25vw;
  height: 25vw;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: center;
  margin: auto;
  position: relative;
  max-width: 140px;
  max-height: 140px;
}

.modify-profile-image-container-inner {
  border-radius: 20vw;
  overflow: hidden;
  width: 22vw;
  height: 22vw;
  align-self: center;
  justify-content: center;
  align-items: center;
  /* background-color: #eee; */
  border: 2px solid #ff5a5e;
  max-width: 140px;
  max-height: 140px;
  background-size: cover;
  background-position: center;
}

.modify-profile-pencil-container {
  position: absolute;
  bottom: 3px;
  right: 8px;
  z-index: 9;
  background-color: #fff;
  /* width: 8vw; */
  border-radius: 50%;
  overflow: hidden;
  width: 25px;
  height: 25px;
}

.modify-profile-pencil-container input[type="file"] {
  position: absolute;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100%;
  opacity: 0;
}

.modify-profile-content-container {
  padding: 30px 0px;
  margin: 20px 0px;
}

.modify-profile-body-container {
  height: 65vh;
  overflow: scroll;
  margin-top: 2px;
}

.modify-profile-body-container .input-wrapper {
  height: 40px;
}

/* airport transfer css */
.carpool-AirportTransferHomeScreen-full.airport-transfer-home {
  /* height: 100vh; */
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.carpool-AirportTransferHomeScreen-full.airport-transfer-home
  .new-home-footer-buttons {
  /* position: relative; */
}

.carpool-AirportTransferHomeScreen-full.airport-transfer-home
  .new-home-footer-bg {
  bottom: 0;
}

.airport-transfer-home .city-box-container {
  display: flex;
  height: auto;
  padding: 10px;
}

.airport-transfer-home .city-container {
  /* display: flex; */
  padding: initial;
}

.airport-transfer-home .city-box-item {
  align-items: center;
}

.airport-transfer-home .counter-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
}

.airport-transfer-home .counter-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
  margin-top: 15px;
}

.airport-transfer-home .service-tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: auto;
  margin-top: 2px;
  margin-bottom: 25px;
}

.airport-transfer-home .time-item ion-datetime {
  --padding-start: 0;
  flex: 1;
}

.airport-transfer-home .luggage-capsule {
  display: flex;
  width: 100%;
  border-radius: 20px;
  background-color: var(--secondary-color);
  padding: 5px 10px;
  color: #fff;
  align-items: center;
  justify-content: center;
  margin-top: 21px;
}

.airport-transfer-home .luggage-capsule img {
  height: 20px;
  margin-right: 5px;
}

.airport-transfer-service-item .kupos-card {
  position: relative;
  z-index: 2;
  padding: 10px;
  margin-bottom: 0;
  margin-top: 10px;
}

.airport-transfer-service-item .facilites-list {
  margin: 20px;
  margin-top: -15px;
  padding-top: 20px;
  background-color: #f4f4f4;
  border-radius: 0 0 15px 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

/* .carousel .control-next.control-arrow:before {
  border-left: 8px solid #000 !important;
}

.carousel .control-prev.control-arrow:before {
  border-left: 8px solid #000 !important;
} */
.carousel .control-dots {
  bottom: -12px !important;
}
.carousel .control-dots .dot {
  box-shadow: none !important;
}

.airport-transfer-facilities-item {
  display: grid;
  /* grid-auto-columns: 1fr 1fr; */
  grid-template-columns: 1fr 5fr;
  grid-gap: 7px;
  align-items: center;
  background-color: #f4f4f4;
  padding: 10px 0;
  margin: 0 10px;
  margin-top: -1px;
  border-radius: 0 0 15px 15px;
  position: relative;
  z-index: 0;
  line-height: 1.3;
  /* padding-top: 0; */
  border-radius: 0;
  border-top: 1px solid #ccc;
}

.airport-transfer-facilities-item:nth-child(1) {
  border-top: 0;
}

.airport-transfer-facilities-item .facility-icon img {
  width: 50px;
}

.airport-transfer-facilities-item .kupos-button {
  height: 40px;
  width: 100%;
  padding-top: 10px;
}

.airport-transfer-facilities-item-lower {
  display: flex;
  justify-content: space-between;
  padding: 5px 0;
}

.airport-transfer-service-grid {
  display: grid;
  grid-template-columns: 3.5fr 9fr 1.7fr;
  grid-column-gap: 7px;
}

.airport-transfer-service-grid .op-info {
  /* border-right: 1px solid #ccc; */
}

.airport-transfer-service-grid .service-details {
  padding: 0;
  /* white-space: nowrap; */
  overflow: hidden;
  text-overflow: ellipsis;
}

.airport-transfer-service-grid .rating img {
  height: 11px;
  width: auto;
  position: relative;
  top: -0.5px;
  margin-left: -0px;
}

.airport-transfer-service-grid .amenities img {
  height: 17px;
  width: auto;
}

.airport-transfer-service-grid .amenities svg {
  height: 15px;
  margin-right: 6px;
}

.airport-transfer-service-grid .op-logo img {
  /* height: 35px; */
  width: auto;
  height: 20px;
  margin-top: 5px;
}

.airport-transfer-service-grid .op-logo span {
  display: flex;
  align-items: center;
  margin-bottom: 6px;
  height: 23px;
}

.airport-transfer-service-grid .service-date {
  /* height: 35px; */
  margin-bottom: 6px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.airport-transfer-service-grid .rating {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  width: 100%;
}

.airport-transfer-service-grid .rating > * {
  margin-right: 5px;
}

.airport-transfer-service-grid .from-to {
  margin-top: 5px;
  display: flex;
}

.airport-transfer-service-grid .from-to .common-svg-home.svg-span,
.airport-transfer-service-grid .from-to img {
  width: 14px;
  position: relative;
  top: 0px;
  height: 14px;
  margin-left: -4px;
  right: -3px;
  margin-right: 5px;
}

.airport-transfer-service-grid
  .from-to.from
  .common-svg-home.svg-span
  #Destination {
  fill: var(--primary-color) !important;
}

.airport-transfer-service-grid .seats-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 60px;
}

.airport-transfer-service-grid .price span {
  display: block;
  text-align: center;
  line-height: 1.1;
}

.airport-transfer-service-grid .seats-details .price {
  margin-bottom: -1px;
}

.airport-transfer-service-item {
  margin-bottom: 10px;
}

.pb-payment-details-payment-right-side-bar .kupos-card {
  margin-bottom: 0;
  position: relative;
  z-index: 1;
}

.pb-payment-details-payment-right-side-bar .city-name img {
  width: 18px;
  position: relative;
  top: 2px;
}

.pb-payment-details-payment-right-side-bar .summary-title {
  margin-bottom: 15px;
}

.pb-payment-details-payment-right-side-bar .op-name {
  display: initial;
}

.pb-payment-details-payment-right-side-bar .location-details {
  margin-top: 10px;
}

.pb-payment-details-payment-right-side-bar .duration-col {
  text-align: right;
}

.pb-payment-details-payment-right-side-bar .date-time-row {
  padding: 15px 0;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
}

.pb-payment-details-payment-right-side-bar .payment-seat-row {
  margin: 3px 0;
}

.pb-payment-details-payment-right-side-bar .total.bold {
  margin: 0 20px;
  padding: 15px 15px 10px;
  background-color: var(--secondary-color);
  border-radius: 0 0 8px 8px;
  margin-top: -5px;
}

.pb-payment-details-payment-right-side-bar .total.bold .total-row div {
  color: #fff;
}

.carpool-AirportTransferPaymentScreen-full .payment-block-promo .pb-button {
  /* max-width: 84px; */
  margin-top: 5px;
  /* margin-left: 6px; */
}

.carpool-AirportTransferPaymentScreen-full .kupos-card.payment-inputs {
  margin-top: 10px;
}

.carpool-AirportTransferPaymentScreen-full .payment-methods {
  /* margin-top: 20px; */
  flex-wrap: wrap;
}

.carpool-AirportTransferPaymentScreen-full .webpay-payment-method,
.carpool-AirportTransferPaymentScreen-full .kupos-payment-method {
  width: 100%;
  display: flex;
  margin-bottom: 15px;
  align-items: center;
  padding-bottom: 8px;
  /* border-bottom: 1px solid #ccc; */
}

.carpool-AirportTransferPaymentScreen-full .kupos-payment-method {
  display: block;
  text-align: right;
  margin-bottom: 0;
}

.carpool-AirportTransferPaymentScreen-full
  .kupos-payment-method
  span.kupos-amount {
  /* position: relative;
  right: 25%; */
}

.carpool-AirportTransferPaymentScreen-full .webpay-payment-method img,
.kupos-payment-method img {
  width: 80px;
  height: auto;
  margin-left: 5px;
  /* margin-bottom: 10px; */
}

.carpool-AirportTransferPaymentScreen-full .tnc.flex-row {
  align-items: center;
  justify-content: center;
  margin-bottom: 25px;
}

.carpool-AirportTransferPaymentScreen-full .pay-button {
  margin: 20px;
}

.carpool-AirportTransferPaymentScreen-full .pay-button .pb-button {
  /* height: 50px; */
}

.carpool-AirportTransferPaymentScreen-full
  .payment-inputs
  .card-title.black-text {
  margin-bottom: 15px;
}

.carpool-AirportTransferPaymentScreen-full .payment-inputs .inp-row {
  margin-top: 15px;
}

.carpool-AirportTransferPaymentScreen-full
  .payment-inputs
  .inp-row
  .PB-input-container:nth-child(2) {
  margin-left: 8px;
}

/* kupos go css */
.kupos-go-inputs .input-wrapper {
  height: 40px;
}

.kupos-go-inputs .add-stop-icon {
  width: 30px;
  padding-left: 8px;
  margin-top: 31px;
}

.kupos-page-scroll {
  overflow: auto;
  height: calc(100vh - 55px);
  padding: 10px 0;
  padding-bottom: 30px;
}

.page-with-footer {
  height: calc(100vh - 55px - 20vw);
}

.kupos-go-return {
  border-top: 1px solid #ccc;
  margin-top: 20px;
  padding-top: 5px;
}

.otp-inps {
  justify-content: space-around;
  padding: 0 15px;
}

.otp-inps input {
  flex: 1;
  width: 40px;
  max-width: 40px;
  padding: 5px 0;
  text-align: center;
  border-bottom: 1px solid #ccc;
  margin-bottom: 10px;
}

.phone-inp-with-flag {
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 10px;
  padding: 0 10px;
  height: 11vw;
}

.phone-inp-with-flag .input-flag {
  display: flex;
  padding-right: 10px;
  align-items: center;
  margin-bottom: 4px;
  border-right: 1px solid #ccc;
}

.phone-inp-with-flag img {
  height: 20px;
  margin-top: 5px;
  border-radius: 4px;
}

.phone-inp-with-flag .input-wrapper {
  border: 0;
  margin-top: 0;
  min-height: inherit;
  height: inherit;
}

.preference-scren {
  padding: 15px;
}



@media screen and (max-width: 390px) {
.santafooterform .no-flex
{
	display: block !important;
	width: 100% !important;
}

.santafooterform .contact-input-item, .santafooterform .contact-button
{
	margin-left: 0px !important;
	margin-right: 0px !important;
}

.santafooterform .contact-input-label
{
	margin-bottom: 2px !important;
	font-size: 14px !important;
}
	.santarosa-mob{
		bottom: 0px;
    padding-top: 0px;
	}
.no-margin-right
{
	margin-right: 0px !important;
}
	.santarosa-mob .new-home-location-container {
		margin-top: 2px;
}
.santa-form-button button{
	padding: 8px 3px !IMPORTANT;
}
 .santarosa-mob .date-box-container
{
	margin-top: 5px;
}
}
@media screen and (max-width: 360px) {

	.new-margin-small .kupos-card
	{
		padding: 12px;
		margin: 15px;
	}
  .seat-column img {
    width: 30px;
  }

  .train-seat-gangway {
    width: 30px;
  }

  .train-seat-gangway.not-gangway {
    width: 2px;
  }

  .seat-column .steering-img {
    width: 28px;
    margin: 0 1px;
  }

  .seat-row {
    padding: 0px 5px;
  }
}

@media screen and (max-width: 320px) {


/* .font14 {
	font-size: 12px !important;
} */
	.new-margin-small .kupos-card {
		padding: 7px;
		margin: 10px;
}

  .seat-details {
    padding: 0px 5px 0 0;
  }

  .seat-column img {
    width: 27px;
  }

  .train-seat-gangway {
    width: 27px;
  }

  .train-seat-gangway.not-gangway {
    width: 2px;
  }

  .seat-column .steering-img {
    width: 25px;
    margin: 0 1px;
  }

  .seat-layout-inner {
    height: 90%;
  }
}

@media screen and (max-width: 415px) {
  .passenger-details-booking-inpute-inner {
    display: grid;
    grid-template-columns: 0.2fr 0.7fr 1fr;
    grid-gap: 5px;
    width: 100%;
    margin: 12px 0px;
  }
}

/* Train seat layout */
.rotate-180-deg {
  transform: rotate(180deg);
}

.coach-layout-front-text {
  display: flex;
  align-items: center;
  margin-top: 20px;
  justify-content: space-evenly;
}

.front-text-image {
  height: 14px;
}

.seats-in-train img {
  transform: rotate(90deg);
  width: 24px;
  max-width: initial;
}

.train-tab-item {
  padding: 5px;
}

.train-seat-layout-inner {
  padding: 10px;
  flex: 1 1;
  height: 86%;
  overflow: scroll;
  margin: 5px 0px;
  padding-top: 0px;
  margin-top: 0px;
}

.seat-type-item .train-layout-showcase-img {
  width: auto;
  height: 24px;
}

.train-seat-number {
  height: 30px;
}

.train-floor-and-seats-section {
  display: flex;
  flex-direction: column;
  position: relative;
  min-width: 190px;
  top: -4px;
  padding-bottom: 15px;
}

.train-seat-layout-inner {
  padding: 10px 5px;
  flex: 1 1;
  height: 86%;
  overflow: scroll;
  margin: 5px 0px;
  transform: rotateY(180deg);
  padding-top: 0px;
}

.train-floor-tabs-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
  position: absolute;
  width: 100%;
  /* top: -17px; */
}

/* ---------------- bip start ------------------ */

.find-bip-number {
  display: flex;
  margin-top: 30px;
  justify-content: center;
}

.bip-card-number-payment {
  margin: 14px 0px;
}

.bip-checkbox-tnc {
  margin: 20px 0px;
}

.add-bip-card-body {
  margin: 0px 20px;
}

.example-add-card {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  margin-top: 5px;
  margin-bottom: 25px;
}

.example-add-card img {
  height: auto;
  width: 54%;
}

.add-bip-card-container {
  margin: 12px 0px;
  margin-top: 30px;
}

.bip-add-input-container {
  margin: 10px 0px;
  margin-bottom: 20px;
}

.example-add-card .edit-card-icon-holder img {
  height: 30px;
  width: auto;
  position: absolute;
  right: 24%;
  bottom: 2%;
}

.my-bip-cards-container .edit-card-icon-holder img {
  height: 30px;
  width: auto;
  position: absolute;
  right: -4%;
  bottom: -4%;
}

.suggested-recharges {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-top: 20px;
}

.suggested-recharges .suggested-amount {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--secondary-color);
  border-radius: 20px;
  padding: 6px 1px;
}

.edit-bip-card-container {
  margin-top: 9%;
}

.delete-popup-content {
  padding: 10px 10%;
  margin-top: 20px;
}

.delete-popup-content .value-delete-item-popup {
  margin-left: 5px;
}

.body-amy-cards-my-account {
  height: 90%;
  overflow: scroll;
}

.overflow-scroll {
  overflow: scroll;
}

.new-bip-home-footer-buttons {
  width: 100vw;
  height: 12vh;
  position: absolute;
  /* margin-top: 31vh; */
  bottom: 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.new-bip-home-footer-bg {
  display: flex;
  width: 100vw;
  position: absolute;
}

.bip-container-footer-page {
  height: 50vh;
}

.new-bip-center-button {
  bottom: 5vh;
}

.counter-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-top: 12px;
}

.counter-row .luggage-col .kupos-go-luggage-capsule img {
  height: 15px;
  margin-right: 10px;
}

.kupos-go-luggage-capsule {
  display: flex;
  width: 100%;
  border-radius: 20px;
  background-color: var(--secondary-color);
  padding: 5px 10px;
  color: #fff;
  align-items: center;
  justify-content: center;
  margin-top: 23px;
}

.add-stop-section {
  display: flex;
  margin-top: 8px;
  align-items: center;
}

.add-stop-section img {
  height: 16px;
  margin-right: 5px;
}

.kupos-go-body-inner {
  height: 72vh;
  overflow: scroll;
}

.kupos-go-passenger-details-booking-input-inner-top {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  margin: 12px 0px;
  margin-bottom: 0px;
}

/* At details screen page styleing */
.at-details-item-container {
  padding-top: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
}

.at-details-text {
  font-size: 14px;
  margin-top: 2px;
  color: #464647;
}

.at-details-cancel-strip {
  background-color: var(--primary-color);
  margin-left: 20px;
  margin-right: 20px;
  padding: 14px;
  padding-top: 20px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-top: -26px;
  position: relative;
}

.carbon-emmission-row {
  display: flex;
  justify-content: space-between;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 10px;
  padding-right: 10px;
}

.clear-date {
  width: 100%;
  justify-content: center;
  padding: 2px;
}

.clear-date-inner {
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 3px;
}

.ver-button-my-trips {
  font-size: 11px;
  color: rgb(70, 70, 71);
  background-color: #eee;
  width: 14vw;
  height: 4vw;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.datetime-text {
  margin-top: 4px;
}

.my-cards-main-item {
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
}

.my-cards-main-item .card-item {
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #aaa;
}

/* react crop css start */
.ReactCrop {
  position: relative;
  display: inline-block;
  cursor: crosshair;
  overflow: hidden;
  max-width: 100%;
}

.ReactCrop:focus {
  outline: none;
}

.ReactCrop--disabled,
.ReactCrop--locked {
  cursor: inherit;
}

.ReactCrop__image {
  display: block;
  max-width: 100%;
  touch-action: manipulation;
}

.ReactCrop__crop-selection {
  position: absolute;
  top: 0;
  left: 0;
  transform: translate3d(0, 0, 0);
  box-sizing: border-box;
  cursor: move;
  box-shadow: 0 0 0 9999em rgba(0, 0, 0, 0.5);
  touch-action: manipulation;
  border: 1px solid;
  border-image-source: url("data:image/gif;base64,R0lGODlhCgAKAJECAAAAAP///////wAAACH/C05FVFNDQVBFMi4wAwEAAAAh/wtYTVAgRGF0YVhNUDw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMy1jMDExIDY2LjE0NTY2MSwgMjAxMi8wMi8wNi0xNDo1NjoyNyAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wTU09Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9tbS8iIHhtbG5zOnN0UmVmPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvc1R5cGUvUmVzb3VyY2VSZWYjIiB4bWxuczp4bXA9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC8iIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6OEI5RDc5MTFDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6OEI5RDc5MTBDNkE2MTFFM0JCMDZEODI2QTI4MzJBOTIiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNiAoTWFjaW50b3NoKSI+IDx4bXBNTTpEZXJpdmVkRnJvbSBzdFJlZjppbnN0YW5jZUlEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIiBzdFJlZjpkb2N1bWVudElEPSJ4bXAuZGlkOjAyODAxMTc0MDcyMDY4MTE4MDgzQzNDMjA5MzREQ0ZDIi8+IDwvcmRmOkRlc2NyaXB0aW9uPiA8L3JkZjpSREY+IDwveDp4bXBtZXRhPiA8P3hwYWNrZXQgZW5kPSJyIj8+Af/+/fz7+vn49/b19PPy8fDv7u3s6+rp6Ofm5eTj4uHg397d3Nva2djX1tXU09LR0M/OzczLysnIx8bFxMPCwcC/vr28u7q5uLe2tbSzsrGwr66trKuqqainpqWko6KhoJ+enZybmpmYl5aVlJOSkZCPjo2Mi4qJiIeGhYSDgoGAf359fHt6eXh3dnV0c3JxcG9ubWxramloZ2ZlZGNiYWBfXl1cW1pZWFdWVVRTUlFQT05NTEtKSUhHRkVEQ0JBQD8+PTw7Ojk4NzY1NDMyMTAvLi0sKyopKCcmJSQjIiEgHx4dHBsaGRgXFhUUExIREA8ODQwLCgkIBwYFBAMCAQAAIfkEBQoAAgAsAAAAAAoACgAAAhWEERkn7W3ei7KlagMWF/dKgYeyGAUAIfkEBQoAAgAsAAAAAAoACgAAAg+UYwLJ7RnQm7QmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYgLJHdiinNSAVfOEKoUCACH5BAUKAAIALAAAAAAKAAoAAAIRVISAdusPo3RAzYtjaMIaUQAAIfkEBQoAAgAsAAAAAAoACgAAAg+MDiem7Q8bSLFaG5il6xQAIfkEBQoAAgAsAAAAAAoACgAAAg+UYRLJ7QnQm7SmsCyVKhUAIfkEBQoAAgAsAAAAAAoACgAAAhCUYBLJDdiinNSEVfOEKoECACH5BAUKAAIALAAAAAAKAAoAAAIRFISBdusPo3RBzYsjaMIaUQAAOw==");
  border-image-slice: 1;
  border-image-repeat: repeat;
}

.ReactCrop--disabled .ReactCrop__crop-selection {
  cursor: inherit;
}

.ReactCrop--circular-crop .ReactCrop__crop-selection {
  border-radius: 50%;
  box-shadow: 0px 0px 1px 1px white, 0 0 0 9999em rgba(0, 0, 0, 0.5);
}

.ReactCrop--invisible-crop .ReactCrop__crop-selection {
  display: none;
}

.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after,
.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
  content: "";
  display: block;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.4);
}

.ReactCrop__rule-of-thirds-vt::before,
.ReactCrop__rule-of-thirds-vt::after {
  width: 1px;
  height: 100%;
}

.ReactCrop__rule-of-thirds-vt::before {
  left: 33.3333%;
  left: calc(100% / 3);
}

.ReactCrop__rule-of-thirds-vt::after {
  left: 66.6666%;
  left: calc(100% / 3 * 2);
}

.ReactCrop__rule-of-thirds-hz::before,
.ReactCrop__rule-of-thirds-hz::after {
  width: 100%;
  height: 1px;
}

.ReactCrop__rule-of-thirds-hz::before {
  top: 33.3333%;
  top: calc(100% / 3);
}

.ReactCrop__rule-of-thirds-hz::after {
  top: 66.6666%;
  top: calc(100% / 3 * 2);
}

.ReactCrop__drag-handle {
  position: absolute;
}

.ReactCrop__drag-handle::after {
  position: absolute;
  content: "";
  display: block;
  width: 10px;
  height: 10px;
  background-color: rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.7);
  box-sizing: border-box;
  outline: 1px solid transparent;
}

.ReactCrop .ord-nw {
  top: 0;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: nw-resize;
}

.ReactCrop .ord-nw::after {
  top: 0;
  left: 0;
}

.ReactCrop .ord-n {
  top: 0;
  left: 50%;
  margin-top: -5px;
  margin-left: -5px;
  cursor: n-resize;
}

.ReactCrop .ord-n::after {
  top: 0;
}

.ReactCrop .ord-ne {
  top: 0;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: ne-resize;
}

.ReactCrop .ord-ne::after {
  top: 0;
  right: 0;
}

.ReactCrop .ord-e {
  top: 50%;
  right: 0;
  margin-top: -5px;
  margin-right: -5px;
  cursor: e-resize;
}

.ReactCrop .ord-e::after {
  right: 0;
}

.ReactCrop .ord-se {
  bottom: 0;
  right: 0;
  margin-bottom: -5px;
  margin-right: -5px;
  cursor: se-resize;
}

.ReactCrop .ord-se::after {
  bottom: 0;
  right: 0;
}

.ReactCrop .ord-s {
  bottom: 0;
  left: 50%;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: s-resize;
}

.ReactCrop .ord-s::after {
  bottom: 0;
}

.ReactCrop .ord-sw {
  bottom: 0;
  left: 0;
  margin-bottom: -5px;
  margin-left: -5px;
  cursor: sw-resize;
}

.ReactCrop .ord-sw::after {
  bottom: 0;
  left: 0;
}

.ReactCrop .ord-w {
  top: 50%;
  left: 0;
  margin-top: -5px;
  margin-left: -5px;
  cursor: w-resize;
}

.ReactCrop .ord-w::after {
  left: 0;
}

.ReactCrop__disabled .ReactCrop__drag-handle {
  cursor: inherit;
}

.ReactCrop__drag-bar {
  position: absolute;
}

.ReactCrop__drag-bar.ord-n {
  top: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-top: -3px;
}

.ReactCrop__drag-bar.ord-e {
  right: 0;
  top: 0;
  width: 6px;
  height: 100%;
  margin-right: -3px;
}

.ReactCrop__drag-bar.ord-s {
  bottom: 0;
  left: 0;
  width: 100%;
  height: 6px;
  margin-bottom: -3px;
}

.ReactCrop__drag-bar.ord-w {
  top: 0;
  left: 0;
  width: 6px;
  height: 100%;
  margin-left: -3px;
}

.ReactCrop--new-crop .ReactCrop__drag-bar,
.ReactCrop--new-crop .ReactCrop__drag-handle,
.ReactCrop--fixed-aspect .ReactCrop__drag-bar {
  display: none;
}

.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-n,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-e,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-s,
.ReactCrop--fixed-aspect .ReactCrop__drag-handle.ord-w {
  display: none;
}

@media (pointer: coarse) {
  .ReactCrop .ord-n,
  .ReactCrop .ord-e,
  .ReactCrop .ord-s,
  .ReactCrop .ord-w {
    display: none;
  }

  .ReactCrop__drag-handle {
    width: 24px;
    height: 24px;
  }
}

/* react crop css end */

/* new recharge transfer screen */

.open-contacts-input {
  width: 100%;
  margin-top: 3px;
}

.open-contacts-inout-inner {
  flex: 1;
  height: 11vw;
  align-items: center;
  display: flex;
  border-radius: 10px;
  border: 1px solid #ccc;
  padding-left: 20px;
  padding-right: 20px;
}

.new-transfer-screen-h-line {
  border-bottom: 1px solid #ccc;
  margin-top: 10px;
  margin-bottom: 10px;
  width: 100%;
}

.new-transfer-screen-fav-item {
  width: 100%;
  display: flex;
  margin-top: 8px;
  justify-content: space-between;
}

.recipient-info-image {
  width: 14vw;
  height: 14vw;
  border-radius: 8vw;
}

.transfer-money-payment-radio-button {
  border-radius: 10px;
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
  border: 2px solid #ff5c60;
  margin-right: 10px;
}

.recipient-image-circle {
  width: 15vw;
  height: 15vw;
  border-radius: 7px;
  overflow: hidden;
  border: 2px solid #fff;
  align-items: center;
  justify-content: center;
}

/* Kupos pool Modal settings */

.modal-bottom-img-container {
  width: 100%;
  position: absolute;
  left: 0px;
  bottom: -5px;
}

.modal-item-conatiner {
  margin-top: 20px;
  /* margin-bottom: 120px; */
}

.modal-social-icon-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  position: absolute;
  bottom: 40px;
  left: 0px;
  width: 100%;
}

.modal-download-desc-container {
  margin-bottom: 120px;
  margin-top: 30px;
}

.modal-social-icon-container img {
  height: 30px;
  width: auto;
}

.modal-bottom-img {
  width: 100%;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.modal-top-img {
  width: 90%;
  height: auto;
  margin-left: 5%;
}

.modal-desc-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 10px;
}

.pager-controls .pager-control .control-icon {
  width: 30px;
}

/* .track div:nth-child(1) { */
/* width: 100% !important; */
/* display: inline-block; */
/* } */

.track-first {
  width: 500% !important;
}

/* AT COUNTER STYLING  */

.at-counter-row {
  display: grid;
  gap: 10px;
  grid-template-columns: 1.6fr 1fr;
  padding-top: 15px;
}

.at-count-user-type-icon {
  height: 30px !important;
  margin: 0px !important;
}

.at-icon-text {
  display: flex;
  align-items: center;
}

.at-count-age-text {
  font-size: 10px;
  color: #919191;
}

.at-horizonatal-line {
  border-top: 1px solid var(--border-color);
}

.kupos-card-title {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.kupos-card-at-container {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.6);
  height: 100%;
  width: 100%;
  z-index: 1000;
}

.kupos-card-at {
  /* position: absolute; */
  /* top: 120px; */
  width: 90%;
  margin-left: 15px;
}

/* kupos timepicker style  */

.date-time-picker {
  position: absolute !important;
  right: 1% !important;
  width: 200% !important;
  background-color: #fff;
  border-radius: 15px;
  overflow: hidden;
  padding: 10px 18px;
  box-shadow: 1px 1px 16px 0px rgb(0 0 0 / 8%);
}

.common-kupos-time-picker {
  margin-top: 3px;
}

.date-inner {
  height: 41px;
  /* margin-top: 7px; */
}

.up {
  display: flex;
  justify-content: center;
}

.down {
  display: flex;
  justify-content: center;
}

.up-down-icon {
  height: 30px;
}

.time-info-icon {
  position: absolute;
  right: 10px;
  left: 82% !important;
  top: 18px !important;
}

.time-picker-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
  /* padding: 25px; */
  background: #fff;
  /* margin-top: -20px; */
  border-radius: 0px 0px 15px 15px;
  display: flex;
  justify-content: center;
}

.value-ampm {
  /* padding: 6px; */
  max-width: 40px;
  min-width: 40px;
  border-radius: 7px;
  border: 1px solid #ccc;
  height: 36px;
  font-size: 13px;
  text-align: center;
  padding-top: 1px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.value input {
  /* padding: 9px; */
  max-width: 40px;
  min-width: 40px;
  border-radius: 7px;
  border: 1px solid #ccc;
  height: 36px;
  font-size: 13px;
  background-color: transparent;
  text-align: center;
  padding-top: 4px;
}

.calendar-clock-container {
  background-color: #fff;
  border-radius: 15px;
}

.timepicker-hr {
  border-bottom: 0.5px solid #ddd;
  width: 90%;
  margin: auto;
}

.time-title {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}

.calendar-clock-btn {
  width: 80%;
  margin: auto;
  margin-top: 5%;
  padding-bottom: 30px;
}

.optional-date-time-container {
  position: relative;
}

.calendar-clock-container .common-kupos-calendar {
  box-shadow: none !important;
}

.optional-field-mask {
  height: 43px;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-radius: 10px;
  border: 1px solid var(--border);
  background-color: #e5e5e5;
  display: flex;
  align-items: center;
}

.mask-icon {
  height: 20px;
  margin: 0px 10px;
}

/* new airport transfer changes */

.origin-dest-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 0px 10px;
  margin-top: 6px;
}

.masked {
  background-color: #dedcd8;
}

.origin-dest-container .origin-dest-inner {
  width: 100%;
}

.cross-icon-container {
  position: absolute;
  right: 10px;
}

.cross-icon {
  height: 15px !important;
  width: 15px !important;
}

/* amenities section styling  */

.read-more {
  background-color: var(--secondary-color);
  color: #fff !important;
  border-radius: 15px;
  padding: 2px 8px;
  display: flex;
  align-items: center;
  /* max-width: 120px; */
  margin-left: 10px;
  margin-bottom: 10px;
  display: inline-flex;
}

.read-more-icon {
  height: 10px;
  margin-right: 5px;
}

.main-amenities-container {
  margin: 20px 10px;
  background-color: #fff;
  padding: 15px;
  border-radius: 10px;
}

.main-amenities-container .inner-item .section-header {
  padding: 0px;
}

.section-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 0px;
}

.section-icon {
  margin-right: 5px;
  max-height: 25px;
}

.item-content {
  padding-bottom: 10px;
}

.horizontal-line-amenities {
  border-top: 1px solid #ccc;
  margin: 5px 0px;
}

.slider-image-height {
  height: 160px;
}

.slider-height {
  height: 170px;
}

.carousel .control-dots .selected {
  background-color: var(--primary-color) !important;
}

.carousel.carousel-slider .control-arrow:hover {
  background: transparent !important;
}

/* AT terminal suggestions styling starts  */

.at-termninal-suggestions {
  position: absolute;
}

.at-termninal-suggestions a img {
  height: 20px;
  margin-right: 5px;
}

.at-termninal-suggestions a {
  display: flex !important;
  align-items: flex-start;
}

.at-termninal-suggestions.auto-suggestions {
  max-height: 175px;
  overflow-y: auto;
  position: absolute;
  z-index: 990;
  background-color: #fff;
  border: 1px solid #ccc;
  min-width: 80%;
  border-radius: 0 0 10px 10px;
  padding-top: 10px;
  margin-top: -4px;
  left: 9.7%;
  width: 80.5%;
}

.at-termninal-suggestions.auto-suggestions a {
  padding: 10px 14px;
  border-bottom: 1px solid #ccc;
  display: block;
  cursor: pointer;
  color: #464647;
}

.at-termninal-suggestions.auto-suggestions a.active {
  color: var(--primary-color);
  background-color: var(--light-primary-color);
  border-color: var(--primary-color);
}

.at-termninal-suggestions.auto-suggestions a.active:last-child {
  border-bottom: 0;
}

.at-termninal-suggestions.auto-suggestions a.cursor {
  color: var(--primary-color);
  background-color: var(--light-primary-color);
  border-color: var(--primary-color);
}

.at-termninal-suggestions.auto-suggestions a:hover {
  color: #fff;
  background-color: var(--secondary-color);
}

.at-termninal-suggestions.auto-suggestions .top-at-terminals {
  padding: 2px 14px;
}

.services-image-container {
  display: flex;
  justify-content: center;
  margin: 10px;
}

.services-image-container img {
  width: 100px;
}

.at-search-city-input div {
  flex: 1;
}

/* Hoppa hour experience  */

.hour-selection-container {
  background-color: #fff;
  /* height: 25vh; */
  /* width: 100%; */
  /* padding: 20px; */
  border-radius: 6px;
}

.hour-selection-container .hour-selection-container-inner {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  justify-content: center;
}

.hour-selection-container .hour-selection-container-inner .hor-row {
  width: 100%;
  /* height: 7vh; */
  display: flex;
}

.hour-selection-container .hour-selection-container-inner .hour-row {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}

.hour-selection-container .hour-selection-container-inner .hour-row div {
  padding: 5px 20px;
}

.flash-message-container {
  background: #fff;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 1;
}

/* Styling For themes  */

/* Styling For themes components  */

/* HeroSection  */

.hero-section-container {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-position-x: left;
  height: 100%;
  position: relative;
}

.hero-image-container {
  height: 100%;
}

.hero-image-container img {
  height: 100%;
}

.hero-overlap {
  display: flex;
  height: 100%;
  width: 94%;
  background-color: rgba(0, 0, 0, 0);
  position: absolute;
  align-items: center;
  justify-content: center;
  /* padding-left: 5%; */
  top: 0;
}
.underline {
  text-align: center;
}
.punch-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* // max-width: 45%;
  // margin-left: 0%; */
}

.punch-line div {
  margin: 14px 0px;
}

.punch-line .baseline {
  margin-top: 10px;
}

/* Slider */

/* .multi-slider-container-outer {
  margin-top: 40px;
}

.multi-slider-container {
  padding: 0px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.react-multi-carousel-list {
  min-height: 300px;
}

.multi-slide-type-1 {
 
}

.multi-slide-type-1 .top-strip-1 {
  height: 10px;
}
.multi-slide-type-1 .top-strip-2 {
  height: 20px;
}



.multi-slide-type-1 .image-container  .image {
  height: 200px;
  width: 200px;
} */

.multi-slider-container-outer h2 {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  font-weight: bold;
}

.multi-slide-type-1 .image-container {
  /* height: 260px; */
  display: flex;
  justify-content: center;
}

/* .multi-slide-type-1 .image-container .image {
  height: auto;
  width: 85vw;
} */

/*  themes-header*/
.themes-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 10px 0;
  position: sticky;
  top: 0;
  z-index: 1000;
}

.themes-header .new-header_logo img {
  height: auto;
  width: 200px;
  margin: 0 auto !important;
  text-align: center !important;
}
.new-header_logo img {
  width: 235px;
  height: auto;
  margin: 0 auto !important;
  text-align: center !important;
}

.new-header_logo2 img {
  width: 160px;
  height: auto;
}

.recharge-tabs-container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.map-container-selector {
  border-radius: 75px;
  background: #ccc;
  margin: 0px auto;
  display: flex;
  margin-top: 30px;
}

.selector-item {
  /* width: 100px; */
  /* padding: 6px 10px 5px; */
  padding: 10px 20px;
  text-align: center;
  color: var(--white-color);
  /* text-transform: capitalize; */
}

.map-container-selector .active {
  border-radius: 75px;
  /* background: var(--secondary-color); */
  background: #6a86ce;
  text-align: center;
}
.nortestyles .map-container-selector {
  border-radius: 75px;
  /* background: var(--secondary-color); */
  background: #d9d629;
  text-align: center;
}

.nortestyles .map-container-selector .active {
  border-radius: 75px;

  background: #02602e;
  text-align: center;
}

.light-blue-background {
  height: 100px;
  /* background-color: #f3f7ff; */
}

.tabs {
  display: flex;
  height: 30px;
  justify-content: center;
  align-items: center;
  z-index: 10000;
  /* width: 80vw; */
  margin: auto;
}

.tab-1 {
  background: #c17223;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  /* box-shadow: 0px 3px 7px 3px rgb(0 0 0 / 8%); */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 50%;
  text-align: center;
  color: #fff;
}

.selected-tab {
  background-color: #db7838 !important;
  color: #fff !important;
  font-weight: 600;
}

.tab-2 {
  /* background: rgb(253, 144, 144); */
  background-color: #c17223;
  padding: 10px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  /* box-shadow: 0px 3px 7px 3px rgb(0 0 0 / 8%); */
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  width: 50%;
  text-align: center;
  color: #fff;
}

.andimar-search-box {
  padding: 20px;
  background: "#F3F7FF";
  width: "80vw";
  margin: "auto";
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
}

/* PULLMAN SAN ANDREAS ICONS */
.san-andreas-facebook {
  display: inline-block;
  width: 25px;
  height: 25px;
  background-size: cover;
  background-image: url("../assets/pullmanSanAndres/fb.svg");
}

.san-andreas-facebook:hover {
  background-image: url("../assets/pullmanSanAndres/fb1.svg");
}

.san-andreas-facebook1 {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-image: url("../assets/pullmanSanAndres/fb.svg");
}

.san-andreas-facebook1:hover {
  background-image: url("../assets/pullmanSanAndres/fb1.svg");
}

.san-andreas-insta {
  display: inline-block;

  width: 25px;
  height: 25px;
  background-size: cover;
  background-image: url("../assets/pullmanSanAndres/insta.svg");
}

.san-andreas-insta:hover {
  background-image: url("../assets/pullmanSanAndres/insta1.svg");
}

.san-andreas-insta1 {
  display: inline-block;
  width: 40px;
  height: 40px;
  background-size: cover;
  background-image: url("../assets/pullmanSanAndres/insta.svg");
}

.san-andreas-insta1:hover {
  background-image: url("../assets/pullmanSanAndres/insta1.svg");
}

.san-andreas-whatsapp {
  display: inline-block;

  width: 25px;
  height: 25px;
  background-size: cover;
  background-image: url("../assets/pullmanSanAndres/whatsapp.svg");
}

.san-andreas-whatsapp:hover {
  background-image: url("../assets/pullmanSanAndres/whatsapp1.svg");
}

.font-10px {
  font-size: 10px;
}

.font-12px {
  font-size: 12px !important;
}

.font-13px {
  font-size: 13px !important;
}
.font-14px {
  font-size: 14px !important;
}

.font-16px {
  font-size: 16px !important;
}

.font-17px {
  font-size: 17px !important;
}

.font-18px {
  font-size: 18px !important;
}

.font-20px {
  font-size: 20px !important;
}

.font-22px {
  font-size: 22px !important;
}

.font-24px {
  font-size: 24px !important;
}

.font-25px {
  font-size: 25px !important;
}

.font-26px {
  font-size: 26px !important;
}

.font-28px {
  font-size: 28px !important;
}

.font-30px {
  font-size: 30px !important;
}

.cursor-pointer {
  cursor: pointer;
}

.font-weight-semi-bold {
  font-weight: normal;
  font-family: "DMSans-Medium", sans-serif;
}

.text-transform-default {
  text-transform: initial;
}

.margin-top-20 {
  margin-top: 20px !important;
}

.margin-0 {
  margin: 0;
}

.padding-x-10px {
  padding: 0 10px;
}

.padding-x-25px {
  padding: 0 25px;
}

.padding-x-30px {
  padding: 0 30px;
}

.margin-b-10px {
  margin-bottom: 10px;
}

.margin-b-30px {
  margin-bottom: 30px;
}

/* pullmansanandres styling */
.sp_service {
  text-align: center;
  margin-top: 30px;
  width: 100%;
}

.sp_service .btn-success {
  background-color: #dba447;
  border-color: #dba447;
  color: #ffffff;
  font-size: 17px;
  font-weight: bold;
  padding: 6px 12px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}

.sp_service .btn-success:hover {
  color: #ffffff;
  background-color: #398439;
  border-color: #255625;
}

.sp_service p {
  font-weight: bold;
  text-align: center;
  margin-top: 10px;
}

.react-dropdown-container {
  width: 100%;
  margin-top: 5px;
}

.react-dropdown-container .Dropdown-root {
  height: 40px;
}

.react-dropdown-container .Dropdown-root .Dropdown-control {
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  padding: 0 25px;
}

.react-dropdown-container .border-less .Dropdown-control {
  border: none !important;
}

.react-dropdown-container
  .Dropdown-root
  .Dropdown-control
  .Dropdown-placeholder {
  font-size: 12px;
}

/* .Dropdown-arrow-wrapper{
	flex: 1;
} */

.react-dropdown-container .Dropdown-root .Dropdown-control .Dropdown-arrow {
  top: 17px !important;
}

.Dropdown-menu {
  border-radius: 8px;
}

.Dropdown-placeholder {
  color: #bbb;
}
.Dropdown-placeholder.is-selected {
  color: #464647;
}

/* COUPONERA STYLING MY ACCOUNT */

.my_account_coupons_container {
  margin: 0 3vw;
  height: 80vh;
  height: calc(100vh - 82px);
  height: 90vh;
}

.my_account_coupons_container .carousel-root {
  height: 100%;
}

.my_account_coupons_container .carousel.carousel-slider {
  /* height: 100%; */
}

.my_account_coupons_container .carousel .slider-wrapper {
  height: 100%;
}

.my_account_coupons_container .carousel .control-dots .dot {
  background: #000;
}
.cuponera_wrapper {
  padding: 3rem 0;
  height: 100%;
}

.cuponera_parent {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 80px;
  padding: 1rem 10rem;
  overflow-y: auto;
  height: 100%;
  row-gap: 46px;
}

.coupon_item_outer {
  position: relative;
  flex: 1;
  cursor: pointer;
}

.coupon_item_outer .info_icon {
  position: absolute;
  top: 12px;
  right: 12px;
}

.coupon_item_outer .info_icon img {
  width: 18px;
  height: auto;
}

.coupon_item_outer .cupon_item {
  position: relative;
  z-index: 10;
  max-height: 86vh;
  overflow-y: scroll;
  scrollbar-width: none;
}

.coupon_item_outer .cupon_item::-webkit-scrollbar {
  display: none;
}

.cupon_item .cupon_title {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.cupon_item .cupon_title span {
  margin-left: 4px;
}

.cupon_item .ticket_items_outer {
  display: flex;
  justify-content: center;
}

.cupon_item .ticket_items {
  display: flex;
  justify-content: center;
  background-color: var(--corporate-blue);
  /* width: 30%; */
  padding: 6px 12px;
  border-radius: 20px;
  /* color: #fff; */
  padding-top: 8px;
}

.cupon_item .qr_container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
}

.cupon_item .qr_container img {
  width: 40% !important;
}

.cupon_item .cupon_cod {
  margin: 1rem 0;
}

.cupon_item .operator_name {
  margin: 0.3rem 0;
  display: flex;
  justify-content: flex-start;
}

.cupon_item .location_item {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
}

.cupon_item .location_item img {
  width: 18px !important;
  height: auto;
  margin-bottom: 2px;
  margin-right: 6px;
}
.new-icon-size .location_item img {
  width: 15px !important;
  height: auto;
  margin-bottom: 2px;
  margin-right: 6px;
}

.cupon_item .view_more_action {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.cupon_item .view_more_action img {
  width: 22px !important;
  margin-left: 4px;
  margin-bottom: 2px;
}

.coupon_item_outer .download_strip {
  position: absolute;
  width: 90%;
  left: 5%;
  background-color: var(--primary-color);
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;
  margin-top: -32px;
  padding-top: 26px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  padding-right: 20px;
  padding-left: 20px;
  color: white;
}

.couponera_more_info_section {
  margin-top: 10px;
}

.couponera_more_info_section .seperator {
  height: 1px;
  background-color: #ccc;
  /* margin: 0 5%; */
}

.couponera_more_info_section .title {
  margin-top: 12px;
  text-align: left;
}

.couponera_more_info_section .more_desc {
  text-align: left;
  margin-top: 6px;
}

.couponera_more_info_section .locations {
  margin-top: 12px;
}

.couponera_more_info_section .locations .location {
  display: flex;
}

/* Dropdown */
.react-dropdown-container
  .Dropdown-root
  .Dropdown-control
  .Dropdown-placeholder {
  /* font-weight: bold; */
}

/****************Menu styles sheeba***************/
.menu-centro {
  position: relative;
}
.side-menu-item .menu-centro > div {
  padding-left: 20px;
  color: inherit;
  display: flex;
  align-items: center;
}

.side-menu-item .menu-centro span {
  padding-left: 10px;
}

.menu-item-list .side-menu-item img {
  height: auto;
  width: 20px;
  color: #464647 !important;
}
.side-menu-item a {
  color: #464647 !important;
}
.welcome-image-dropdown {
  height: auto !important;
  width: 15px !important;
  position: absolute;
  right: 10%;
}
.dropdown-content {
  /* display: none; */
  z-index: 1;
  line-height: 24px;
  margin-top: 10px;
}

.dropdown-content span {
  display: block;
  margin-bottom: 10px;
  padding: 5px 5px 5px 20px;
  color: inherit;
}
.drop-menu.active:hover {
  background: #ffc72c;
}

.dropdown-content span:hover,
.dropdown-content span:active {
  background-color: #ffc72c;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.inner-pay-method {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.special-service-dest {
  position: absolute;
  width: 12px;
  height: 15px;
  top: 39px;
  left: 5px;
}

.special-service-origin {
  position: absolute;
  width: 12px;
  height: auto;
  top: 40px;
  left: 5px;
}

.css-va7pk8 {
  padding-left: 16px !important;
  padding-top: 4px !important;
}

.css-rsyb7x {
  padding-left: 16px !important;
}

.border {
  border: 1px solid lightgray;
}

.pip_popup_main_container {
  position: fixed;
  z-index: 9999 !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  background: rgba(100, 100, 100, 0.9);
  align-items: center;
  justify-content: center;
  .pip_popup_content {
    width: 80%;
    height: 20%;
    background-color: #fff;
    padding: 25px;
    border-radius: 15px;
    border: 1px solid #ddd;
    box-shadow: 0px 3px 7px 3px rgba(0, 0, 0, 0.08);
    display: flex;
    align-items: center;
    justify-content: center;
    .pip_popup_input input {
      width: 100%;
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 5px;
      margin-top: 5px;
      margin-bottom: 10px;
    }
  }
}

.service-form{
  padding: 20px 10px;
}

.footer-links{
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  font-size: 12px;
  gap: 20px;
}
.footer-links-item{
  padding-top: 0%;
  padding-bottom: 5px;
}

.buseslafitspacing .left-tabs, .buseslafitspacing .new-left-tabs
{
width: 62vw !important;
}
.buseslafitspacing .right-tabs, .buseslafitspacing  .new-right-tabs
{ 
width: 38vw !important;
}

.routes-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.route-box {
  border: 1px solid #ddd;
  padding: 10px;
  padding-top: 5px;
  border-radius: 20px;
  background-color: white;
  text-align: center;
  max-width: 400px;
  margin: 10px auto;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}

.toggle-button {
  display: inline-block;
  margin-top: 10px;
  background-color: rgb(1, 82, 166);
  color: white;
  padding: 5px 35px;
  border-radius: 20px;
  cursor: pointer;
}

.route-image-container {
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding: 0px 20px;
}

.route-map {
  width: 100%;
  max-width: 350px;
  border-radius: 8px;
}

