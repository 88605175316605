.accord-block {
  margin-bottom: 20px;
  padding: 5px 23px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
}
.scroll-div {
  overflow-y: scroll;
  height: 85vh;
  margin-top: 22px;
}
span.accordion-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.accord-heading {
  display: flex;
  align-items: center;
  color: rgb(1, 82, 166);
  line-height: 1.3;
  width: 80%;
}

.accord-data {
  margin-top: 10px;
  width: 90%;

  line-height: 20px;
  color: rgb(104, 102, 102);
  padding-bottom: 25px;
  text-align: justify;
}

.accord-icon {
  width: 20%;
  height: 65px;
}
.accord-icon img {
  width: 100%;
}
.yellow-icon {
  display: block;
  height: 35px;
  width: 35px;
  line-height: 40px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  background-color: #ffc72c;
  color: #0152a6;
  text-align: center;
  font-size: 1em;
  font-weight: bold;
  box-shadow: -2px 2px 8px -2px #ccccccbf;
  margin-left: 15px;
  top: 8px;

  position: relative;
}
.yellow-icon img {
  -webkit-transform: rotate(90deg);
  -moz-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  -o-transform: rotate(90deg);
  transform: rotate(90deg);
  width: 20%;
  position: absolute;
  top: 34%;
  left: 43%;
  margin: 0 auto;
  right: 43%;
  bottom: 34%;
  text-align: center;
  line-height: 30px;
}
