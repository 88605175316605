.themes-theme-three-footer {
  margin-top: 40px;
}
.themes-theme-three-footer .social {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  padding: 16px;
}
.themes-theme-three-footer .contact-links {
  display: flex;
  margin-top: 13px;
}
.themes-theme-three-footer .email {
  margin-left: 8px;
  font-size: 15px;
  display: flex;
  align-items: center;
}

.themes-theme-three-footer .email_icon {
  width: 15px;
  height: 15px;
}

.themes-theme-three-footer .email .email_icon {
  margin-right: 8px;
}
.themes-theme-three-footer .footer-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
}

.themes-theme-three-footer .bottom-footer {
  padding: 15px 10px;
  text-align: center;
}
