.themes-theme-seven-about-us-screen {
  line-height: 1.42857143;
}
.themes-theme-seven-about-us-screen #about-us {
  background: url("../../../assets/fernandez/about-us/BusesFernandez-Website-AboutUs-Banner.jpg");
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  margin-top: -20px;
}
.themes-theme-seven-about-us-screen #about-us .heading {
  position: relative;
  top: 120px;
}
.themes-theme-seven-about-us-screen #about-us .heading h1 {
  text-align: center;
  color: #ffffff;
  font-family: "DMSans-Bold";
  font-size: 25px;
}
.themes-theme-seven-about-us-screen .about-us {
  margin-top: 30px;
}
.themes-theme-seven-about-us-screen .about-us .col6 {
  float: left;
  width: 50%;
}
.themes-theme-seven-about-us-screen .about-us .about {
  border: 1px solid #b2b6be;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: 12px 25px;
  position: relative;
  font-size: 14px;
}
.themes-theme-seven-about-us-screen .about-us .about h3 {
  font-size: 24px;
  margin-bottom: 25px;
}
.themes-theme-seven-about-us-screen .about-us .about h3 span {
  border: 1px solid #b2b6be;
  border-radius: 50px;
  padding: 6px 25px;
  position: absolute;
  top: -20px;
  left: 24px;
  /* transform: translate(145px); */
  background-color: #fff;
}
.themes-theme-seven-about-us-screen .about-us .about p {
  margin: 0 0 10px;
}
.themes-theme-seven-about-us-screen .about-us .vision {
  font-size: 14px;
  background: #203b68;
  color: #ffffff;
  padding: 17px 25px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: relative;
  margin-bottom: 30px;
}
.themes-theme-seven-about-us-screen .about-us .vision h4 {
  font-size: 18px;
}
.themes-theme-seven-about-us-screen .about-us .vision h4 span {
  position: absolute;
  top: -15px;
  background: #ffa100;
  border-radius: 50px;
  padding: 6px 15px;
}
.themes-theme-seven-about-us-screen .about-us .vision p {
  margin: 0 0 10px;
}
.themes-theme-seven-about-us-screen .about-us .mision {
  font-size: 14px;
  background: #3966b1;
  color: #ffffff;
  padding: 19px 25px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}
.themes-theme-seven-about-us-screen .about-us .mision h4 {
  font-size: 18px;
}
.themes-theme-seven-about-us-screen .about-us .mision h4 span {
  background-color: #ffa100;
  border-radius: 50px;
  padding: 6px 15px;
  position: relative;
  right: 35px;
}
.themes-theme-seven-about-us-screen .about-us .mision p {
  margin: 0 0 10px;
}
.themes-theme-seven-about-us-screen .about-us .history {
  font-size: 14px;
  padding: 10px 25px;
  background: #3966b1;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  color: #ffffff;
}
.themes-theme-seven-about-us-screen .about-us .history h4 {
  text-align: right;
}
.themes-theme-seven-about-us-screen .about-us .history h4 span {
  background-color: #ffa100;
  border-radius: 50px;
  padding: 6px 15px;
  left: 35px;
  position: relative;
  top: -15px;
}
.themes-theme-seven-about-us-screen .about-us .history p {
  margin: 0 0 10px;
}
.themes-theme-seven-about-us-screen .board h3 {
  text-align: center;
  margin: 40px auto;
  display: flex;
  width: 90%;
}
.themes-theme-seven-about-us-screen .board h3 span {
  color: #203b68;
  border: 1px solid #b2b6be;
  border-radius: 50px;
  padding: 6px 30px;
  font-family: "DMSans-Bold";
}
.themes-theme-seven-about-us-screen .board h3:before {
  border-left: 1px solid #b2b6be;
  border-radius: 10px 0 0 0;
  content: "";
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 25px;
  position: relative;
  top: 10px;
}
.themes-theme-seven-about-us-screen .board h3:after {
  border-right: 1px solid #b2b6be;
  border-radius: 0 10px 0 0;
  content: "";
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 25px;
  position: relative;
  top: 10px;
}
.themes-theme-seven-about-us-screen .board .jc {
  justify-content: center;
}
.themes-theme-seven-about-us-screen .board .flex-row {
  display: flex;
  align-items: center;
}
.themes-theme-seven-about-us-screen .board .game-board {
  /* display: grid;
  grid-template-rows: 200px 200px 200px;
  grid-template-columns: 400px 420px 400px; */
}
.themes-theme-seven-about-us-screen .board .game-board .box {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  margin-bottom: 20px;
}
.themes-theme-seven-about-us-screen .board .game-board .box .text-center {
  width: 250px;
}
.themes-theme-seven-about-us-screen .board .game-board .box .text-center img {
  width: 91px;
  height: 105px;
}
.themes-theme-seven-about-us-screen .board .game-board .box .text-center .name {
  font-family: "DMSans-Bold";
  margin-top: 10px;
}
/* .themes-theme-seven-about-us-screen
  .board
  .game-board
  .box
  .text-center::after {
  content: '';
  border-left: 1px solid #b2b6be;
  position: relative;
  top: 10px;
}
.themes-theme-seven-about-us-screen
  .board
  .game-board
  .box:nth-child(2)::before,
.themes-theme-seven-about-us-screen
  .board
  .game-board
  .box:nth-child(5)::before,
.themes-theme-seven-about-us-screen
  .board
  .game-board
  .box:nth-child(8)::before {
  right: 50px;
  content: '';
  border-top: 1px solid #b2b6be;
  width: 150px;
  height: 75px;
  position: relative;
}
.themes-theme-seven-about-us-screen .board .game-board .box:nth-child(2)::after,
.themes-theme-seven-about-us-screen .board .game-board .box:nth-child(5)::after,
.themes-theme-seven-about-us-screen
  .board
  .game-board
  .box:nth-child(8)::after {
  left: 50px;
  content: '';
  border-top: 1px solid #b2b6be;
  width: 150px;
  height: 75px;
  position: relative;
} */
