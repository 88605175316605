.pullman-san-andreas-about {
	/* transform: translateY(-32px); */
}

.about-us-heading {
	margin-top: 40px;
	/* margin-bottom: 20px; */
}

.about-us-heading-main {
	margin-top: 40px;
	/* margin-bottom: 20px; */
}
.about-us-text {
	font-style: italic;
	color: #333;
	font-size: 20px;
	margin-bottom: 50px;
}

.pullman-san-andreas-about p {
	/* font-size: 16px; */
	color: #333;
	font-weight: bold;
}

.pullman-san-andreas-about .bottom-para {
	font-size: 20px;
	color: #666666;
}
