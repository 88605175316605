.themes-contact-form-1 .contact {
  padding: 0 20px;
}

.themes-contact-form-1 .contact-header {
  /* background: green; */
  padding: 0.8rem;
  padding-left: 20px;
}

.themes-contact-form-1 .contact-header .contact-header-text {
  color: white;
  font-weight: bold;
  text-transform: uppercase;
  /* border-bottom: 2px solid rgb(255, 239, 0); */
  display: inline-block;
  padding-bottom: 4px;
}

/* //  .themes-contact-form-1  .contact-header .contact-header-text::after{
//     content: "";
//     width: 142px;
//     height: 3px;
//     background: white;
//     display: flex;
//     margin-top: 3px;
//   } */

.themes-contact-form-1 .contact .contact-bottom {
  background: #e4e5e9;
  padding: 20px;
  /* // height: 500px; */
}

span.error {
  color: "#f00";
  font-size: 11px;
}

.themes-contact-form-1 .contact .contact-bottom .align-contact-inputs {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-direction: column;
  /* // padding-top:15px; */
}
.themes-contact-form-1
  .contact
  .contact-bottom
  .align-contact-inputs
  .contact-input-item {
  width: 100%;
  margin-right: 20px;
  margin-bottom: 10px;
}

.themes-contact-form-1 .contact .contact-bottom .contact-input-label {
  margin-bottom: 10px;
  font-size: 16px;
  /* font-family: Poppins-Medium; */
}

.themes-contact-form-1 .contact .contact-bottom .text-area-input {
  margin-right: 20px;
  margin-top: 30px;
  margin-bottom: 30px;
}

.themes-contact-form-1 .contact .contact-bottom .text-area {
  border-radius: 10px;
  border: none;
  width: 100%;
  padding: 10px;
}

.py-60 {
  padding: 60px 0;
}

.px-20 {
  padding-left: 20px;
  padding-right: 20px;
}
