.themes-theme-seven-frequent-card .cards h3 {
  text-align: center;
  margin: 20px auto;
  /* display: flex; */
  /* width: 90%; */
}
.themes-theme-seven-frequent-card .cards h3 span {
  color: #203b68;
  /* border: 1px solid #b2b6be; */
  border-radius: 50px;
  /* padding: 6px 30px; */
  font-family: 'DMSans-Bold';
}
/* .themes-theme-seven-frequent-card .cards h3:before {
  border-left: 1px solid #b2b6be;
  border-radius: 10px 0 0 0;
}
.themes-theme-seven-frequent-card .cards h3:after {
  border-right: 1px solid #b2b6be;
  border-radius: 0 10px 0 0;
}
.themes-theme-seven-frequent-card .cards h3::before,
.themes-theme-seven-frequent-card .cards h3::after {
  content: '';
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 25px;
  position: relative;
  top: 10px;
} */
.themes-theme-seven-frequent-card .cards {
  margin-top: 20px;
}
.themes-theme-seven-frequent-card .cards .row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}
.themes-theme-seven-frequent-card .cards .wd-full {
  width: 100%;
  /* flex: 0.5; */
}
.themes-theme-seven-frequent-card .cards .tab {
  background-color: #203b68;
  font-size: 16px;
  padding: 20px 17px;
  color: #fff;
  margin-bottom: 1.3px;
  cursor: pointer;
}
.themes-theme-seven-frequent-card .cards .tab:first-child {
  border-top-left-radius: 10px;
}
.themes-theme-seven-frequent-card .cards .tab:last-child {
  border-bottom-left-radius: 10px;
}
.themes-theme-seven-frequent-card .cards .active {
  background-color: #ffa100;
  color: #ffffff;
  border-radius: 0;
  font-family: 'DMSans-Bold';
}
.themes-theme-seven-frequent-card .cards .tab-content {
  min-height: 343px;
  flex: 1;
  background-color: #ffffff;
  color: #464647;
  font-size: 15px;
  border-radius: 0 10px 10px 0;
  padding: 20px;
  border: 1px solid #b2b6be;
  line-height: 1.4;
}
.themes-theme-seven-frequent-card .cards .tab-content img {
  width: 200px;
  height: auto;
  margin-bottom: 20px;
}
.themes-theme-seven-frequent-card .cards .tab-content p {
  margin: 0 0 10px;
}
.themes-theme-seven-frequent-card .cards .tab-content p span {
  font-size: 15px;
  font-family: 'DMSans-Bold';
}
