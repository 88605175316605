.themes-theme-seven-footer {
  padding: 25px 0;
  background: #f4f4f4;
  color: #203b68;
  margin-top: 30px;
  font-size: 15px;
}
.themes-theme-seven-footer .footer-logo img {
  width: 200px;
  height: auto;
}
.themes-theme-seven-footer .left-text {
  margin: 3px auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
.themes-theme-seven-footer .left-text a {
  margin: auto 4px;
  margin-bottom: 18px;
}
.themes-theme-seven-footer .footer-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.themes-theme-seven-footer .footer-wrapper a {
  color: #203b68;
}
.themes-theme-seven-footer .footer-wrapper p {
  margin-bottom: -12px;
  text-align: center;
}
.themes-theme-seven-footer .footer-wrapper .fb,
.themes-theme-seven-footer .footer-wrapper .insta {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 25px;
  padding: 15px;
  background-position-y: 19px;
}
.themes-theme-seven-footer .footer-wrapper .fb {
  background-image: url("../../../assets/fernandez/general/fb.png");
}
.themes-theme-seven-footer .footer-wrapper .insta {
  background-image: url("../../../assets/fernandez/general/insta.png");
}
