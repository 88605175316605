/* .react-multi-carousel-item {
  width: 170px !important;
} */

/* .react-multi-carousel-item {
	padding: 0 128px;
} */

/* .themes-theme-five-slider .react-multi-carousel-list {
	min-height: 200px !important;
} */

.image-container {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image-container p {
	font-weight: 600;
	margin-top: 10px;
}

/* .themes-theme-five-slider .react-multi-carousel-list {
  min-height: 262px !important;
} */

/* .react-multi-carousel-item {
  width: 100px !important;
} */
