.theme-six-office-section {
  border: 1px solid #cc8229;
  border-radius: 25px;
  /* height: 300px; */
  padding: 20px;
  /* display: flex; */
  box-shadow: 5px 5px 5px 3px rgb(0 0 0 / 20%);
}

.theme-six-office-section li {
  list-style: none;
}
.theme-six-office-section-left {
  /* width: 40%; */
}

.theme-six-office-section-right {
  /* width: 60%; */
  padding-left: 10px;
}

.theme-six-office-section-left-item {
  width: 100%;
  border: 1px solid #cc8229;
  border-radius: 10px;
  text-align: center;
  padding: 10px 0;
  width: 95%;
}
.theme-six-office-section-right {
  font-size: 16px;
  display: flex;
  flex-direction: column;
}

.theme-six-office-section-right-top span,
.theme-six-office-section-right-bottom span {
  display: inline-block;
  /* font-weight: 900; */
  padding-bottom: 3px;
}
.theme-six-office-section-right-bottom {
  margin-top: 10px;
}

.theme-six-office-section-right-bottom-left {
  /* width: 30%; */
}

.theme-six-office-section-right-bottom-right {
  /* width: 70%; */
  height: 200px;
}

.theme-six-office-section-right-bottom {
  /* display: flex;
	flex: 1; */
}

.selected-office-mark {
  display: inline-block;
  width: 15px;
  height: 15px;
  background-color: #cc8229;
  clip-path: polygon(0 1%, 0% 100%, 100% 50%);
}

.theme-six-offices-list {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.theme-six-office-section .selected-address img {
  height: 18px;
  width: auto;
  margin-left: 10px;
}
