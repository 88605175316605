.themes-theme-three-contact-form {
  /* display: flex; */
  gap: 20px;
}
.themes-theme-three-contact-form .form-section {
  padding: 10px;
  border-radius: 15px;
  font-size: 14px;
  border: 1px solid #dddddd;
  flex: 1;
  margin-bottom: 40px;
}
.themes-theme-three-contact-form .form-section h2 {
  margin: 0px;
  padding: 15px 12px;
  font-size: 20px;
  text-align: left;
}
.border-block .PB-text-input {
  border: 1px solid #ccc;
  border-radius: 10px;
}

.PB-input-container .input-wrapper {
  overflow: visible;
}
.themes-theme-three-contact-form .form-section .contact-input-item {
  margin-bottom: 15px;
  padding: 0 12px;
}
.themes-theme-three-contact-form .form-section .contact-input-label {
  font-size: 14px;
}
.themes-theme-three-contact-form .form-section .send-btn {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.themes-theme-three-contact-form .submit-btn {
  border: 1px solid transparent;
  padding: 6px 30px;
  border-radius: 7px;
  width: 100%;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
}
.themes-theme-three-contact-form .home-image {
  flex: 1;
}
.themes-theme-three-contact-form .services {
  flex: 1;
}
.themes-theme-three-contact-form .services .item {
  display: flex;
  align-items: center;
}
.themes-theme-three-contact-form .services .icon {
  height: 60px;
  width: 68.21px;
}
.themes-theme-three-contact-form .services .caption {
  margin-left: 20px;
  font-size: 14px;
}
.themes-theme-three-contact-form .services hr {
  margin: 30px 0;
}
.themes-theme-three-contact-form .contactus {
  flex: 1;
}
.themes-theme-three-contact-form .contactus h5 {
  font-size: 14px;
  margin: 10px 0;
}
.themes-theme-three-contact-form .contactus .item {
  display: flex;
  align-items: center;
}
.themes-theme-three-contact-form .contactus .icon {
  height: 30px;
  width: auto;
}
.themes-theme-three-contact-form .contactus .caption {
  margin-left: 20px;
  font-size: 14px;
}
.themes-theme-three-contact-form .contactus hr {
  margin: 30px 0;
}
.themes-theme-three-contact-form .contactus .contact-links {
  margin-top: 20px;
}
.themes-theme-three-contact-form .contactus .social {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 30px;
  padding: 20px;
}
.my-3 
{
	margin-top: 10px;
}
.mx-50
{
	margin-left: 50px !important;
}
.mx-10
{
	margin-left: 20px !important;
}
.mx-0{
	margin-left: 0px !important;
}