.footer6 {
}

.footer6 ul {
  list-style: none;
  padding: 0;
}

.footer6 .footer-input {
  border: none;
  margin-bottom: 5px;
  padding: 5px 15px;
  border-radius: 0;
}

.footer6 textarea {
  border-radius: 0;
}

.footer6 .footer-button-container {
  display: flex;
  justify-content: flex-end;
  margin: 5px 0;
}

.footer6 .footer-button {
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
}

.footer6 .footer-sub-heading {
  /* font-size: 25px !important; */
  font-weight: 600;
  margin-top: 20px;
  margin-bottom: 5px;
}
.footer6 li {
  margin-bottom: 5px;
  /* font-size: 18px; */
}
.poweredBy {
  text-align: center;
  display: flex;
  justify-content: center;
  font-size: 13px;
  font-weight: 900;
  text-transform: uppercase;
  padding: 0 12px;
}

.main-footer-logo {
  width: 235px;
  height: auto;
}

.main-footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70px;
}
.footer-6-section2 {
  color: #fff;
  padding: 0 10px;
  padding-top: 30px;
  padding-bottom: 20px;
}

.footer-text {
  text-align: justify;
}

.color-white {
  color: #fff;
}
