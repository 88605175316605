.themes-theme-seven-slider {
  border: 1px solid #b2b6be;
  border-radius: 10px;
  background-color: #ffffff;
  position: relative;
  margin-top: 30px;
}
.themes-theme-seven-slider h2 {
  margin-bottom: 30px;
  margin-top: 30px;
  text-align: center;
  color: #203b68;
  font-size: 25px;
}
.themes-theme-seven-slider h2 span {
  border-radius: 50px;
  border: 1px solid #b2b6be;
  padding: 3px 40px;
  position: absolute;
  top: -19px;
  background: #fff;
  transform: translateX(-145px);
}
.themes-theme-seven-slider .react-multi-carousel-dot-list {
  bottom: 5px;
}
.themes-theme-seven-slider .slide-item .image-container .image {
  height: 100%;
  width: 100%;
  padding: 0 10px;
}
.themes-theme-seven-slider .react-multi-carousel-dot button {
  background: #b2b6be;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.themes-theme-seven-slider .react-multi-carousel-dot--active button {
  background: #ffa100;
  border: none;
  border-radius: 50%;
  width: 10px;
  height: 10px;
}
.themes-theme-seven-slider .react-multi-carousel-list {
  /* min-height: 550px; */
  padding: 30px 0;
  width: 100%;
}
