.themes-theme-four-about-icon-section {
  /* display: flex; */
  margin-bottom: 30px;
}
.themes-theme-four-about-icon-section .icons-item {
  text-align: center;
  padding: 25px;
  flex: 1;
}
.themes-theme-four-about-icon-section .icons-item .icon {
  width: 85px;
  height: 85px;
}
.themes-theme-four-about-icon-section .icons-item .title {
  font-size: 16px;
  margin: 15px 0;
}
.themes-theme-four-about-icon-section .icons-item .des {
  font-size: 14px;
  line-height: 1.5;
}
