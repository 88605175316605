.themes-theme-seven-cargo-screen #cargo {
  background: url("../../../assets/fernandez/services/BusesFernandez-Website-Services-FreightServices-Banner.jpg");
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  margin-bottom: 20px;
  margin-top: -20px;
}
.themes-theme-seven-cargo-screen #cargo .heading {
  position: relative;
  top: 90px;
}
.themes-theme-seven-cargo-screen #cargo .heading h1 {
  text-align: center;
  color: #ffffff;
  font-family: "DMSans-Bold";
}
.themes-theme-seven-cargo-screen #cargo .heading h1 span:first-child {
  color: #3966b1;
  font-family: "DM Sans";
  font-size: 25px;
}
.themes-theme-seven-cargo-screen #cargo .heading h1 span:last-child {
  color: #203b68;
  font-family: "DMSans-Bold";
  font-size: 25px;
}
.themes-theme-seven-cargo-screen .cargo h3 {
  text-align: center;
  margin: 20px 0;
}
.themes-theme-seven-cargo-screen .cargo h3 span {
  color: #203b68;
  /* border-radius: 50px; */
  /* padding: 6px 30px; */
  font-family: "DMSans-Bold";
  /* position: absolute; */
  /* top: -22px; */
  /* transform: translate(-145px); */
  background-color: #ffffff;
}
.themes-theme-seven-cargo-screen .cargo .cargo-details {
  border: 1px solid #b2b6be;
  padding: 6% 30px;
  padding-bottom: 0;
  border-radius: 10px;
}
.themes-theme-seven-cargo-screen .cargo .cargo-details h5 {
  font-size: 16px;
  text-align: center;
  color: #203b68;
  margin-bottom: 20px;
  line-height: 21px;
}
.themes-theme-seven-cargo-screen .cargo .cargo-details h5 span {
  font-family: "DMSans-Bold";
}
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe1 .support {
  border-top-right-radius: 10px;
}
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe2 .support {
  border-top-left-radius: 10px;
}
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe1,
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe2 {
  margin-bottom: 70px;
  /* display: flex; */
}
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe1 .col-md-8,
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe1 .col-md-4,
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe2 .col-md-8,
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe2 .col-md-4 {
  padding: 0;
}
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe1 img,
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe2 img {
  width: 100%;
  height: 350px;
}
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe1 .support,
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe2 .support {
  background-color: #3966b1;
  color: #ffffff;
  padding: 10px;
  font-size: 14px;
}
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe1 .support .item,
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe2 .support .item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}
.themes-theme-seven-cargo-screen
  .cargo
  .cargo-details
  .iframe1
  .support
  .item
  span,
.themes-theme-seven-cargo-screen
  .cargo
  .cargo-details
  .iframe2
  .support
  .item
  span {
  color: #ffffff;
  padding: 0 10px;
  /* display: flex; */
  /* align-items: center; */
}
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe1 .support img,
.themes-theme-seven-cargo-screen .cargo .cargo-details .iframe2 .support img {
  width: 30px;
  height: auto;
}
.themes-theme-seven-cargo-screen .cargo .more {
  margin: 30px 0;
  text-align: center;
  font-size: 17px;
  font-family: "DMSans-Bold";
  line-height: 1.3;
}
.themes-theme-seven-cargo-screen .cargo .more span:first-child {
  /* border: 1px solid #203b68; */
  /* background-color: #203b68; */
  /* border-radius: 50px 0 0 50px; */
  padding: 6px 15px;
  color: #464647;
  border-right: 0;
}
.themes-theme-seven-cargo-screen .cargo .more span:last-child {
  border: 1px solid #ffa100;
  background-color: #ffa100;
  border-radius: 50px;
  padding: 6px 15px;
  display: inline-block;
  /* margin-left: -15px; */
  color: #ffffff;
  cursor: pointer;
  /* margin-top: 10px; */
}
