.themes-theme-eight-footer {
  color: #fff;
  margin-top: 30px;
}
.themes-theme-eight-footer .footer {
  border-radius: 25px 25px 0 0;
  padding: 17px 26px;
  font-size: 15px;
  text-align: center;
  min-height: 72.85px;
  margin-bottom: 0;
}
