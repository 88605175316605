.services-main-div{
	display: flex;
	flex-direction: column;
	margin-top: 72px;
}

.services-inner-div{
	display: flex;
	justify-content: space-between;
	margin-bottom: 70px;
}

.services-img-div{
	width: 130px;
	margin-right: 35px;

	img{
		width: 90%;
		margin-left: 8px;
		height: 80px;
	}
}

.services-text-div{
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-right: 15px;
}
.offices-dest-div{
	font-size: 16px;

}
.offices-phone-div{
	font-size: 16px;
}