.contact-heading {
	margin-top: 30px;
	margin-bottom: 40px;
}

.row-1 {
	display: flex;
	justify-content: center;
	/* gap: 50px; */
}
.row-1-col {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 180px;
	text-align: center;
}

.row-1-col img {
	margin-bottom: 20px;
	width: 70px;
	height: 70px;
}
.row-1-col p {
	margin: 0;
	margin-bottom: 5px;
}
/* ::placeholder {
	color: #333;
	opacity: 1; 
}


	color: #333;
} */
.contact-page-mweb .form-card {
padding: 20px;
background: rgb(255, 199, 44);
border-radius: 20px;
border: none;

}

.contact-page-mweb .input-label {
color: #0152a6 !important;
margin-bottom: 2px;
}
.contact-page-mweb .common-kupos-input {
margin-bottom: 15px;
}
.center-submit {
margin: 0 auto;
}
.contact-page-mweb .center-submit button {
background: rgb(1, 82, 166);
padding: 10px 25px;
border-radius: 50px;
margin-top: 15px;
}

.contact-page-mweb .text-bold {
font-weight: bold;

}
.contact-page-mweb .d-flex
{
display: flex;
align-items: flex-start;

}
.contact-page-mweb .contact-icons-set p{
margin: 0px;
}
.contact-page-mweb .contact-icons-set img {
width: 35px;
margin-bottom: 20px;
margin-right: 15px;
}
.contact-page-mweb .dark-font
{
color:#0152a6 !important;
}
.contact-page-mweb .PB-text-input
{
color: var(--primary-text-color);
margin-top: 0px;
}
.contact-page-mweb .form-container {
max-width: 49vw;
width: 49vw;
margin: auto;
}
.contact-page-mweb .PB-input-container .input-wrapper
{
padding: 0px;
margin-bottom: 10px;
border: none;
border-radius: 10px;
}