.themes-theme-four-contact-form .home-contact-container h2 {
  margin: 0px;
  padding: 0 0 30px;
  font-size: 22px;
  text-align: center;
}
.themes-theme-four-contact-form .home-contact-form-section {
  padding: 10px 20px;
  border-radius: 15px;
  font-size: 14px;
  border: 1px solid #dddddd;
  background-color: #fff;
  margin: 0 auto;
  min-width: 44%;
  width: 44%;
}
.themes-theme-four-contact-form .home-contact-form-section h2 {
  margin: 0px;
  padding: 15px 12px;
  font-size: 22px;
  text-align: center;
}
.themes-theme-four-contact-form .home-contact-form-section img {
  width: 25px;
  height: 25px;
}
.themes-theme-four-contact-form .home-contact-form-section .contact-input-item {
  margin-bottom: 15px;
}
.themes-theme-four-contact-form
  .home-contact-form-section
  .contact-input-label {
  margin-bottom: 5px;
}
.themes-theme-four-contact-form .contact-container {
  /* display: flex; */
  gap: 30px;
}
.themes-theme-four-contact-form .contact-container .contact-form-section {
  padding: 30px;
  border-radius: 15px;
  font-size: 14px;
  border: 1px solid #dddddd;
  background-color: #fff;
  flex: 1;
}
.themes-theme-four-contact-form
  .contact-container
  .contact-form-section
  .contact-input-item {
  margin-bottom: 15px;
}
.themes-theme-four-contact-form
  .contact-container
  .contact-form-section
  .contact-input-label {
  margin-bottom: 5px;
}
.themes-theme-four-contact-form .contact-container .contact-us-info {
  flex: 1;
  padding: 20px;
  text-align: center;
}
.themes-theme-four-contact-form .contact-container .contact-us-info .item {
  display: flex;
  align-items: center;
  justify-content: center;
}
.themes-theme-four-contact-form
  .contact-container
  .contact-us-info
  .item
  .icon {
  width: 40px;
  height: 40px;
}
.themes-theme-four-contact-form
  .contact-container
  .contact-us-info
  .item
  .caption {
  margin-left: 10px;
  font-size: 13px;
}
.themes-theme-four-contact-form
  .contact-container
  .contact-us-info
  .social-links
  p {
  font-size: 16px;
}
.themes-theme-four-contact-form
  .contact-container
  .contact-us-info
  .social-links
  .social-icon {
  width: 30px;
  margin-right: 8px;
}
.themes-theme-four-contact-form
  .contact-container
  .contact-us-info
  .social-links
  .social-icon:hover {
  transform: scale(1.2);
  transition: ease-in-out 200ms;
}
.themes-theme-four-contact-form .submit-btn {
  border: 1px solid transparent;
  padding: 6px 30px;
  border-radius: 7px;
  width: 100%;
  margin-top: 10px;
  font-size: 14px;
  height: 40px;
}

.themes-theme-four-contact-form .PB-text-input {
  border: 1px solid #dddddd;
  border-radius: 6px;
}
.text-left {
  text-align: left !important;
}
.text-left > p {
  font-size: 16px;
}

.text-left .item {
  justify-content: left !important;
  padding: 10px;
}

.text-left .social-icon {
  height: 25px;
}
