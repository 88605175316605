.ThemeTwoFooter {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.ThemeTwoFooter .footer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.ThemeTwoFooter .footer .info {
  text-align: center;
  margin-top: 10px;
}

.ThemeTwoFooter .footer .info div {
  color: #fff;
}
