.themes-theme-four-footer .footer-arc {
  padding: 15px 0;
  background: #7f02a4;
  color: #ffffff;
  margin-top: 40px;
  border-radius: 25% 25% 0 0;
  display: flex;
  justify-content: center;
}
.themes-theme-four-footer .footer-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
.themes-theme-four-footer .footer-links {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.themes-theme-four-footer .footer-links a {
  padding: 0px 3px;
}
.themes-theme-four-footer .fb {
  margin-right: 4px;
}
.themes-theme-four-footer .insta {
  margin-right: 8px;
}
.themes-theme-four-footer .phone-icon {
  margin: 0 8px;
}
.themes-theme-four-footer .footer-icon {
  width: 20px;
  height: 20px;
}
.themes-theme-four-footer .footer-icon:hover {
  transform: scale(1.2);
  transition: ease-in-out 100ms;
}
.themes-theme-four-footer .phone {
  font-size: 14px;
}

.themes-theme-four-footer .bottom {
  text-align: center;
  padding: 5px;
}
