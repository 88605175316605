.themes-theme-seven-tn-c-screen {
  font-size: 14px;
}
.themes-theme-seven-tn-c-screen .terms h3 {
  text-align: center;
  margin: 20px auto;
  /* display: flex; */
  /* width: 90%; */
}
.themes-theme-seven-tn-c-screen .terms h3 span {
  color: #203b68;
  /* border: 1px solid #b2b6be; */
  border-radius: 50px;
  /* padding: 6px 30px; */
  font-family: 'DMSans-Bold';
}
/* .themes-theme-seven-tn-c-screen .terms h3::before {
  border-left: 1px solid #b2b6be;
  border-radius: 10px 0 0 0;
  content: '';
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 25px;
  position: relative;
  top: 10px;
}
.themes-theme-seven-tn-c-screen .terms h3::after {
  border-right: 1px solid #b2b6be;
  border-radius: 0 10px 0 0;
  content: '';
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 25px;
  position: relative;
  top: 10px;
} */
.themes-theme-seven-tn-c-screen .sub-terms {
  border: 1px solid #b2b6be;
  padding: 20px 30px;
  border-radius: 10px;
}
.themes-theme-seven-tn-c-screen .sub-terms h5 {
  margin: 25px 0;
}
.themes-theme-seven-tn-c-screen .sub-terms h5 span {
  background-color: #ffa100;
  border-radius: 50px;
  padding: 5px;
  color: #fff;
  font-family: 'DMSans-Bold';
  font-size: 15px;
  /* position: relative; */
  /* left: -45px; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.themes-theme-seven-tn-c-screen .sub-terms p {
  text-align: justify;
}
.themes-theme-seven-tn-c-screen .sub-terms .bold {
  font-family: 'DMSans-Bold';
  font-size: 14px;
}
