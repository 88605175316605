.themes-theme-eight-contact-us-screen .form-section {
  padding: 20px 10px;
  border-radius: 15px;
  font-size: 14px;
  border: 1px solid #cccccc;
  flex: 1;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  margin: auto;
  margin-top: -95px;
  position: relative;
}
.themes-theme-eight-contact-us-screen .form-section h2 {
  margin: 0px;
  padding: 15px 12px;
  font-size: 20px;
  text-align: left;
}
.themes-theme-eight-contact-us-screen .form-section .contact-input-item {
  margin-bottom: 15px;
  padding: 0 12px;
}
.themes-theme-eight-contact-us-screen .form-section .contact-input-label {
  font-size: 14px;
}
.themes-theme-eight-contact-us-screen .form-section .submit-btn {
  border: 1px solid transparent;
  padding: 8px 35px;
  border-radius: 7px;
  margin-top: 10px;
  font-size: 14px;
}
.themes-theme-eight-contact-us-screen .form-section .send-btn {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
.themes-theme-eight-contact-us-screen .form-section .center {
  text-align: center;
}
