.themes-theme-seven-gallery-screen #gallery {
  background: url("../../../assets/fernandez/gallery/BusesFernandez-Website-Gallery-Banner.jpg");
  min-height: 300px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom center;
  margin-top: -20px;
}
.themes-theme-seven-gallery-screen #gallery .heading {
  position: relative;
  top: 140px;
}
.themes-theme-seven-gallery-screen #gallery .heading h1 {
  text-align: center;
  color: #ffffff;
  font-family: "DMSans-Bold";
  font-size: 25px;
}
.themes-theme-seven-gallery-screen .gallery h3 {
  text-align: center;
  margin: 40px auto;
  display: flex;
  width: 70%;
}
.themes-theme-seven-gallery-screen .gallery h3 span {
  color: #203b68;
  border: 1px solid #b2b6be;
  border-radius: 50px;
  padding: 6px 30px;
  font-family: "DMSans-Bold";
}
.themes-theme-seven-gallery-screen .gallery h3::before {
  border-left: 1px solid #b2b6be;
  border-radius: 10px 0 0 0;
}
.themes-theme-seven-gallery-screen .gallery h3::after {
  border-right: 1px solid #b2b6be;
  border-radius: 0 10px 0 0;
}
.themes-theme-seven-gallery-screen .gallery h3::before,
.themes-theme-seven-gallery-screen .gallery h3::after {
  content: "";
  flex: 1 1;
  border-top: 1px solid #b2b6be;
  margin: auto;
  height: 25px;
  position: relative;
  top: 10px;
}
