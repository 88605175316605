.themes-footer-2 {
  /* // position:absolute; */
  /* // bottom: 0; */
  width: 100%;
  /* // remove this later */
}

.footer-container {
  width: 60vw;
}

.themes-footer-2 .footer-logo {
  margin-right: 30px;
}


.themes-footer-2 .info {
  margin-right: 30px;
}
.newfooterlogo
{
	margin-right: 0px !important;
}
.newfooterlogo img
{
	width: 80%;
}
.themes-footer-2 .kupos_link {
  margin-left: 10px;
  color: white;
  font-weight: bold;
  cursor: pointer;
}

.bottom-links-list {
  padding: 20px 30px;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  font-size: 12px;
  justify-content: center;
}
.none {

	display: none;
}
.mh-5 {
margin: 0px 5px;
}