.pullman-san-andreas-contact {
	/* transform: translateY(-35px); */
}

.pullman-san-andreas-contact .main-heading {
	margin-top: 40px;
	margin-bottom: 40px;
	/* padding: 0 10px; */
}

.pullman-san-andreas-contact .sub-main-heading {
	font-size: 22px;
	margin-bottom: 30px;
}
.pullman-san-andreas-contact-content {
	/* display: flex; */
	/* justify-content: space-between; */
	margin-bottom: 30px;
}

.pullman-san-andreas-contact-content-left {
	/* width: 45%; */
}

.pullman-san-andreas-contact-content-left span {
	font-size: 14px;
	font-weight: 600;
	line-height: 1.3;
}

.pullman-san-andreas-contact-content-right {
	/* width: 45%; */
}

.pullman-san-andreas-contact-content-right .form-group {
	display: flex;
	flex-direction: column;
	margin-bottom: 10px;
}

.pullman-san-andreas-contact-content-right .form-group label {
	font-size: 14px;
	color: #333;
	font-weight: bold;
}

.pullman-san-andreas-contact-content-right .form-group select,
.pullman-san-andreas-contact-content-right .form-group input,
.pullman-san-andreas-contact-content-right .form-group textarea {
	border: 2px solid #db7838;
	font-size: 14px;
	border-radius: 12px;
	padding: 5px 10px;
}

.pullman-san-andreas-contact-content-right .form-group input::placeholder,
.pullman-san-andreas-contact-content-right .form-group select::placeholder {
	font-size: 14px;
	font-weight: 900;
	color: #777;
}
