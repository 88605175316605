.themes-offices {
  font-size: 18px;
}

:root {
  --color-dark-green: #005924;
  --color-light-green: #008137;
  --color-yellow: #ffef00;
}

.py-60 {
  padding: 60px 0;
}

.container {
  width: 1100px;
  margin: 0 auto;
}
.capitalize {
  text-transform: capitalize;
}

.cursor-pointer {
  cursor: pointer;
}

ul {
  margin: 0;
}

.text-18 {
  font-size: 18px;
}

.text-30 {
  font-size: 30px;
}

.mb-20 {
  margin-bottom: 20px;
}

.bold {
  font-weight: 500;
}

.offices {
  width: 100%;
  color: #fff;
}

.offices .office__list {
  display: flex;
  justify-content: space-between;
  background-color: var(--color-yellow);
  /* padding-top: 6px; */
  flex-direction: column;
}

.offices .office__list .office__item {
  /* width: calc(100% / 7); */
  display: flex;
  justify-content: center;
  padding: 10px 0;
}

.offices .office__list .selected-location {
  color: var(--color-dark-green) !important;
  background-color: var(--color-yellow) !important;
}

.offices .office__list .selected-location .office__name {
  border-bottom: 3px solid var(--color-dark-green) !important;
}

.offices .office__list .office__item .office__name {
  display: inline-block;
  width: 80%;
  padding: 7px 0;
  border-bottom: 3px solid var(--color-yellow);
  text-transform: capitalize;
}

.offices .background-dark-green {
  background-color: var(--color-dark-green);
}

.offices .background-light-green {
  background-color: var(--color-light-green);
}

.offices .office__map {
  height: 500px;
  background-color: #e4e5e9;
  display: flex;
  flex-direction: column;
}

.offices .office__map .office__map-view {
  background-color: white;
  /* width: 40%; */
  /* background-image: url('https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/609147088669907f652110b0_report-an-issue(about-maps).jpeg'); */
  height: 100%;
  /* background-size: cover; */
}

.offices .office__map .office__map-details {
  /* width: 60%; */
  height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 60px;
  color: #333;
  overflow-y: scroll;
  /* align-items: center; */
}

.offices .office__map .office__map-details .office__map-details-text {
  display: flex;
  flex-direction: column;
}

.offices
  .office__map
  .office__map-details
  .office__map-details-text
  .selected-location-name {
  width: 100px;
  padding-bottom: 4px;
  margin-bottom: 15px;
}

.offices
  .office__map
  .office__map-details
  .office__map-details-text
  .selected-location-contact-no {
  display: inline-block;
  padding-top: 7px;

  padding-bottom: 8px;
}

.offices
  .office__map
  .office__map-details
  .office__map-details-text
  .selected-location-address {
  text-transform: capitalize;
}

.offices .office__map .office__map-details .selected-location-name {
  /* padding: 7px 0; */

  border-bottom: 3px solid var(--color-dark-green) !important;
  text-transform: capitalize;
}
.about-service-image {
  height: 100%;
}
/* .dark-green {
	color: var(--color-dark-green);
}
.light-green {
	color: #018137;
} */

.offices .office__map .office__map-details .selected-office {
  margin-bottom: 15px;
}

.offices .office__map .office__map-details .selected-office .terminal {
  border-bottom: 3px solid var(--color-dark-green);
  margin-bottom: 25px;
  width: max-content;
}

.offices .office__map .office__map-details .selected-office .phone {
  margin: 5px 0;
  display: flex;
  align-items: center;
}
.offices .office__map .office__map-details .selected-office .address {
  margin: 5px 0;
  display: flex;
  align-items: center;
}
